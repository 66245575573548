import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import WalletLogin from "../user/auth/WalletLogin";
import { useAccount } from "wagmi";
import { HiMiniArrowTopRightOnSquare } from "react-icons/hi2";
import { getCookie } from "../../functions/cookies";
import { FaSignInAlt } from "react-icons/fa";

export default function Header() {
   const loggedIn = getCookie('nftng-user-token')
   const menuFunc = (link, title) => {
      return { link, title };
   };
   const menuArr = [
      menuFunc("/home", "HOME"),
      menuFunc("/apply", "APPLY"),
      menuFunc("/about", "ABOUT US"),
      menuFunc("/product", "PRODUCT"),
      menuFunc("/event", "EVENT"),
      menuFunc("/award", "AWARD"),
      menuFunc("/contact", "CONTACT US"),
   ];
   const navi = useNavigate()
   return (
      <div className="hidden lg:block">
         <div className="header bg-neutral-950 border-b-[1px] border-gray-500 py-3 flex items-center justify-between px-10">
            <div className="logo-con">
               <img src="/logo.png" alt="NFTng" onClick={() => navi('/')} className="cursor-pointer" />
            </div>
            <div className="menu-con">
               {menuArr.map((menu, index) => (
                  <NavLink
                     to={menu.link}
                     className="capitalize decoration-none text-xs md:mx-4 lg:mx-4 text-slate-50"
                     key={index}
                  >
                     {menu.title}
                  </NavLink>
               ))}
            </div>

            <div className="btn-wrap flex gap-2 text-sm">
               {loggedIn ? (
                  <button className="bg-ng-primary bg-opacity-40 px-3 py-2 rounded-full flex items-center gap-2 font-bold text-white" onClick={() => navi('/user/dashboard')}>Dashboard <HiMiniArrowTopRightOnSquare size={18} className="text-ng-primary" /> </button>
               ) :
                  (
                     <button className="bg-ng-primary hover:bg-opacity-40 trans px-3 py-2 rounded-full flex items-center gap-2 font-bold text-black" onClick={() => navi('/auth/login')}>Login <FaSignInAlt size={18} className="text-ng-secondary" /> </button>
                  )}
            </div>


         </div>
      </div>
   );
}
