import { toast } from "react-toastify";

const notify = (type, msg) => {
	switch (type) {
		case "error":
			toast.error(msg, {
				position: "top-right",
				autoClose: 1500,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: true,
				progress: undefined,
				theme: "light",
			});
			break;
		case "success":
			toast.success(msg, {
				position: "top-right",
				autoClose: 1500,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: true,
				progress: undefined,
				theme: "light",
			});
			break;

		case "warning":
			toast.warning(msg, {
				position: "top-right",
				autoClose: 1500,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: true,
				progress: undefined,
				theme: "light",
			});
			break;
		default:
			break;
	}
};
export default notify;
