import axios from "axios";
import { getToken } from "./tokenLogger";

export const get = async (query) => {
   try {
      const response = await axios.get(`${window.api}${query}`, {
         headers: {
            "nftng-user-token": getToken("user"),
            "Content-Type": "application/json",
         },
      });
      return response;
   } catch (error) {
      throw error;
   }
};
