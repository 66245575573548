import React, { useEffect, useState } from "react";
import ClientWrapper from "../../components/ClientWrapper";
import Loader from "../../components/loader";
import Bookings from "./components/bookings";
import Buzz from "./components/buzz";
import Faqs from "./components/faqs";
import Intro from "./components/intro";
import Football from "./components/Football";

export default function Home() {
   const [loading, setLoading] = useState(true);
   useEffect(() => {
      setTimeout(() => setLoading(false), 2000);
   }, []);
   return (
      <ClientWrapper>
         <div className="home bg-neutral-950">
            <Loader loading={loading} />
            <Intro />
            <Football />
            <Buzz />
            <Bookings />
            <Faqs />
         </div>
      </ClientWrapper>
   );
}
