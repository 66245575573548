import React from "react";
import { HashLoader, RingLoader, RiseLoader, SyncLoader } from "react-spinners";

export default function Loader({ loading }) {
   return (
      <div
         className={`loader ${
            !loading && "d-none"
         } d-flex justify-content-center align-items-center position-fixed top-0 start-0 end-0 bottom-0 bg-ng-primary z-30`}
      >
         <div className="wrap text-center ">
            <div className="mx-auto d-flex  justify-content-center items-center">
               <RingLoader size={120}  color="#000"/>
               <span className="text-black text-2xl font-bold absolute">NFT<small className="text-white">ng.</small></span>
            </div>
         </div>
      </div>
   );
}
