import React from 'react'
import { NumericFormat } from 'react-number-format'

export default function FormatNumber({ number }) {
    return (
        <>
            &#8358;
            <NumericFormat
                value={number}
                displayType={'text'}
                thousandSeparator={','}
            />
        </>
    )
}
