import React, { useState } from "react";
import { FaBars, FaSignInAlt } from "react-icons/fa";
import { FaCircleXmark } from "react-icons/fa6";
import { NavLink, useNavigate } from "react-router-dom";
import WalletLogin from "../user/auth/WalletLogin";
import { useAccount } from "wagmi";
import { HiMiniArrowTopRightOnSquare } from "react-icons/hi2";
import { getCookie } from "../../functions/cookies";
export default function MbHeader() {
   const [toggle, setToggle] = useState(false);
   const menuFunc = (link, title) => {
      return { link, title };
   };
   const menuArr = [
      menuFunc("/home", "HOME"),
      menuFunc("/apply", "APPLY"),
      menuFunc("/about", "ABOUT US"),
      menuFunc("/product", "PRODUCT"),
      menuFunc("/event", "EVENT"),
      menuFunc("/award", "AWARD"),
      menuFunc("/contact", "CONTACT US"),
   ];
   const navi = useNavigate()
   const loggedIn = getCookie('nftng-user-token')
   return (
      <>
         <div className="logo-con flex justify-between py-3 bg-black px-4 sticky-top lg:hidden">
            <div className="img-wrap">
               <img src="/logo.png" alt="NFTng" className="w-[50px] cursor-pointer" onClick={() => navi('/')} />
            </div>
            <span
               className="flex text-white items-center gap-2 text-2xl"
               onClick={() => setToggle(!toggle)}
            >

               {loggedIn ? (
                  <button className="bg-ng-primary text-xs bg-opacity-40 px-3 py-2 rounded-full flex items-center gap-2 text-white" onClick={() => navi('/user/dashboard')}>Dashboard <HiMiniArrowTopRightOnSquare size={18} className="text-ng-primary" /> </button>
               ) :
                  (
                     <button className="bg-ng-primary text-xs hover:bg-opacity-40 trans px-3 py-2 rounded-full flex items-center gap-2 font-bold text-black" onClick={() => navi('/auth/login')}>Login <FaSignInAlt size={18} className="text-ng-secondary" /> </button>
                  )}

               <FaBars />
            </span>
         </div>

         <div
            className={`mb-header trans overflow-auto bg-black bottom-0 fixed top-0 left-0 right-0 z-[1022] ${toggle === false ? "slide" : ""
               } lg:hidden`}
         >
            <div className="container">
               <div className="logo-con flex justify-between  py-5 bg-black px-4 mb-5">
                  <div className="img-wrap">
                     <img src="/logo.png" alt="NFTng" className="w-[50px]" />
                  </div>
                  <span
                     className="flex text-ng-primary items-center gap-2"
                     onClick={() => setToggle(!toggle)}
                  >
                     close <FaCircleXmark />
                  </span>
               </div>
               <div
                  className={`menu-link absolute left-0 right-0 px-3 text-white `}
               >
                  <ul>
                     {menuArr.map((menu, index) => (
                        <li
                           key={index}
                           className="flex my-3 text-center"
                           onClick={() => setToggle(!toggle)}
                        >
                           <NavLink
                              to={menu.link}
                              className="bg-ng-secondary-dark py-3 col-12"
                           >
                              {" "}
                              {menu.title}{" "}
                           </NavLink>{" "}
                        </li>
                     ))}
                     <li className="flex justify-center gap-2">
                        {loggedIn ? (
                           <button className="bg-ng-primary bg-opacity-40 px-3 py-2 rounded-full flex items-center gap-2 font-bold text-white" onClick={() => navi('/user/dashboard')}>Dashboard <HiMiniArrowTopRightOnSquare size={18} className="text-ng-primary" /> </button>
                        ) :
                           (
                              <button className="bg-ng-primary hover:bg-opacity-40 trans px-3 py-2 rounded-full flex items-center gap-2 font-bold text-black" onClick={() => navi('/auth/login')}>Login <FaSignInAlt size={18} className="text-ng-secondary" /> </button>
                           )}
                     </li>
                  </ul>
                  <div className="rights text-center w-full  bottom-0 text-xs text-white py-4">
                     <span>Copyright © 2022 NFT Nigeria</span> <br />
                     <span>Powered by NFTNG</span>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}
