import React from "react";
import glass from "../../../svgs/glass.svg";
import people from "../../../svgs/people.svg";
import flag from "../../../svgs/flag.svg";
import CoreTeam from "./components/core-team";
import ClientWrapper from "../../components/ClientWrapper";

export default function Apply() {
   const applyFunc = (icon, title, text, color) => {
      return { icon, title, text, color };
   };
   const applyArr = [
      applyFunc(
         flag,
         "Mission",
         "Empower the next sets of builders, creatives, and thought leaders from the African community. Bring NFTs and blockchain technology to the attention of the mainstream audience and community. Create a tight-knit web3 community that will thrive off each other and bring value to the ecosystem. Enlighten the mainstream community about the endless opportunities that can be created with blockchain technology and the importance of mass adoption.",
         0
      ),
      applyFunc(
         glass,
         "Vision",
         "Bringing together digital creators, developers, thought leaders and other Web3 enthusiasts in the African Space and empowering them with opportunities to thrive on a global scale.",
         1
      ),
      applyFunc(
         people,
         "Team",
         "Get to know the talented individuals who make up our team. From dedicated professionals to passionate experts, we're here to serve you with excellence.",
         0
      ),
   ];
   return (
      <ClientWrapper>
         <div className="about">
            <div className="container">
               <div className="head py-4 uppercase">
                  <span className=" text-xl lg:text-2xl text-slate-400 font-bold">
                     Apply
                  </span>
               </div>
               <div className="row justify-between">
                  <div className="col-lg-8 col-12">
                     {applyArr.map((box, index) => (
                        <div
                           className={`box p-4 ${index === 2 ? "mb-0" : "mb-4"
                              }  ${box.color === 0
                                 ? "bg-ng-primary-opacity"
                                 : "bg-ng-secondary-dark"
                              }`}
                           key={index}
                        >
                           <div className="box-head flex gap-2 items-center">
                              <img src={box.icon} alt="Apply" width={30} />{" "}
                              <span className="font-bold text-ng-primary text-xl uppercase">
                                 {box.title}
                              </span>
                           </div>
                           <div className="box-body text-slate-400 text-sm my-4 mb-1">
                              <span className="leading-8">{box.text}</span>
                           </div>
                        </div>
                     ))}
                     <CoreTeam header={"Team Member"} />
                  </div>
                  <div className="col-lg-3 hidden  lg:block col-12">
                     <div className="quick-links bg-black p-2 pb-3 sticky-top">
                        <div className="head text-slate-400 p-3">
                           <span className="text-xl">Quick Links</span>
                        </div>
                        {applyArr.map((i, index) => (
                           <div
                              className="body items-center  text-md font-bold"
                              key={index}
                           >
                              <span className="px-3 text-ng-primary leading-10">
                                 {i.title}
                              </span>
                           </div>
                        ))}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </ClientWrapper>
   );
}
