export const CART_INITIAL_STATE = [
   { product_id: 0, product_name: "", product_price: 0, product_img: "", product_size: 'm' },
];

const CartReducer = (state, action) => {
   switch (action.type) {
      case "ADD_STORED_CART": {
         return action.payload
      }
      case "ADD_CART":
         return [...state, action.payload]; // Add a new product to the cart
      case "ADD_QTY":
         const cart_id = action.payload; // Assuming action.payload contains the cart_id
         const updatedCart = state.map((product) => {
            if (product.cart_id === cart_id) {
               // Edit the quantity of the specific product
               return {
                  ...product,
                  product_quantity: product.product_quantity + 1, // Increment quantity safely
               };
            }
            return product;
         });
         return updatedCart;

      case "REM_QTY":
         const rem_cart_id = action.payload; // Assuming action.payload contains the cart_id
         const rem_updatedCart = state.map((product) => {
            if (product.cart_id === rem_cart_id) {
               // Edit the quantity of the specific product
               const qty_value = product.product_quantity - 1;
               const val = qty_value < 1 ? 1 : qty_value;
               return {
                  ...product,
                  product_quantity: val, // Decrease quantity safely
               };
            }
            return product;
         });
         return rem_updatedCart;

      case "ADD_SIZE":
         const size_cart_id = action.payload.id; // Assuming action.payload contains the cart_id
         const s_updatedCart = state.map((product) => {
            if (product.cart_id === size_cart_id) {
               // Edit the quantity of the specific product
               return {
                  ...product,
                  product_size: action.payload.size, // Increment quantity safely
               };
            }
            return product;
         });
         return s_updatedCart;

      case "REM_PRODUCT":
         const cartId = action.payload;
         const newProdList = state.filter((product) => {
            return cartId !== product.cart_id;
         });
         return newProdList;

      case "RESET":
         return [];
      default:
         return state;
   }
};

export default CartReducer;
