import React, { createContext, useReducer } from 'react'
import { USER_INTIAL_STATE, UserDetailReducer } from './userDetails'

export const UserDetailsContext = createContext()
export default function UserDetailsContextWrap({ children }) {

    const [state, dispatch] = useReducer(UserDetailReducer, USER_INTIAL_STATE)
    return (
        <UserDetailsContext.Provider value={{ state, dispatch }}>
            {children}
        </UserDetailsContext.Provider>
    )
}
