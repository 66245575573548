import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AdminAuth } from "../../../functions/Auth";
import { storeToken } from "../../../functions/tokenLogger";
import Loader from "../../components/loader";
import { setCookie } from "../../../functions/cookies";

export default function AdminLogin() {
   const [email, setEmail] = useState("");
   const [password, setPassword] = useState("");

   const [loading, setLoading] = useState(false);
   const [err, setErr] = useState("");
   const navi = useNavigate();
   const handleSubmit = async (e) => {
      try {
         setLoading(true);
         e.preventDefault();
         const data = { email, password };
         const token = await AdminAuth(data);
         if (token) {
            setCookie('nftng-admin-token', token)
            navi("/admin/dashboard");
         } else {
            setErr("Wrong Credentials");
         }
         setLoading(false);
      } catch (error) {
         console.log(error);
         setLoading(false);
      }
   };
   return (
      <div className="contact">
         <div className="container min-h-[80vh]">
            <Loader loading={loading} />
            <div className="col-lg-12 py-5">
               <form onSubmit={handleSubmit}>
                  <div className="col-lg-6 bg-black mx-auto p-5">
                     <div className="form-text  text-white text-md leading-8  pb-3">
                        <span className="text-ng-primary font-bold text-3xl">
                           Admin Login
                        </span>{" "}
                        <br />
                     </div>

                     <div className="input-wrap  justify-between">
                        <input
                           type="email"
                           placeholder="Email"
                           className={`w-full  p-3 rounded-md mb-4 text-slate-200 text-xs bg-transparent border-1 ${
                              err === ""
                                 ? "border-ng-primary"
                                 : "border-red-500"
                           } border-opacity-30 focus:outline-none focus:ring-2 `}
                           required
                           value={email}
                           onChange={(e) => setEmail(e.target.value)}
                        />
                        <input
                           type="password"
                           placeholder="Password"
                           className={`w-full  p-3 rounded-md mb-4 text-slate-200 text-xs bg-transparent border-1 ${
                              err === ""
                                 ? "border-ng-primary"
                                 : "border-red-500"
                           } border-opacity-30 focus:outline-none focus:ring-2 `}
                           required
                           value={password}
                           onChange={(e) => setPassword(e.target.value)}
                        />{" "}
                        <span className="text-danger capitalize">{err}</span>
                     </div>
                     <div className="btn-wrap my-3 block">
                        <button
                           type="submit"
                           className="w-full px-3 bg-ng-primary rounded-md text-black text-sm font-bold py-3"
                        >
                           LOGIN
                        </button>
                     </div>
                  </div>
               </form>
            </div>
         </div>
      </div>
   );
}
