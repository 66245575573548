import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import notify from "../../../../functions/notify";
import { post } from "../../../../functions/post";
import Breadcrumb from "../../../components/breadcrumb";
import ClientWrapper from "../../../components/ClientWrapper";
import Loader from "../../../components/loader";
import Navigator from "../../../components/navigator";
import Success from "../../../components/success";
import BgGrad from "../../../components/BgGrad";

export default function Partner() {
   const [title, setTitle] = useState("");
   const [firstname, setFirstname] = useState("");
   const [lastname, setLastname] = useState("");
   const [company_name, setCompany_name] = useState("");
   const [phone, setPhone] = useState("");
   const [email, setEmail] = useState(localStorage.getItem("email"));

   const [loading, setLoading] = useState(false);
   const [success, setSuccess] = useState(false);

   const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      try {
         const data = {
            title,
            firstname,
            lastname,
            company_name,
            phone,
            // email,
         };
         const response = await post("/apply/partner", data);
         setLoading(false);
         setSuccess(true);
      } catch (error) {
         console.log(error);
         setLoading(false);
         notify("error", error.response.data.error);
      }
   };
   return (
      <ClientWrapper>
         <BgGrad>
            <div className="partners bg-ng-secondary-dark bg-opacity-80 rounded-2xl pb-10">
               <div className="container min-h-[80vh]">
                  <Navigator link={"/apply"} />
                  {/* loader if request is ongoing  */}
                  <Loader loading={loading} />
                  {/* success popup if form submitted successfully */}
                  {success && (
                     <Success
                        message={
                           "Data submitted successfully. You'll contacted be shortly"
                        }
                        link={"/apply"}
                        btnText={"Done"}
                     />
                  )}
                  {/* Notifyer */}
                  <ToastContainer theme="dark" />

                  {/* form */}
                  <div className="col-lg-12 mx-auto">
                     <form onSubmit={handleSubmit}>
                        <div className="col-lg-6 mx-auto">
                           <div className="form-text  text-light text-md leading-8  pb-3">
                              <Breadcrumb
                                 base={"apply"}
                                 current={"Partner"}
                                 mbCrumb="Partner form"
                              />
                              <span>
                                 We’ve had something brewing… It’s very exciting
                                 and worth anticipating. Now that the cat is out of
                                 the bag, please join us on our incredible journey
                                 you snooze, you lose.
                              </span>
                           </div>

                           <div className="input-wrap flex flex-wrap gap-x-3 justify-between">
                              <select
                                 className=" lg:w-auto  p-3 rounded-md mb-4 text-slate-400 text-xs bg-transparent border-1 border-ng-primary border-opacity-30 focus:outline-none focus:ring-2 "
                                 required
                                 value={title}
                                 onChange={(e) => {
                                    setTitle(e.target.value);
                                 }}
                              >
                                 <option value="" defaultChecked>
                                    Title*
                                 </option>
                                 <option value="tetst">Test @</option>
                              </select>
                              <input
                                 type="text"
                                 placeholder="First Name"
                                 className="w-full lg:w-auto  p-3 rounded-md mb-4 text-slate-200 text-xs bg-transparent border-1 border-ng-primary border-opacity-30 focus:outline-none focus:ring-2 "
                                 required
                                 value={firstname}
                                 onChange={(e) => {
                                    setFirstname(e.target.value);
                                 }}
                              />
                              <input
                                 type="text"
                                 placeholder="Last Name"
                                 className="w-full lg:w-auto p-3 rounded-md mb-4 text-slate-200 text-xs bg-transparent border-1 border-ng-primary border-opacity-30 focus:outline-none focus:ring-2 "
                                 required
                                 value={lastname}
                                 onChange={(e) => {
                                    setLastname(e.target.value);
                                 }}
                              />
                           </div>
                           <div className="input-wrap">
                              <input
                                 type="text"
                                 placeholder="Company Name"
                                 className="w-full  p-3 rounded-md mb-4 text-slate-200 text-xs bg-transparent border-1 border-ng-primary border-opacity-30 focus:outline-none focus:ring-2 "
                                 required
                                 value={company_name}
                                 onChange={(e) => {
                                    setCompany_name(e.target.value);
                                 }}
                              />
                           </div>
                           <div className="input-wrap">
                              <div className="border-1 px-4 mb-4  border-ng-primary border-opacity-30 flex items-center rounded-md">
                                 <span className="text-light font-bold">+234</span>
                                 <input
                                    type="number"
                                    placeholder="Enter phone number"
                                    className="w-full  p-3 rounded-md  text-slate-200 text-xs bg-transparent  focus:outline-none focus:ring-2"
                                    required
                                    value={phone}
                                    onChange={(e) => {
                                       setPhone(e.target.value);
                                    }}
                                 />
                              </div>
                           </div>
                           <div className="input-wrap">
                              <input
                                 type="email"
                                 placeholder="Email address"
                                 className="w-full  p-3 rounded-md mb-4 text-slate-200 text-xs bg-transparent border-1 border-ng-primary border-opacity-30 focus:outline-none focus:ring-2 "
                                 required
                                 value={email}
                                 onChange={(e) => {
                                    setEmail(e.target.value);
                                 }}
                                 disabled
                              />
                           </div>
                           <div className="btn-wrap my-3 b">
                              <button className="w-full px-3 bg-ng-primary rounded-md text-black text-sm font-bold py-3">
                                 Submit
                              </button>
                           </div>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
         </BgGrad>
      </ClientWrapper>
   );
}
