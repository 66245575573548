import React from 'react'

export default function AdminHeader() {
    return (
        <div className="header py-3 flex justify-between items-center text-white">
            <span className="text-lg text-ng-primary  font-bold">
                Dashboard
            </span>
            <span className="flex gap-2 items-center bg-ng-primary-opacity px-3 py-2 rounded-xl">
                <img src="/admin_avatar.png" alt="Admin" /> King.Sol
            </span>
        </div>
    )
}
