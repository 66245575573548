import { getCookie, removeCookie, setCookie } from "./cookies";
import { verifyToken } from "./verify-token";

// stores either admin or user token
export const storeToken = (token, who) => {
   setCookie(`nftng-${who}-token`, token);
};

// get token from cookie sorage (either admin or user)
export const getToken = (who) => {
   const token = getCookie(`nftng-${who}-token`);
   verifyToken(token, who);
   return token;
};

// logout user and delete token
export const logout = (who) => {
   removeCookie(`nftng-user-token`);
   window.location.href = who === "admin" ? "/admin/login" : "/auth/login";
};

// exports.userToken = userToken
