import React from "react";
import { useSession, signIn, signOut } from "next-auth/react";


export default function Twitter() {
   const { data: session } = useSession();

   return (
      <div>
         {!session ? (
            <button onClick={() => signIn("google")}>
               Sign in with Google
            </button>
         ) : (
            <>
               <p>Welcome, {session.user.name}!</p>
               <button onClick={() => signOut()}>Sign out</button>
            </>
         )}
      </div>
   );
}
