import React from "react";
import Breadcrumb from "../../../components/breadcrumb";
import ClientWrapper from "../../../components/ClientWrapper";
import Navigator from "../../../components/navigator";
import BgGrad from "../../../components/BgGrad";

export default function Volunteers() {
   return (
      <ClientWrapper>
         <BgGrad>
            <div className="volunteers bg-ng-secondary-dark bg-opacity-80 rounded-2xl pb-10">
               <div className="container min-h-[80vh]">
                  <Navigator link={"/apply"} />
                  <div className="col-lg-12 mx-auto">
                     <form action="">
                        <Breadcrumb
                           base={"apply"}
                           current={"Volunteer"}
                           mbCrumb="Volunteer form"
                        />
                        <div className="form-text text-light text-md leading-8  pb-3">
                           <span>
                              Following numerous requests, NFT NG is launching a
                              volunteer service program for those of you who have
                              the skills and want to contribute to NFTNG. Please
                              fill out the application form below and let us know
                              what you can offer. PS: Completing this form does not
                              guarantee Buzzlist inclusion.
                           </span>
                        </div>

                        <div className="row ">
                           <div className="col-lg-6">
                              <div className="input-wrap">
                                 <div className="border-1 px-4 mb-4  border-ng-primary border-opacity-30 flex items-center rounded-md">
                                    <span className="text-light font-bold w-">
                                       Twitter Handle*
                                    </span>
                                    <input
                                       type="number"
                                       placeholder="Enter phone number"
                                       className="w-auto  p-3 rounded-md  text-slate-200 text-xs bg-transparent  focus:outline-none focus:ring-2"
                                       required
                                    />
                                 </div>
                              </div>
                              <div className="radio-wrap mb-4 text-white leading-8 lg:flex lg:flex-wrap lg:gap-4">
                                 <div className="head col-12">
                                    <span className="text-ng-primary font-bold">
                                       Departments you can volunteer in*
                                    </span>
                                 </div>
                                 <div className="wrap">
                                    <input
                                       type="radio"
                                       name="type"
                                       className="bg-red-400"
                                    />{" "}
                                    <span>Protocol/Ushering </span>
                                 </div>
                                 <div className="wrap">
                                    <input
                                       type="radio"
                                       name="type"
                                       className="bg-red-400"
                                    />{" "}
                                    <span>Technical team </span>
                                 </div>
                                 <div className="wrap">
                                    <input
                                       type="radio"
                                       name="type"
                                       className="bg-red-400"
                                    />{" "}
                                    <span>Media: Photography </span>
                                 </div>
                                 <div className="wrap">
                                    <input
                                       type="radio"
                                       name="type"
                                       className="bg-red-400"
                                    />{" "}
                                    <span>Media: Graphics/Animation </span>
                                 </div>
                                 <div className="wrap">
                                    <input
                                       type="radio"
                                       name="type"
                                       className="bg-red-400"
                                    />{" "}
                                    <span>Media: Others </span>
                                 </div>
                                 <div className="wrap">
                                    <input
                                       type="radio"
                                       name="type"
                                       className="bg-red-400"
                                    />{" "}
                                    <span>PR </span>
                                 </div>
                                 <div className="wrap">
                                    <input
                                       type="radio"
                                       name="type"
                                       className="bg-red-400"
                                    />{" "}
                                    <span>Content </span>
                                 </div>
                              </div>
                              <div className="input-wrap">
                                 <textarea
                                    placeholder="Kindly tell us how you can function in the department you chose to volunteer in."
                                    className="w-full  p-3 rounded-md mb-4 text-slate-200 text-xs bg-transparent border-1 border-ng-primary border-opacity-30 focus:outline-none focus:ring-2 "
                                    required
                                    rows={4}
                                 />
                              </div>

                              <div className="btn-wrap my-3 hidden lg:block">
                                 <button className="w-auto px-5 bg-ng-primary rounded-md text-black text-sm font-bold py-3">
                                    Submit
                                 </button>
                              </div>
                           </div>

                           {/* Second Column */}
                           <div className="col-lg-6">
                              <div className="input-wrap">
                                 <textarea
                                    rows="8"
                                    placeholder="If available, kindly provide a link (s) to any work you have done previously in the department you selected above."
                                    className="w-full  p-3 rounded-md mb-4 text-slate-200 text-xs bg-transparent border-1 border-ng-primary border-opacity-30 focus:outline-none focus:ring-2 "
                                 ></textarea>
                              </div>
                           </div>
                           <div className="btn-wrap my-3 b block lg:hidden">
                              <button className="w-full px-3 bg-ng-primary rounded-md text-black text-sm font-bold py-3">
                                 Submit
                              </button>
                           </div>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
         </BgGrad>
      </ClientWrapper>
   );
}
