function selectRandom(array, numItems) {
	// Create a copy of the array to avoid mutating the original array
	const arrayCopy = [...array];
	const result = [];

	// Ensure numItems is not greater than the array length
	const itemsToSelect = Math.min(numItems, arrayCopy.length);

	// Select items randomly
	for (let i = 0; i < itemsToSelect; i++) {
		const randomIndex = Math.floor(Math.random() * arrayCopy.length);
		result.push(arrayCopy.splice(randomIndex, 1)[0]);
	}

	return result;
}

export default selectRandom;
