import React from "react";
import mic from "../../../svgs/microphone.svg";
import people from "../../../svgs/people.svg";
import user from "../../../svgs/profile-2user.svg";
import crown from "../../../svgs/crown.svg";
import { Link } from "react-router-dom";
import ClientWrapper from "../../components/ClientWrapper";

export default function Apply() {
   const applyFunc = (icon, title, text, btnText, link, color) => {
      return { icon, title, text, btnText, link, color };
   };
   const applyArr = [
      applyFunc(
         crown,
         "Award",
         "DEFI Summer event will include an award session for reputable Web3 figures. Awardees must have shown evidence of driving ideas that are good for the Web3 space, making an impact in the web3 community, or building/contributing to impactful web3 projects. To nominate yourself or someone who fits into the criteria as an awardee, fill this form.",
         " Be a nominee",
         "/apply/award",
         0
      ),
      applyFunc(
         mic,
         "Speaker",
         "DEFI Summer event (and other NFTNG events) will entail informative sessions from seasoned speakers. Prospective speakers must have shown expertise in the respective aspects of Web3 (including NFTs, DeFi, Gaming, Metaverse, Digital Art, Mining, Venture Capitalism) they will be speaking on. To nominate yourself or someone who fits into the criteria as a speaker, fill this form.",
         " Be a speaker now",
         "/apply/speaker",
         1
      ),
      applyFunc(
         people,
         "Volunteer",
         "NFTNG volunteer service program is geared towards providing skillful Web3 individuals with a platform to sharpen their skills and gain positive connections. Volunteers must be skillful in any of protocol/ushering, media (photography, graphics & animations, others), technical management, content creation, or public relations, and be willing to contribute to NFTNG. If you would like to volunteer for NFTNG, kindly fill this form.",
         "Volunteer for NFTng",
         "/apply/volunteer",
         0
      ),
      applyFunc(
         user,
         "sponsor",
         "NFTNG is currently seeking sponsors for its upcoming DEFI Summer event, as well as for the development and implementation of its various utilities. If you're interested in sponsoring DEFI Summer event or any of NFTNG's events or products, we invite you to become a sponsor today.",
         "Become sponsor",
         "/apply/sponsor",
         1
      ),
      applyFunc(
         user,
         "Partner",
         "NFTNG is looking for partners to form positive alliances in achieving its mission. f you're interested in sponsoring DEFI Summer event or any of NFTNG's events or products, we invite you to become a Partner today.",
         "Become a partner",
         "/apply/partner",
         0
      ),
   ];
   return (
      <ClientWrapper>
         <div className="apply">
            <div className="container">
               <div className="head py-4 uppercase">
                  <span className=" text-xl lg:text-2xl text-slate-500 font-bold">
                     Apply
                  </span>
               </div>
               <div className="row justify-between">
                  <div className="col-lg-8 col-12">
                     {applyArr.map((box, index) => (
                        <div
                           className={`box p-4 mb-4 ${
                              box.color === 0
                                 ? "bg-ng-primary-opacity"
                                 : "bg-ng-secondary-dark"
                           }`}
                           key={index}
                        >
                           <div className="box-head flex gap-2 items-center">
                              <img src={box.icon} alt="Apply" width={30} />{" "}
                              <span className="font-bold text-ng-primary text-xl uppercase">
                                 {box.title}
                              </span>
                           </div>
                           <div className="box-body text-slate-400 text-sm my-4 mb-1">
                              <span className="leading-8">{box.text}</span>
                              <div className="btn-wrap mt-4">
                                 <Link to={box.link}>
                                    <button className="w-full lg:w-auto bg-ng-primary text-xs text-black py-3 px-5 font-bold ">
                                       {box.btnText}
                                    </button>
                                 </Link>
                              </div>
                           </div>
                        </div>
                     ))}
                  </div>
                  <div className="col-lg-3 hidden  lg:block col-12">
                     <div className="quick-links bg-black p-2 pb-3 sticky-top">
                        <div className="head text-slate-400 p-3">
                           <span className="text-xl">Quick Links</span>
                        </div>
                        {applyArr.map((i, index) => (
                           <div
                              className="body items-center  text-md font-bold"
                              key={index}
                           >
                              <span className="px-3 text-ng-primary leading-10">
                                 {i.title}
                              </span>
                           </div>
                        ))}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </ClientWrapper>
   );
}
