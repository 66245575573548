import React from 'react'
import { FaBoltLightning } from 'react-icons/fa6';

export default function TaskSkeleton() {
    const dum = [1, 2, 3];

    return (
        <div className='grid lg:grid-cols-3 md:grid-cols-3 grid-cols-2'>
            {dum.map((task, index) => (
                <div
                    className="cards rounded-xl  m-2 mb-5 md:m-16 bg-ng-secondary-opacity placeholder-glow"
                    key={index}
                >
                    <div className="img-wrap">
                        <div
                            className=" w-full h-[150px] md:h-[200px] rounded-t-xl placeholder"
                        />
                    </div>
                    <div className="views text-sm font-thin text-ng-primary flex px-2 mt-3">
                        <span className="flex gap-1 items-center placeholder col-4 p-1 rounded-3xl">
                        </span>
                    </div>
                    <div className="text-wrap text-sm text-white p-2">
                        <span className={` placeholder col-12 p-1 rounded-3xl`}></span>
                        <span className={` placeholder col-6 p-1 rounded-3xl`}></span>
                        <br />
                    </div>
                    <div className="wrap flex justify-between items-center p-2 text-sm">
                        <span className="flex items-center gap-1 text-ng-primary">
                            <FaBoltLightning className="mx-auto text-lg text-yellow-300" />
                            <span className={` placeholder w-[50px] p-1 rounded-3xl`}></span>
                        </span>
                        <span className={` placeholder col-4 p-1 rounded-3xl`}></span>
                    </div>
                </div>
            ))}
        </div>
    )
}
