import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { post } from '../../../../functions/post'
import { RingLoader } from 'react-spinners'
import { useAccount, useConnect, useSendTransaction, useWriteContract } from 'wagmi'
import WalletLogin from '../../auth/WalletLogin'
import { abi } from '../../../../contract/MerchPurchaseAbi'
import { bsc, sepolia } from 'viem/chains'
import { parseEther } from 'viem'
import notify from '../../../../functions/notify'
import { FaCheck } from 'react-icons/fa'

export default function CryptoPayBox({ open, setOpen, ethAmount, setPayment_type, submit, setTx }) {

    const [isLoading, setIsLoading] = useState(false)
    const [sent, setSent] = useState(null)
    const [txHash, setTxHash] = useState('')

    const { isConnected } = useAccount()

    // Contract Section
    const {
        data: hash,
        isPending,
        sendTransactionAsync
    } = useSendTransaction()

    useEffect(() => {
        sent && notify('success', "Payment sent Successfully")
    }, [sent])

    const sendPayment = async () => {
        setIsLoading(true);

        try {
            // Make sure ethAmount, bsc.id, parseEther(), and sendTransactionAsync() are properly defined.
            const data = await sendTransactionAsync({
                to: '0xAa1bb591f0279EaEEc068a16149F47C065A125d8',
                value: parseEther(ethAmount),
                chainId: bsc.id
            });

            // Depending on your application logic, handle setting tx and txHash differently.
            setTx(data);
            setTxHash(data);
            setSent(true);
        } catch (error) {
            console.error(error);
            // Provide more specific error messages based on error types.
            if (error.message.includes('Insufficient Balance')) {
                notify('error', 'Insufficient Balance. Please ensure you have enough funds.');
            } else {
                notify('error', 'Transaction Failed. Please try again later.');
            }
        } finally {
            setIsLoading(false);
        }
    }




    const complete = async () => {
        setPayment_type("crypto")
    }

    const cancelButtonRef = useRef(null)

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-75 backdrop-blur-lg transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-[100vw] overflow-y-auto p-0 lg:p-auto">
                    <div className="flex min-h-full items-end justify-center p-2 lg:p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden bg-white w-[100vw] bg-opacity-10 rounded-lg text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <div className="wrap py-10">
                                    {!isConnected && (
                                        <div className="wrap text-center text-white p-2">
                                            <span>Connect Wallet to proceed</span>
                                            <div className="flex justify-center mt-2">
                                                <button className='bg-white p-2 px-3 rounded-3xl text-black'> <WalletLogin /></button>
                                            </div>
                                        </div>
                                    )}

                                    {isConnected && (
                                        <div className="wrap text-center text-white p-2">
                                            <WalletLogin />
                                        </div>
                                    )}


                                    {/* If Wallet is connected */}
                                    {isConnected && (
                                        <div className="wrap">
                                            <div
                                                className="w-full  mt-2 placeholder-glow text-3xl rounded-md text-white font-bold py-3 px-3 flex gap-2 justify-center items-center"
                                            // onClick={handleSubmit}
                                            >
                                                Pay <span className={`${!ethAmount && 'placeholder rounded-md w-[20px]'}`}>{ethAmount}</span> <img src="https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png" className="w-[40px]" alt="ETH" />
                                            </div>

                                            <div className="btn-wrap text-center">
                                                {sent === true ? <button className='text-sm bg-green-400 mx-auto text-black p-3 rounded-md flex items-center gap-2'>
                                                    Transaction Complete <FaCheck size={25} />
                                                </button> :
                                                    (<>
                                                        <button className='text-sm bg-green-400 text-black p-3 rounded-md' onClick={() => sendPayment()}>
                                                            {isLoading ? <RingLoader size={'25px'} /> : 'Checkout Now'}
                                                        </button> <br />
                                                    </>)}

                                                <div className="wrap mt-2">
                                                    {isLoading && (
                                                        <span className="text-sm text-yellow-400 italic">Confirm transaction in your wallet</span>
                                                    )}
                                                    {sent && (
                                                        <span className="text-sm text-gray-300 italic"> Please click on <b>Proceed</b> <br /> View <a href={`https://bscscan.com/tx/${txHash}`} target='_blank' className='text-blue-400'>transaction</a> on etherscan</span>
                                                    )}
                                                </div>
                                            </div>

                                        </div>
                                    )}
                                </div>
                                {sent && (
                                    <div className=" px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                        <button
                                            type="submit"
                                            className="w-full justify-center rounded-md bg-ng-primary px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-opacity-50 sm:ml-3 sm:w-auto"
                                            onClick={() => ethAmount > 0 && complete()}
                                        >
                                            Proceed
                                        </button>
                                    </div>
                                )}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root >
    )
}
