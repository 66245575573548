import React, { useEffect, useState } from 'react'
import Container from '../Components/Container'
import AdminHeader from '../Components/AdminHeader'
import Breadcrumb from '../../components/breadcrumb'
import Navigator from '../../components/navigator'
import { FaCopy } from 'react-icons/fa6'
import { FaTrash } from 'react-icons/fa'
import axios from 'axios'
import { getCookie } from '../../../functions/cookies'
import Loader from '../../components/loader'
import ConfirmDelete from '../Task/ConfirmDelete'
import copyToClipboard from '../../../functions/copyToClipboard'

export default function Sportbet() {

    const [task, setTask] = useState([])
    const [open, setOpen] = useState(false)
    const [taskId, setTaskId] = useState('')
    const [action, setAction] = useState(null)

    const [loading, setLoading] = useState(false)


    useEffect(() => {
        getTask()
    }, [])
    const token = getCookie('nftng-admin-token')

    const getTask = async () => {
        try {
            setLoading(true)
            const response = await axios.get(`${window.api}/admin/sportbet?action=get`, {
                headers: {
                    "nftng-admin-token": token,
                    "Content-Type": "multipart/form-data",
                },
            })
            setTask(response.data.message)
        } catch (error) {
            console.log(error)
        }
        finally {
            setLoading(false)
        }
    }

    const deleteTask = async (id) => {
        try {
            setLoading(true)
            await axios.get(`${window.api}/admin/sportbet?action=${action}&id=${id}`, {
                headers: {
                    "nftng-admin-token": token,
                    "Content-Type": "multipart/form-data",
                },
            })
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
            setOpen(false)
            getTask()
        }
    }

    const toggeleOpen = (passId, apiAction = false) => {
        setTaskId(passId)
        apiAction ? setAction(apiAction) : setAction("delete")
        setOpen(true)
    }
    return (
        <div className="wrap min-h-[100vh]">
            <Container>
                <AdminHeader />
                <Loader loading={loading} />
                <ConfirmDelete open={open} setOpen={setOpen} deleteFunc={deleteTask} id={taskId} />
                <Navigator link={"/admin/dashboard"} />
                <Breadcrumb
                    base={"Dashboard "}
                    current={"Sportbet.io Task "}
                    mbCrumb={"Sportbet.io Task "}
                />

                <div className="heading">
                    <div className="users">
                        <div className="table-responsive my-3">
                            <table className='w-full rounded-3xl overflow-clip'>
                                <thead className=' bg-ng-primary p-3'>
                                    <tr>
                                        <th className='p-3'>Username</th>
                                        <th className='p-3'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {task.map((i, index) => (
                                        <tr className=' text-gray-400 rounded-3xl bg-ng-primary-opacity p-3  '>
                                            <td className='min-w-[50vw]'>
                                                <div className="wrap flex gap-2 items-center p-3">
                                                    <span>{i.username}</span> <FaCopy onClick={() => {
                                                        copyToClipboard(i.username)
                                                    }} />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="wrap flex gap-3  px-3 py-3">
                                                    <span className='cursor-pointer bg-red-300 p-1 rounded' onClick={() => toggeleOpen(i._id)}><FaTrash className='text-red-600' /></span>
                                                    <span className={`cursor-pointer badge font-thin bg-ng-primary text-black`} onClick={() => toggeleOpen(i.user_id, 'reward-user')}>{"Reward"}</span>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}
