import React, { useEffect, useState } from "react";
import { FaBoltLightning } from "react-icons/fa6";
import { get } from "../../../../../../functions/get";
import { BeatLoader } from "react-spinners";
import { FaExclamationCircle } from "react-icons/fa";
import obscureEmail from "../../../../../../functions/obscure";

export default function Downlines() {
   const [downlines, setDownlines] = useState('')
   const [count, setCount] = useState(0)
   const [limit, setLimit] = useState(5)

   useEffect(() => {
      getDownlines()
   }, [limit])
   const getDownlines = async () => {
      try {
         const response = await get(`/user/profile/get-downlines?limit=${limit}`)
         setDownlines(response.data.message.downlines)
         setCount(response.data.message.count)
      } catch (error) {
         console.log(error)
      }
   }
   return (
      <div className="wrap px-3 mt-3">
         <div className="heading flex justify-between items-center">
            <span className="font-bold text-white text-lg">
               Total Invite (<small className="text-sm">{downlines.length}</small> / {count}){" "}
            </span>
            <span className="flex gap-3 text-gray-400">
               DSP Point Earned:{" "}
               <span className="flex items-center gap-1">
                  <FaBoltLightning className="text-yellow-300" /> {25 * downlines.length}
               </span>
            </span>
         </div>
         <div className="users">
            <ul className="my-10">
               <div className="flex bg-neutral-950 justify-between px-4 py-2 text-sm text-ng-primary text-opacity-70 capitalize rounded-t-3xl">
                  <span>referrals</span> <span>DSP point</span>
               </div>
               {!downlines && (
                  <div className="wrap flex justify-center py-10 px-4">
                     <BeatLoader size={'20'} color="#A1D283" />
                  </div>
               )}
               {downlines && (
                  <>
                     {
                        downlines.length === 0 && (
                           <div className="wrap p-4 text-center text-lg flex justify-center items-center gap-2 bg-ng-primary-opacity text-ng-primary font-bold rounded-b-xl">
                              <FaExclamationCircle /> <span>No Record Found</span>
                           </div>
                        )
                     }
                     {downlines.map((i, index) => (
                        <li className="flex justify-between items-center text-gray-400 rounded-3xl bg-ng-primary-opacity px-3 py-3 my-2" key={index} >
                           <div className="wrap flex gap-2 items-center">
                              <img
                                 src={i.pfp !== "" & i.pfp ? i.pfp : '/n8.png' }
                                 alt="user"
                                 className="w-[30px] h-[30px] rounded-full "
                              />
                              <span>{obscureEmail(i.email)}</span>
                           </div>
                           <span className="flex gap-1 items-center">
                              {" "}
                              {i.buzz_balance}{" "}
                              <FaBoltLightning className="text-yellow-300" />
                           </span>
                        </li>
                     ))}</>
               )}
            </ul>

            <div className="btn-wrap text-gray-400">
               <button className="border-2 border-ng-primary-opacity rounded-3xl p-2 px-3 text-sm " onClick={() => setLimit(limit + 5)}>
                  view more
               </button>
            </div>
         </div>
      </div>
   );
}
