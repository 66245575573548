import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../../pages/user/home/home";
import FaqsPage from "../../pages/user/home/faqs/faqs";
import Apply from "../../pages/user/apply/apply";
import Award from "../../pages/user/apply/award/award";
import Speaker from "../../pages/user/apply/speaker/speaker";
import Volunteers from "../../pages/user/apply/volunteers/volunteers";
import Sponsor from "../../pages/user/apply/sponsor/sponsor";
import Partner from "../../pages/user/apply/partner/partner";
import Contact from "../../pages/user/contact/contact";
import About from "../../pages/user/about/about";
import Event from "../../pages/user/event/event";
import Nominee from "../../pages/user/award/award";
import Product from "../../pages/user/product/product";
import Merch from "../../pages/user/product/mechandise/merch";
import Cart from "../../pages/user/product/cart/cart";
import Address from "../../pages/user/product/billing-address/address";
import Checkout from "../../pages/user/product/checkout/checkout";
import Dashboard from "../../pages/admin/dashboard/dashboard";
import AddProduct from "../../pages/admin/add-product/add-product";
import EditProduct from "../../pages/admin/edit-product/edit-product";
import Select from "../../pages/admin/edit-product/select/select";
import Login from "../../pages/user/auth/login";
import AdminLogin from "../../pages/admin/auth/admin-login";
import "react-toastify/dist/ReactToastify.css";
import Orders from "../../pages/admin/orders/orders";
import View from "../../pages/admin/orders/view-more/view";
import PaymentSuccess from "../../pages/user/product/checkout/isSuccess/PaymentSuccess";
import Twitter from "../../pages/DApp/twitter";
import OTP from "../../pages/user/auth/OTP";
// import NotFound from "./NotFound";
import CreateTask from "../admin/Task/CreateTask";
import Task from "../admin/Task/Task";
import Register from "../register/Register";
import Guests from "../admin/Guest/Guest-stats";
import Signup from "../user/auth/Signup";
import Raffle from "../admin/Raffles/raffle";
import Sportbet from "../admin/SportBet/Sportbet";
import AndriodRaffle from "../admin/Raffles/Andriod";
import MifiRaffle from "../admin/Raffles/Mifi";
import IphoneRaffle from "../admin/Raffles/Iphone";
import AdminIceTask from "../admin/ice/iceTask";

export default function NoHeaderWrapper() {
   return (
      <>
         <div className="bg-neutral-950">
            <Routes>
               {/* CLIENT ROUTES */}
               {/* ###################################################################################################### */}
               <Route exact path="/" element={<Home />} />
               <Route path="/auth/login" element={<Login />} />
               <Route path="/auth/signup" element={<Signup />} />
               <Route path="/home" element={<Home />} />
               <Route path="/home/faqs" element={<FaqsPage />} />
               {/* Apply routes */}
               <Route path="/apply" element={<Apply />} />
               <Route path="/apply/award" element={<Award />} />
               <Route path="/apply/speaker" element={<Speaker />} />
               <Route
                  path="/apply/volunteer"
                  element={<Volunteers />}
               />
               <Route path="/apply/sponsor" element={<Sponsor />} />
               <Route path="/apply/partner" element={<Partner />} />
               {/* Contact */}
               <Route path="/contact" element={<Contact />} />
               {/* About */}
               <Route path="/about" element={<About />} />
               {/* Events */}
               <Route path="/event" element={<Event />} />
               {/* Award */}
               <Route path="/award" element={<Nominee />} />


               {/* Product */}
               <Route path="/product" element={<Product />} />
               <Route
                  path="/product/merchandise"
                  element={<Merch />}
               />
               <Route path="/product/cart" element={<Cart />} />
               <Route
                  path="/product/cart/billing-address"
                  element={<Address />}
               />
               <Route
                  path="/product/cart/checkout"
                  element={<Checkout />}
               />
               <Route
                  path="/product/cart/checkout/payment-success"
                  element={<PaymentSuccess />}
               />

               {/* USER DASHBOARD ROUTES  */}
               {/* ###################################################################################################### */}
               <Route path="/auth/otp" element={<OTP />} />

               {/* ADMIN ROUTES */}
               {/* ###################################################################################################### */}
               <Route path="/admin/login" element={<AdminLogin />} />
               <Route
                  path="/admin/dashboard"
                  element={<Dashboard />}
               />
               <Route
                  path="/admin/add-product/merchandise"
                  element={<AddProduct />}
               />
               <Route
                  path="/admin/edit-product/merchandise"
                  element={<EditProduct />}
               />
               <Route
                  path="/admin/edit-product/select"
                  element={<Select />}
               />
               <Route path="/admin/orders" element={<Orders />} />
               <Route path="/admin/orders/view" element={<View />} />
               <Route path="/admin/task/create" element={<CreateTask />} />
               <Route path="/admin/task" element={<Task />} />
               <Route path="/admin/ice-task" element={<AdminIceTask />} />
               <Route path="/admin/guests" element={<Guests />} />
               <Route path="/admin/sportbet" element={<Sportbet />} />

               {/* Registration */}
               <Route path="/register" element={<Register />} />

               {/* raffle */}
               <Route path="/user/raffle/laptop" element={<Raffle />} />
               <Route path="/user/raffle/andriod" element={<AndriodRaffle />} />
               <Route path="/user/raffle/mifi" element={<MifiRaffle />} />
               <Route path="/user/raffle/iphone" element={<IphoneRaffle />} />


               {/* TEST */}
               <Route path="/test" element={<Twitter />} />
               {/* <Route path="*" element={<NotFound />} /> */}
            </Routes>
         </div>
      </>
   );
}
