import React, { useEffect, useState } from 'react'
import Container from '../Components/Container'
import AdminHeader from '../Components/AdminHeader'
import Breadcrumb from '../../components/breadcrumb'
import Navigator from '../../components/navigator'
import { FaBoltLightning } from 'react-icons/fa6'
import { FaDownload, FaEye, FaTrash, FaUsers } from 'react-icons/fa'
import { get } from '../../../functions/get'
import axios from 'axios'
import { getCookie } from '../../../functions/cookies'
import { RingLoader } from 'react-spinners'

export default function Guests() {
    const dum = [1, 2, 3, 4]
    const [guests, setGuests] = useState([])
    const [totalGuest, setTotalGuest] = useState(0)
    const [limit, setLimit] = useState(10)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getGuests()
    }, [limit])

    const getGuests = async () => {
        const token = getCookie('nftng-admin-token')

        try {
            setLoading(true)
            const response = await axios.get(`${window.api}/admin/guest-stats?limit=${limit}`, {
                headers: {
                    "nftng-admin-token": token,
                    "Content-Type": "application/json",
                },
            })
            setGuests(response.data.message.guest)
            setTotalGuest(response.data.message.totalGuest)

        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const downloadCSV = () => {
        const csvContent = "data:text/csv;charset=utf-8," +
            guests.map(guest => `${guest.name},${guest.email},${guest.phone_number}`).join("\n");
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "guests.csv");
        document.body.appendChild(link);
        link.click();
    };
    return (
        <div className="wrap min-h-[100vh]">
            <Container>
                <AdminHeader />
                <Navigator link={"/admin/dashboard"} />
                <Breadcrumb
                    base={"Guests"}
                    current={"Guests"}
                    mbCrumb={"Guests"}
                />

                <div className="heading">
                    <div className="users">
                        <div className="icon-menu text-slate-300">
                            <div className="wrap flex gap-3 text-sm">
                                <span className='flex gap-2 items-center'>
                                    <b className='flex items-center gap-2'><FaUsers className='text-ng-primary' />Total Guests:</b>
                                    <span className='text-xl'>{totalGuest}</span>
                                </span>
                                <span className='flex gap-2 items-center cursor-pointer' onClick={() => downloadCSV()}>
                                    <FaDownload className='text-ng-primary' /> <span>Download CSV File</span>
                                </span>
                            </div>
                        </div>
                        <div className="table-responsive mt-3">
                            <table className='w-full rounded-3xl overflow-clip'>
                                <thead className=' bg-ng-primary p-3'>
                                    <tr>
                                        <th className=''></th>
                                        <th className='p-3'>Name</th>
                                        <th className='p-3'>Email</th>
                                        <th className='p-3'>Phone No</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {guests.map((i, index) => (
                                        <tr className=' text-gray-400 rounded-3xl bg-ng-primary-opacity p-3' key={index}>
                                            <td className='p-1 text-center'>
                                                <span className='text-ng-primary font-bold'>{index + 1}</span>.
                                            </td>
                                            <td className='p-3'>
                                                {i.name}
                                            </td>
                                            <td className="p-3">
                                                {i.email}
                                            </td>
                                            <td className='p-3'>
                                                {i.phone_number}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="btn-wrap text-gray-400 mt-3">
                            <button className="border-2 border-ng-primary-opacity rounded-3xl p-2 px-3 text-sm" onClick={() => setLimit(limit + 2000)}>
                                {!loading ? "view more" : <RingLoader color='#A1D283' size={20} />}
                            </button>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}
