import React from 'react';
import BgGrad from '../../../components/BgGrad';
import { useNavigate } from 'react-router-dom';

export default function Ended() {
    const navi = useNavigate()
    return (
        <div className="wrap fixed z-[10000] top-0 right-0 bottom-0 left-0">
            <BgGrad>
                <div className="flex justify-center items-center h-screen w-screen">
                    <div className="text-wrap bg-gray-900 bg-opacity-50 p-10 text-gray-300 rounded-lg">
                        <h1 className=' text-ng-primary font-extrabold text-3xl'>Point System Ended</h1>
                        <p>Thank you for participating in the <span className=' text-ng-primary '>NFTNG DEFI SUMMER POINT SYSTEM.</span></p>
                        <p>We regret to announce that the point accumulation for the upcoming raffle has ended as of June 25th, 2024.</p>
                        <p>Qualifiers will be notified and will have a chance to win laptops, phones, and Mifis.</p>
                        <p>Check back soon for updates on our next event and system improvements.</p>

                        <div className="flex text-black gap-3 mt-2">
                        <button className='bg-white p-2 rounded-xl text-sm' onClick={() => navi('/')}>Go home</button>
                        <button className='bg-ng-primary p-2 rounded-xl text-sm' onClick={() => navi('/product/merchandise')}>Buy a Merch</button>
                        <button className='bg-green-500 p-2 rounded-xl text-sm' onClick={() => navi('/user/leaderboard')}>Leaderboard</button>
                        </div>
                    </div>
                </div>
            </BgGrad>
        </div>
    );
}
