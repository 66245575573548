import React, { useState } from "react";
import { GoogleLogin } from "@react-oauth/google";
import jwtDecode from "jwt-decode";
import Auth from "../../../functions/Auth";
import { storeToken } from "../../../functions/tokenLogger";
import Loader from "../../components/loader";
import axios from "axios";
import { getUrlParam } from "../../../functions/getUrlParameter";
import notify from "../../../functions/notify";
import { useNavigate } from "react-router-dom";
export default function OTP() {
   const [loading, setLoading] = useState(false);

   const [otp, setOTP] = useState('')
   const [err, setErr] = useState("");
   const userId = getUrlParam(window.location.href, "userId")

   const navi = useNavigate()


   const handleSubmit = async (e) => {
      e.preventDefault();
      try {
         setLoading(true)
         const data = { otp, userId }
         const response = await axios.post(`${window.api}/auth/verify-otp`, data)
         notify('success', "Email Verified Successfully")
         setTimeout(() => {
            navi('/auth/login')
         }, 1000);
      } catch (error) {
         console.log(error)
         setErr("Invalid OTP")
      }
      finally {
         setLoading(false)
      }
   };

   const resendCode = async () => {
      try {
         setLoading(true)
         const data = { otp, userId }
         await axios.post(`${window.api}/auth/resend-otp`, data)
         notify('success', "Check your email for a new otp code")
      } catch (error) {
         console.log(error)
      }
      finally {
         setLoading(false)
      }
   }
   return (
      <div className="login">
         <div className="container">
            <Loader loading={loading} />
            <div className="row justify-center items-center min-h-[80vh]">
               <div className="col-lg-6 rounded-lg flex flex-wrap text-center">
                  <form onSubmit={handleSubmit} className="w-full">
                     <div className="bg-black col-12 mx-auto p-5 rounded-2xl">
                        {/* Heading */}
                        <div className="form-text  text-white text-md leading-8  pb-3">
                           <span className="text-2xl font-bold text-ng-primary col-12">
                              OTP
                           </span>
                           <br />
                        </div>

                        {/* Input section */}
                        <div className="input-wrap  justify-between">
                           <div className="text-wrap text-left text-sm text-gray-300 font-thin mb-2">
                              <span>Check your email for OTP password</span>
                           </div>
                           <input
                              type="text"
                              placeholder="12345"
                              className={`w-full  p-3 rounded-md mb-4 text-slate-200 text-xs bg-transparent border-1 ${err === ""
                                 ? "border-ng-primary"
                                 : "border-red-500"
                                 } border-opacity-30 focus:outline-none focus:ring-2 `}
                              value={otp}
                              onChange={(e) => setOTP(e.target.value)}
                              required
                           />
                           <span className="text-danger capitalize">{err}</span>
                        </div>

                        {/* Recieve Text Setion */}
                        <div className="text-wrap flex justify-between items-center text-white">
                           <span>
                              Didn’t receive code{" "}
                              <span className="text-ng-primary cursor-pointer" onClick={resendCode}>Resend</span>
                           </span>
                        </div>

                        {/* Submit btn section */}
                        <div className="btn-wrap my-3 block">
                           <button
                              type="submit"
                              className="w-full px-3 bg-ng-primary rounded-md text-black text-sm font-bold py-3"
                           >
                              Verify Email Address
                           </button>
                        </div>
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </div>
   );
}
