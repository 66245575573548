import axios from "axios";

export const AuthPost = async (query, data) => {
   try {
      const response = await axios.post(`${window.api}${query}`, data, {
         headers: {
            "Content-Type": "application/json",
         },
      });
      return response;
   } catch (error) {
      throw error;
   }
};
