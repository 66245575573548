
import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { HiMiniArrowTopRightOnSquare } from 'react-icons/hi2'
import notify from '../../../../../functions/notify'
import { post } from '../../../../../functions/post'
import { RingLoader } from 'react-spinners'
import { FaExclamationTriangle } from 'react-icons/fa'
import { get } from '../../../../../functions/get'

export default function TaskDetails({ task, show, setShow, getTasks, isSportBet, isTurnUp }) {
   const [placeHolder, setPlaceHolder] = useState('')
   const [linkOpen, setLinkOpen] = useState(false)
   const [verification, setVerification] = useState('')
   const [loading, setLoading] = useState(false)

   const cancelButtonRef = useRef(null)

   useEffect(() => {
      switch (task.type) {
         case "follow":
            return setPlaceHolder("@username");
         case "comment":
            return setPlaceHolder("https://example.com/...");
         case "retweet":
            return setPlaceHolder("https://example.com/...")
         case 'like':
            return setPlaceHolder("@username")
         case 'quote_retweet':
            return setPlaceHolder("https://example.com/...")
         default:
            break;
      }
   }, [task.type])

   const completeTask = async () => {

      if (verification === "" && !isTurnUp) return notify("warning", "Fill the input field")

      if (!linkOpen) return notify("warning", "please complete task")
      try {
         setLoading(true)
         const data = {
            task_id: task._id,
            data: verification
         }
         let response;
         if (isSportBet) {
            response = await get('/user/sportbet-task?action=add-user')
         } else if (isTurnUp) {
            response = await get('/user/verify-turnUp')
         }
         else response = await post('/user/complete-task', data)
         if (response.data.message === "Already Completed") { return notify("warning", "Task Completed Previously") }
         getTasks()
         notify('success', "Task Completed Successfully")
      } catch (error) {
         notify('error', error.response.data.message)
         console.log(error)
      } finally {
         setShow(false)
         setLoading(true)
      }
   }
   return (
      <Transition.Root show={show} as={Fragment}>
         <Dialog className="relative z-10" initialFocus={cancelButtonRef} onClose={setShow}>
            <Transition.Child
               as={Fragment}
               enter="ease-out duration-300"
               enterFrom="opacity-0"
               enterTo="opacity-100"
               leave="ease-in duration-200"
               leaveFrom="opacity-100"
               leaveTo="opacity-0"
            >
               <div className="fixed inset-0 bg-neutral-900 backdrop-blur-xl bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
               <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                     as={Fragment}
                     enter="ease-out duration-300"
                     enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                     enterTo="opacity-100 translate-y-0 sm:scale-100"
                     leave="ease-in duration-200"
                     leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                     leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                     <Dialog.Panel className="relative transform rounded-lg overflow-clip bg-neutral-900 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">

                        <div className="wrap">
                           <div className="img-wrap ">
                              <img src={task.img} alt="" />
                           </div>

                           <div className="p-3">
                              <div className="header">
                                 <h1 className='font-bold text-lg text-gray-200'>{task.title}</h1>
                                 <span className='text-gray-400 text-sm'>{task.description} </span> <br />
                                 {isTurnUp && <p className='text-white mt-3'>Use the referral code <strong className='text-ng-primary'>influencerdee</strong></p>}
                              </div>

                              <div className="link-wrap flex py-2">
                                 <a href={task.link} target='_blank' rel="noreferrer" className='flex' onClick={() => setLinkOpen(true)} >
                                    <small className='text-lg p-2 rounded-full bg-ng-primary-opacity text-ng-primary cursor-pointer' ><HiMiniArrowTopRightOnSquare /> </small>
                                 </a>
                              </div>

                              <div className="input-wrap">
                                 {/* Task type = Follow */}
                                 {!isTurnUp && (
                                    <>
                                       <label className='text-xs font-bold text-gray-300'>Submit {" "}
                                          {task.type === "follow" && "Username"}
                                          {task.type === "comment" && "Link"}
                                          {task.type === "retweet" && "Retweet Link"}
                                          {task.type === "like" && "Username"}
                                       </label> <br />
                                       <input type="text" className=' bg-ng-primary-opacity p-2 rounded-xl text-sm text-ng-primary' placeholder={placeHolder} value={verification} onChange={(e) => setVerification(e.target.value)} />
                                    </>
                                 )}

                              </div>
                           </div>
                           <div className="my-2 text-sm" >
                              <span className='italic flex items-center px-3 gap-3 text-gray-400'>  <span className='text-yellow-400'><FaExclamationTriangle size={20} /></span> Any false completion of a task will result in disqualification. Ensure that you complete each task correctly, as they will be verified for every raffle qualifier.</span>
                           </div>
                        </div>
                        <div className="px-4 py-3 sm:flex sm:flex-row-reverse gap-2 sm:px-6">
                           <button
                              type="submit"
                              className="mt-3 inline-flex w-full justify-center rounded-md bg-ng-primary px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                              onClick={() => completeTask()}
                           >
                              {loading ? <RingLoader size={'25px'} /> : isTurnUp ? 'Verify Registration' : "Complete"}
                           </button>
                           <button
                              type="button"
                              className="mt-3 inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-gray-50 shadow-sm ring-1 ring-inset ring-gray-300  hover:bg-red-800 sm:mt-0 sm:w-auto"
                              onClick={() => setShow(false)}
                           // ref={cancelButtonRef}
                           >
                              Cancel
                           </button>
                        </div>
                     </Dialog.Panel>
                  </Transition.Child>
               </div>
            </div>
         </Dialog>
      </Transition.Root>
   )
}
