import axios from 'axios'

const Auth = async (email, username) => {
   try {
      const data = { email, username };
      const response = await axios.post(`${window.api}/auth/login`, data);
      const token = response.headers["nftng-user-token"];
      return token;
   } catch (error) {
      if (error) {
         console.log(error);
         return false;
      }
   }
};

export const AdminAuth = async (data) => {
   try {
      const response = await axios.post(`${window.api}/admin/login`, data);
      const token = response.headers["nftng-admin-token"];
      return token;
   } catch (error) {
      if (error) {
         console.log(error)
         return false;
      }
   }
}

export default Auth


