import React from "react";
import { Link } from "react-router-dom";
import left from "../../svgs/arrow-left.svg";

export default function Navigator({ link }) {
   return (
      <div className="py-3 pb-1">
         <div className="flex gap-2 text-ng-primary">
            <Link to={link}>
               <img src={left} alt="back" />
            </Link>
            <span>Back</span>
         </div>
      </div>
   );
}
