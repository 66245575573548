import React, { useEffect, useState } from 'react'
import { useAccount, useDisconnect } from 'wagmi'
import { getCookie, removeCookie, setCookie } from '../../../functions/cookies'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import { post } from '../../../functions/post'
import { AuthPost } from '../../../functions/authPost'
import { logout } from '../../../functions/tokenLogger'
import { useNavigate } from 'react-router-dom'
import { getUrlParam } from '../../../functions/getUrlParameter'


export default function WalletLogin({ mbHeader }) {
    const { disconnect } = useDisconnect()
    const { address, isConnected } = useAccount()

    // useEffect(() => {

    //     const upline = getUrlParam(window.location.href, "upline")
    // }, [isConnected])

    // const isSignedIn = async () => {
    //     if (isConnected) {
    //         return await getAuthToken();
    //     }
    //     removeCookie("nftng-user-token")
    // }

    // Get JWT token for usage
    const getAuthToken = async () => {
        // const upline = getUrlParam(window.location.href, "upline")
        // const data = {
        //     walletAddress: address,
        //     loginType: "walletConnect",
        //     upline: upline ? upline : "system"
        // }
        // try {
        //     const response = await AuthPost('/auth/login', data)
        //     const token = response.headers['nftng-user-token']
        //     setCookie("nftng-user-token", token)
        // } catch (error) {
        //     console.log(error)
        //     disconnect();
        // }
    }
    return (
        <ConnectButton
            label="Connect Wallet"
            showBalance={{
                smallScreen: false,
                largeScreen: false,
            }}
            accountStatus={{
                smallScreen: mbHeader ? 'full' : 'avatar',
                largeScreen: 'full',
            }}
        />
    )
}
