import React from "react";
import Header from "./header";
import MbHeader from "./mb-header";

export default function ClientWrapper({ children }) {
   return (
      <div className="wrap">
         <Header />
         <MbHeader />
         {children}
      </div>
   );
}
