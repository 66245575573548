import React, { useContext } from "react";

export default function MerchSkeleton() {
    const dum = [1, 2, 3, 4]
    return (
        <div className="wrao">
            <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-2 gap-3" >
                {dum.map((i, index) => (
                    <div className="mx-2" key={index}>
                        <div className="team placeholder-glow   border rounded-lg relative  h-[350px]   overflow-hidden">
                            {/* <img src={img} alt="TEAM" className="min-w-[100%] h-[auto]" /> */}
                            <div className="wrap h-[100%] bg-black placeholder col-12"></div>
                            <div className="absolute right-0 left-0 top-0 bottom-0  overlay"></div>
                            <div className="flex flex-wrap p-2 justify-between absolute right-0 left-0 bottom-0 img-con">
                                <div className="leading-3">
                                    <span className="text-white font-bold text-sm placeholder w-[100px] rounded-lg">...</span>{" "}
                                    <br />
                                    <span className="text-ng-primary text-xs placeholder w-[50px] py-2 rounded-lg"></span>
                                </div>
                                <span className="flex text-white gap-2 bottom-0">
                                    <button
                                        className="bg-ng-primary placeholder w-[70px]  text-black p-1 lg:p-3 py-1 rounded-md text-xs"
                                    >
                                        
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
