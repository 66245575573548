import React, { useContext, useEffect, useState } from "react";
import { FaBars, FaHome, FaArrowLeft, FaAward, FaImage, FaTrophy } from "react-icons/fa";
import { FaBoltLightning, FaShop } from "react-icons/fa6";
import { NavLink, useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";
import { logout } from "../../../../functions/tokenLogger";
import { get } from "../../../../functions/get";
import { setCookie } from "../../../../functions/cookies";
import getUserInfo from "../../../../functions/getUserInfo";
import { NumberFormatBase, NumericFormat } from 'react-number-format';
import { UserDetailsContext } from "../../../../context/UserDetailsContext";
import notify from "../../../../functions/notify";
import VerifyHolding from "../../VerifyHolder/VerifyHolding";
import Ended from "./Ended";
import VerifyQualifer from "../../VerifyQualifiers/VerifyQualifiers";

export default function UserLayout({ children, isDashboard, heading, }) {
   const navi = useNavigate()

   const [showLayout, setShowLayout] = useState(true)

   const { state, dispatch } = useContext(UserDetailsContext)

   const [open, setOpen] = useState(false)
   const [open2, setOpen2] = useState(false)


   const { isConnected, address } = useAccount()
   const menuFunc = (icon, text, link) => {
      return { icon, text, link };
   };

   const menuArr = [
      menuFunc(<FaHome />, "Dashboard", "/user/dashboard"),
      menuFunc(<FaAward />, "Leaderboard", "/user/leaderboard"),
      menuFunc(<FaShop />, "Store", "/user/store"),
   ];
   const [show, setShow] = useState(false);
   const [buzz_balance, setBuzz_balance] = useState(0)

   useEffect(() => {
      checkWindowSize();
      verifyIsConnected();
   }, []);


   const checkWindowSize = () => {
      const isMobile = window.innerWidth < 1024; // Adjust the breakpoint as needed
      setShow(!isMobile);
      const handleResize = () => {
         const isMobile = window.innerWidth < 1024; // Adjust the breakpoint as needed
         setShow(!isMobile);
      };
      window.addEventListener("resize", handleResize);
      return () => {
         window.removeEventListener("resize", handleResize);
      };
   }

   const verifyIsConnected = async () => {
      // check if wallet is connected
      // alert(address)
      // if (!address) return alert(address)
      const info = await getUserInfo()
      dispatch({ type: "set-info", payload: info })
   }

   return (
      <>
         {!showLayout && (<Ended />)}
         {showLayout && (
            <div className="user-menu bg-neutral-950">
               <VerifyHolding setOpen={setOpen} open={open} />
               <VerifyQualifer setOpen={setOpen2} open={open2} />
               <div className="flex">
                  <div
                     className={`side-menu lg:w-[300px] h-[100vh] bg-white relative ${!show && "d-none"
                        }`}
                  >
                     <div className="layout-menu wrap bg-ng-secondary-dark   fixed z-10 top-0 bottom-0 w-[80%] lg:w-[250px]">
                        <div className="logo-con p-3 flex justify-center py-6">
                           <img src="/favicon.ico" alt="NFTng" className="cursor-pointer" onClick={() => navi("/")} />
                        </div>
                        <div className="menu-con px-2">
                           <div className="label text-sm font-bold pt-2 text-gray-500">
                              <span>Main Menu</span>
                           </div>
                           <div className="menu-list mt-3">
                              <ul>
                                 {menuArr.map((menu, index) => (
                                    <li key={index} className="flex">
                                       <NavLink
                                          to={menu.link}
                                          className="flex mb-4 p-0 w-full"
                                       >
                                          <li
                                             className={`trans text-gray-500 px-2 hover:bg-slate-300 hover:rounded-lg hover:text-primary w-full`}
                                          >
                                             {" "}
                                             <a
                                                className={`d-flex gap-2 align-items-center  py-2 px-3 rounded-lg`}
                                             >
                                                {menu.icon} {menu.text}
                                             </a>
                                          </li>
                                       </NavLink>
                                    </li>
                                 ))}
                                 <li className="flex">
                                    <a
                                       className="flex mb-4 p-0 w-full"
                                       onClick={() => notify("error", "Reward ended!")}
                                    >
                                       <li
                                          className={`trans text-gray-500 px-2 hover:bg-slate-300 hover:rounded-lg hover:text-primary w-full`}
                                       >
                                          {" "}
                                          <a
                                             className={`d-flex gap-2 align-items-center  py-2 px-3 rounded-lg`}
                                          >
                                             <FaImage /> NFTng Pass Holder
                                          </a>
                                       </li>
                                    </a>
                                 </li>
                                 <li className="flex">
                                    <a
                                       className="flex mb-4 p-0 w-full"
                                       // onClick={() => setOpen2(true)}
                                       onClick={() => notify("error", "Reward ended!")}
                                    >
                                       <li
                                          className={`trans text-gray-500 px-2 hover:bg-slate-300 hover:rounded-lg hover:text-primary w-full`}
                                       >
                                          {" "}
                                          <a
                                             className={`d-flex gap-2 align-items-center  py-2 px-3 rounded-lg`}
                                          >
                                             <FaTrophy /> $ICE Qualifiers
                                          </a>
                                       </li>
                                    </a>
                                 </li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>

                  {/* Content Section */}
                  <div className="content w-100 min-h-[100vh]">
                     <div className="user-header  text-white col-lg-8  rounded-b-2xl mx-auto overflow-hidden">
                        <div className="bg-con backdrop-blur-xl px-4 bg-black rounded-b-2xl bg-opacity-50">
                           {/* Breadcrum (config later) */}

                           <div className="wrap flex  gap-3 justify-between p-3 text-lg font-bold">
                              <div className="icon-wrap cursor-pointer" onClick={() => window.history.back()}>
                                 <FaArrowLeft />
                              </div>
                              <div className="text-wrap text-ng-primary">
                                 <span>Unlock the $ice points</span>
                              </div>

                              <div
                                 className="icon-wrap"
                                 onClick={() => setShow(!show)}
                              >
                                 <span className="cursor-pointer">
                                    <FaBars />
                                 </span>
                              </div>
                           </div>

                           {/* POint Display */}
                           {isDashboard ? (
                              <>
                                 <div className="wrap flex justify-center items-center text-3xl gap-2 py-3">
                                    <span className="text-yellow-400">
                                       <FaBoltLightning />{" "}
                                    </span>{" "}
                                    <span>
                                       <NumericFormat
                                          value={state.buzz_balance}
                                          displayType={'text'}
                                          thousandSeparator={','}
                                       />
                                    </span>
                                 </div>

                                 <div className="menu">
                                    <div className="sub-link flex justify-between pt-4 pb-2 text-gray-300">
                                       <NavLink
                                          to={"/user/dashboard/profile"}
                                          className="w-full text-center"
                                       >
                                          Profile
                                       </NavLink>
                                       <NavLink
                                          to={"/user/dashboard/referral"}
                                          className="w-full text-center"
                                       >
                                          Referrals
                                       </NavLink>
                                       <NavLink
                                          to={"/user/dashboard/reward"}
                                          className=" w-full text-center"
                                       >
                                          Get Points
                                       </NavLink>
                                    </div>
                                 </div>
                              </>
                           ) : (
                              <div className="wrap text-3xl font-bold text-center py-5">
                                 <span>{heading}</span>
                              </div>
                           )}
                        </div>
                     </div>

                     {/* Page Content */}
                     <div className="w-100">
                        <div className="container mx-auto">
                           {children}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         )}
      </>
   );
}
