import React from "react";
import award from "../../../svgs/award.svg";
import ClientWrapper from "../../components/ClientWrapper";
import CoreTeam from "../about/components/core-team";

export default function Nominee() {
   return (
      <ClientWrapper>
         <div className="event">
            <div className="container">
               <div className="row justify-center min-h-screen">
                  <div className="col-lg-8 col-12">
                     <div className="col-12">
                        <div className="head py-4 uppercase">
                           <span className=" text-xl lg:text-2xl text-slate-50 font-bold">
                              Awards
                           </span>
                        </div>
                     </div>
                     {/* Speaker Con */}
                     <div className="speaker-wrap box p-4 bg-black">
                        <div className="box-head flex gap-2 items-center">
                           <img src={award} alt="Apply" width={30} />{" "}
                           <span className="font-bold text-ng-primary text-xl uppercase">
                              Nominees
                           </span>
                        </div>
                        <div className="box-body text-slate-50 text-sm my-4 mb-1">
                           <span className="text-2xl">
                              Coming Soon...
                           </span>
                        </div>
                     </div>
                     <div className="col">
                        {/* <CoreTeam /> */}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </ClientWrapper>
   );
}
