import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import WalletLogin from '../auth/WalletLogin'
import { useAccount } from 'wagmi'
import { get } from '../../../functions/get'
import { RingLoader } from 'react-spinners'
import { post } from '../../../functions/post'

export default function VerifyHolding({ setOpen, open }) {
    const [loading, setLoading] = useState(false)
    const [errMsg, setErrMsg] = useState(null)
    const [msg, setMsg] = useState(null)

    const [solAddress, setSolAddress] = useState('')

    const cancelButtonRef = useRef(null)
    const { address } = useAccount()

    useEffect(() => {
        //send a request to verify address
        handleVerification()
    }, [address])

    const handleVerification = async () => {
        if (!address) return false
        try {
            setLoading(true)
            const response = await get(`/user/verify-holding?address=${address.toLocaleLowerCase()}`)
            setErrMsg(null)
            setMsg(response.data.message)
        } catch (error) {
            console.log(error)
            setErrMsg(error.response.data.message)
        } finally {
            setLoading(false)
        }
    }

    const submitAddress = async () => {
        if (solAddress === '') return false

        try {
            setLoading(true)
            const data = { solAddress }
            const response = await post(`/user/submit-soladdress`, data)
            setErrMsg(null)
            setMsg(response.data.message)
            console.log(response)
        } catch (error) {
            console.log(error)
            setErrMsg(error.response.data.message)
        } finally {
            setLoading(false)
        }
    }
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-900 bg-opacity-75 backdrop-blur-lg transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-ng-secondary-dark text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <div className="wrap py-16 px-5">
                                    <div className="header text-center text-gray-300 mb-3">
                                        <h1 className=' text-ng-primary font-bold text-2xl'>$ICE token Eligibility</h1>
                                        <p>If you hold an NFTng Pass NFT, you're automatically eligible to Claim an $ICE token airdrop. Connect your wallet to check your eligibility.</p>
                                    </div>
                                    <div className="btn-wrap flex justify-center ">
                                        <button className="p-2 px-5 bg-ng-primary-opacity rounded-full text-white">
                                            <WalletLogin />
                                        </button> <br />
                                    </div>

                                    {loading && (
                                        <div className="wrap text-center mt-2">
                                            <span className="text-sm text-yellow-400 italic">Verifying your address...</span> <br />
                                            <div className="flex justify-center text-ng-primary">
                                                {loading ? <RingLoader size={'25px'} color='#A1D283' /> : 'Checkout Now'}
                                            </div>
                                        </div>
                                    )}
                                    {errMsg && (
                                        <div className="text-center">
                                            <span className='text-sm text-center text-red-500'>{errMsg}</span>
                                        </div>
                                    )}
                                    {msg && (
                                        <div className="text-center mt-3">
                                            <span className='text-sm text-center text-green-500'>{msg}</span>
                                            <div className="input-wrap">
                                                <input type="text" className='text-center w-full p-3 text-gray-200 bg-ng-secondary rounded-xl' placeholder='Submit Solana Wallet Address' value={solAddress} onChange={(e) => setSolAddress(e.target.value)} />
                                                {solAddress.length === 44 && (
                                                    <button className='bg-ng-primary bg-opacity-80 text-slate-100 p-2 mt-3 rounded-full px-3 hover:text-black hover:bg-opacity-100' onClick={submitAddress}>{loading ? <RingLoader size={'25px'} color='#A1D283' /> : 'Submit'}</button>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {/* <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                                        onClick={() => setOpen(false)}
                                    >
                                        Deactivate
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                        onClick={() => setOpen(false)}
                                        ref={cancelButtonRef}
                                    >
                                        Cancel
                                    </button>
                                </div> */}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
