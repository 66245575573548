import "./App.css";
import "./styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SessionProvider } from "next-auth/react";
import Footer from "./pages/components/footer";
import ScrollToTop from "./pages/components/scroll";
import ContextWrap from "./pages/components/context-wrap";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "react-toastify/dist/ReactToastify.css";
import NoHeaderWrapper from "./pages/components/NoHeaderWrapper";
import UserDashboardRoute from "./pages/components/routes/UserDashboardRoute";



import '@rainbow-me/rainbowkit/styles.css';
import {
   getDefaultConfig,
   RainbowKitProvider, darkTheme
} from '@rainbow-me/rainbowkit';
import { WagmiProvider, http } from 'wagmi';
import {
   mainnet, sepolia, bsc
} from 'wagmi/chains';
import {
   QueryClientProvider,
   QueryClient,
} from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";

const config = getDefaultConfig({
   appName: 'NFTng DApp',
   projectId: 'a1246319c8393fe03bf0d3e269dca7ca',
   chains: [mainnet],
   transports: {
      [mainnet.id]: http(),
   },
   ssr: true, // If your dApp uses server side rendering (SSR)
});

const queryClient = new QueryClient();


function App() {
   // window.api = "http://localhost:7000";
   window.api = "https://nftng-server-y7ru.onrender.com";
   return (
      <>
         <ToastContainer />
         <WagmiProvider config={config}>
            <QueryClientProvider client={queryClient}>
               <RainbowKitProvider coolMode modalSize="compact"
                  theme={darkTheme({
                     accentColor: 'white',
                     accentColorForeground: 'black',
                     borderRadius: 'large',
                     fontStack: 'system',
                     overlayBlur: 'small',
                  })}
               >
                  <SessionProvider>
                     <GoogleOAuthProvider clientId="778909111486-4bfnu64elqrbj5t3fifcafpfcdnr53nc.apps.googleusercontent.com">
                        <ContextWrap>
                           <div className="App">
                              <ScrollToTop />
                              <UserDashboardRoute />
                              <NoHeaderWrapper />
                              <Footer />
                           </div>
                        </ContextWrap>
                     </GoogleOAuthProvider>
                  </SessionProvider>
               </RainbowKitProvider>
            </QueryClientProvider>
         </WagmiProvider>


      </>

   );
}

export default App;
