import React from "react";
import { Link } from "react-router-dom";
import Dropdown from "./dropdown";
export default function Faqs() {

   const imgArr = ["/CHILL DOGS.png", "/buds.png", "/TRIBE.png", "/FANCATS.png"];
   const faqFunc = (title, text) => {
      return { title, text };
   };
   const faqArr = [
      faqFunc(
         "What is the NFT_NG Pass?",
         "The NFTNG Pass is a NFT Pass that gives holders exclusive lifetime access to all NFTNG events and products. "
      ),
      faqFunc(
         "  What is NFT_NG Vision?  ",
         "Bringing together digital creators, developers, thought leaders and other Web3 enthusiasts in the African Space and empowering them with opportunities to thrive on a global scale."
      ),
      faqFunc(
         " What utilities does the NFTNG pass have? ",
         "The NFTNG pass gives holders exclusive access to the DEFI Summer event as well as a discount on the DEFI Summer Merchandise. Also, the pass grants holders a lifetime access to other NFTNG products and events. "
      ),
      faqFunc(
         " What is the date and venue of the DEFI Summer Event? ",
         "The DEFI Summer event will hold on the 26th of June  2024 in Lagos, Nigeria. "
      ),
   ];
   return (
      <div className="faqs py-10 ">
         <div className="container">
            <div className="row">
               <div className="col-lg-6 mb-3">
                  <div className="faq-text">
                     <div className="head py-4">
                        <h1 className="font-poppins text-ng-primary text-4xl font-bold">
                           Frequently asked <br /> Questions
                        </h1>
                     </div>
                     <div className="body">
                        <span className="text-gray-400">
                           Have questions? We've got answers! Explore our frequently asked questions to find the information you need.
                        </span>{" "}
                        <br /> <br />
                        <small className="text-ng-primary hidden lg:block text-lg underline">
                           <Link to={"/home/faqs"}>See more</Link>
                        </small>
                     </div>
                  </div>
               </div>
               <div className="col-lg-6  items-center">
                  {faqArr.map((i, index) => (
                     <Dropdown text={i.text} title={i.title} key={index} />
                  ))}
               </div>
               <small className="text-ng-primary text-lg underline lg:hidden">
                  <Link to={"/home/faqs"}>See more</Link>
               </small>
            </div>
         </div>
      </div>
   );
}
