import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import notify from "../../../functions/notify";
import { getToken } from "../../../functions/tokenLogger";
import Breadcrumb from "../../components/breadcrumb";
import Navigator from "../../components/navigator";
import { NumericFormat } from "react-number-format";
import FormatNumber from "../../components/FormatNumber";

export default function Orders() {
   const [orders, setOrders] = useState([]);
   useEffect(() => {
      getOrders();
   }, []);

   const navi = useNavigate();

   const getOrders = async () => {
      try {
         const response = await axios.get(
            `${window.api}/admin/get-orders/all`,
            {
               headers: {
                  "nftng-admin-token": getToken("admin"),
               },
            }
         );
         setOrders(response.data);
      } catch (error) {
         console.log(error);
         notify("error", error.message);
      }
   };

   const payAmount = (ordered_product) => {
      let totalAmount = 0;

      for (let i = 0; i < ordered_product.length; i++) {
         totalAmount +=
            ordered_product[i].product_price *
            ordered_product[i].product_quantity;
      }
      return totalAmount;
   };
   const productQty = (ordered_product) => {
      let totalAmount = 0;

      for (let i = 0; i < ordered_product.length; i++) {
         totalAmount += ordered_product[i].product_quantity;
      }
      return totalAmount;
   };
   return (
      <div className="orders">
         <div className="container">
            <div className="heading">
               <Navigator link={"/admin/dashboard"} />
               <Breadcrumb
                  base={"Product"}
                  current={"orders"}
                  mbCrumb={"All orders"}
               />{" "}
            </div>

            {/* Table  */}

            <div className="table-wrap text-light overflow-scroll">
               <table className=" bg-ng-primary-opacity rounded-lg min-w-[100%]">
                  <thead>
                     <tr className="p-3">
                        <th className="p-3">Product</th>
                        <th className="p-3">Status</th>
                        <th className="p-3">Quantity</th>
                        <th className="p-3">Price</th>
                        <th className="p-3"></th>
                     </tr>
                  </thead>
                  <tbody>
                     {orders.map((order, index) => (
                        <tr key={index}>
                           {/* product */}
                           <td className="p-3 flex flex-wrap items-center gap-3">
                              {order.ordered_product.length} items ordered
                           </td>
                           {/* status */}
                           <td className="p-3 items-center">
                              <small className="rounded-pill badge bg-ng-primary text-black p-2">
                                 {order.status}
                              </small>
                           </td>
                           <td className="p-3">
                              {productQty(order.ordered_product)}
                           </td>
                           <td className="p-3">
                              <FormatNumber number={payAmount(order.ordered_product)} />
                           </td>
                           <td className="p-3">
                              <button
                                 className="border-1 border-ng-primary rounded-md text-white p-2"
                                 onClick={() =>
                                    navi(`view?order_id=${order._id}`)
                                 }
                              >
                                 View More
                              </button>
                           </td>
                        </tr>
                     ))}
                  </tbody>
               </table>
            </div>
         </div>
      </div>
   );
}
