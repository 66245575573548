import React from "react";
import ClientWrapper from "../../components/ClientWrapper";

export default function Contact() {
   return (
      <ClientWrapper>
         <div className="contact">
            <div className="container min-h-[80vh]">
               <div className="col-lg-12 mx-auto py-5">
                  <form action="">
                     <div className="col-lg-6 bg-black mx-auto p-5">
                        <div className="form-text  text-white text-md leading-8  pb-3">
                           <span className="text-ng-primary font-bold text-3xl">
                              Contact us
                           </span>{" "}
                           <br />
                           <span>For inquiries, kindly fill this form</span>
                        </div>

                        <div className="input-wrap  justify-between">
                           <select className="   p-3 rounded-md mb-4 text-slate-400 text-xs bg-transparent border-1 border-ng-primary border-opacity-30 focus:outline-none focus:ring-2 ">
                              <option selected>Title*</option>
                           </select>
                           <input
                              type="text"
                              placeholder="Fullname"
                              className="w-full   p-3 rounded-md mb-4 text-slate-200 text-xs bg-transparent border-1 border-ng-primary border-opacity-30 focus:outline-none focus:ring-2 "
                              required
                           />
                           <input
                              type="text"
                              placeholder="Email address"
                              className="w-full  p-3 rounded-md mb-4 text-slate-200 text-xs bg-transparent border-1 border-ng-primary border-opacity-30 focus:outline-none focus:ring-2 "
                              required
                           />
                        </div>
                        <div className="input-wrap">
                           <input
                              type="text"
                              placeholder="Twitter handles"
                              className="w-full  p-3 rounded-md mb-4 text-slate-200 text-xs bg-transparent border-1 border-ng-primary border-opacity-30 focus:outline-none focus:ring-2 "
                              required
                           />
                        </div>
                        <div className="input-wrap">
                           <textarea
                              rows={5}
                              placeholder="Type your message"
                              className="w-full  p-3 rounded-md mb-4 text-slate-200 text-xs bg-transparent border-1 border-ng-primary border-opacity-30 focus:outline-none focus:ring-2 "
                              required
                           />
                        </div>
                        <div className="btn-wrap my-3 block">
                           <button className="w-full px-3 bg-ng-primary rounded-md text-black text-sm font-bold py-3">
                              Submit
                           </button>
                        </div>
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </ClientWrapper>
   );
}
