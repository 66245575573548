import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Loader from "../../../components/loader";
import UserLayout from "../../dashboard/components/UserLayout";
import { getUrlParam } from "../../../../functions/getUrlParameter";
import { get } from "../../../../functions/get";
import { NumericFormat } from "react-number-format";
import FormatNumber from "../../../components/FormatNumber";

export default function OrderDetails() {
   const [loading, setLoading] = useState(false);
   const [order, setOrder] = useState({
      amount: 87,
      details: {
         delivery_address: "...",
         delivery_city: "...",
         delivery_state: "...",
         email: "",
         firstname: "...",
         lastname: "...",
         phone: "..."
      },
      ordered_product: [],
      status: "...",
   })
   const order_id = getUrlParam(window.location.href, 'order_id')

   useEffect(() => {
      getOrder()
   }, [])

   const getOrder = async () => {
      try {
         const response = await get(`/user/store/user-orders?order_id=${order_id}`)
         const userOrder = response.data.message
         setOrder(userOrder)
      } catch (error) {
         console.log(error)
      }
   }

   return (
      <UserLayout heading={"Order Details"}>
         <div className="checkout col-lg-8 mx-auto">
            <div className="container">
               <Loader loading={loading} />
               <ToastContainer theme="dark" />

               <div className="header"></div>
               <div className="body">
                  <div className="row justify-between">
                     <>
                        <div className="col-lg4 mb-4 bg-ng-secondary-dark lg:px-5 col-12">
                           <div className="items text-white">
                              <div className="head py-2">
                                 <span className="text-xl text-ng-primary font-bold">
                                    Order (#2321) Details ({order.ordered_product.length})
                                 </span>
                                 <hr className="mt-3" />
                              </div>
                              <div className="grid sm:grid-cols-2 gap-3 py-4">
                                 {order.ordered_product.map((i) => (
                                    <div
                                       className="items-con bg-neutral-950 rounded-lg"
                                       key={i}
                                    >
                                       <div className="item-card flex w-full gap-3 leading-8">
                                          <img
                                             src={i.product_img}
                                             alt="Items"
                                             className="w-[100px] rounded-l-lg"
                                          />
                                          <div className="item-data">
                                             <span className="text-lg font-bold text-ng-primary">
                                                {i.product_name}
                                             </span>{" "}
                                             <br />
                                             <span className="text-gray-400  ">
                                                Price: ${i.product_price}
                                             </span>{" "}
                                             <br />
                                             <span className="text-xs">
                                                Qty: {i.product_quantity}
                                             </span>
                                          </div>
                                       </div>
                                    </div>
                                 ))}
                              </div>
                           </div>
                        </div>
                        <div className="col-lg8 col-12 lg:p-10 lg:bg-black text-light">
                           <div className="bill  border-ng-primary lg:border bg-ng-secondary-dark lg:bg-transparent py-4 p-3 my-3">
                              <div className="address leading-6">
                                 <div className="head flex justify-between pb-3">
                                    <span className="font-bold">
                                       Address Details
                                    </span>{" "}
                                 </div>
                                 <div className="name pb-3">
                                    <span className="text-ng-primary">
                                       Name:{" "}
                                    </span>{" "}
                                    <br />
                                    <span>
                                       {order.details.firstname} {order.details.lastname}
                                    </span>
                                 </div>

                                 <div className="address pb-3">
                                    <span className="text-ng-primary">
                                       Address:{" "}
                                    </span>{" "}
                                    <br />
                                    <span>
                                       {order.details.delivery_address}, {order.details.delivery_city}, {" "}
                                       {order.details.delivery_state}
                                    </span>
                                 </div>
                                 <div className="email pb-3">
                                    <span className="text-ng-primary">
                                       Email:{" "}
                                    </span>{" "}
                                    <br />
                                    <span>{order.details.email}</span>
                                 </div>
                                 <div className="phone pb-3">
                                    <span className="text-ng-primary">
                                       Phone Number:{" "}
                                    </span>{" "}
                                    <br />
                                    <span>{order.details.phone}</span>
                                 </div>
                              </div>
                           </div>
                           <div className="price p-3 leading-8 pt-3 bg-ng-secondary-dark">
                              <div className=" flex justify-between">
                                 <span>Subtotal</span>{" "}
                                 <span className="font-bold">
                                    <FormatNumber number={order.amount} />
                                 </span>
                              </div>
                              {/* <div className=" flex justify-between">
                                 <span>Delivery fee</span>{" "}
                                 <span className="font-bold">$10</span>
                              </div> */}
                              <div className=" flex justify-between">
                                 <span>Discount</span>{" "}
                                 <span className="font-bold">&#8358;0</span>
                              </div>
                              <hr className="my-3" />
                              <div className=" flex justify-between">
                                 <span>Delivery Status</span>{" "}
                                 <div className="wrap">
                                    <span className="font-bold badge bg-danger">
                                       {order.status}
                                    </span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </>
                  </div>
               </div>
            </div>
         </div>
      </UserLayout>
   );
}
