export const INITIAL_STATE = {
   id: "",
   product_img: "",
   product_name: "",
   product_price: "",
   product_quantity: "",
   product_size: "",
};

export const editReducer = (state, action) => {
   switch (action.type) {
      case "p-id":
         return { ...state, id: action.payload };
      case "p-img":
         return { ...state, product_img: action.payload };
      case "p-name":
         return { ...state, product_name: action.payload };
      case "p-price":
         return { ...state, product_price: action.payload };
      case "p-quantity":
         return { ...state, product_quantity: action.payload };
      case "p-size":
         return { ...state, product_size: action.payload };
      default:
         break;
   }
};
