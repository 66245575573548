import React from "react";
import { NumericFormat } from "react-number-format";
import { Link } from "react-router-dom";
import FormatNumber from "../../../components/FormatNumber";

export default function Payout({ state }) {
   let amt = 0;

   const payAmount = () => {
      let totalAmount = 0;

      for (let i = 0; i < state.length; i++) {
         totalAmount += state[i].product_price * state[i].product_quantity
      }
      return totalAmount
   };
   return (
      <div className="cart-summary bg-ng-secondary-dark p-4">
         <div className="head text-ng-primary text-2xl font-bold py-3">
            <span>Cart Summary</span>
            <hr className="mt-2" />
         </div>
         <div className="body text-white">
            <div className="flex justify-between">
               <span>Subtotal</span>
               <span className="text-ng-primary text-2xl font-bold">
                  &#8358;
                  <NumericFormat
                     value={payAmount()}
                     displayType={'text'}
                     thousandSeparator={','}
                  />
               </span>
            </div>
            <div className="leading-8">
               <span className="font-bold text-ng-primary">10% Discount</span>{" "}
               <br /> <span>Delivery fee not Included</span>{" "}
            </div>
            <div className="btn-wrap mt-3">
               <Link to={"billing-address"}>
                  <button className="bg-ng-primary w-full font-bold text-xs text-black rounded-lg p-2">
                     Proceed checkout <FormatNumber number={payAmount()} />
                  </button>
               </Link>
            </div>
         </div>
      </div>
   );
}
