import React from "react";
import { Route, Routes } from "react-router-dom";
import Profile from "../../user/dashboard/Container/Profile/Profile";
import Referral from "../../user/dashboard/Container/Referral/Referral";
import Reward from "../../user/dashboard/Container/Reward/Reward";
import Leaderboard from "../../user/Leaderboard/Leaderboard";
import OrderDetails from "../../user/Store/Orders/OrderDetails";
import Orders from "../../user/Store/Orders/Orders";
import Store from "../../user/Store/Store";
import NotFound from "../NotFound";

export default function UserDashboardRoute() {
   return (
      <Routes>
         <Route path="/user/dashboard/reward" element={<Reward />} />
         <Route path="/user/dashboard/profile" element={<Profile />} />
         <Route path="/user/dashboard" element={<Reward />} />
         <Route path="/user/dashboard/referral" element={<Referral />} />
         <Route path="/user/leaderboard" element={<Leaderboard />} />
         <Route path="/user/store" element={<Store />} />
         <Route path="/user/store/orders/" element={<Orders />} />
         <Route path="/user/store/order/detail" element={<OrderDetails />} />
         {/* <Route path="*" element={<NotFound />} /> */}
      </Routes>
   );
}
