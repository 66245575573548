import React from "react";
import bank from "../../../svgs/bank.svg";
import people from "../../../svgs/people.svg";
import house from "../../../svgs/house.svg";
import mic from "../../../svgs/microphone.svg";
import Workers from "./components/workers";
import FullCard from "./components/full-card";
import EventSpeaker from "./components/event-speaker";
import ClientWrapper from "../../components/ClientWrapper";

export default function Event() {
   const menu = [
      "Venue",
      "Speakers",
      "Sponsor/ Partners",
      "Accommodation",
      "Flight Booking",
   ];
   const imgArr = ["guardian.JPG", "thecable.JPG", "pulse.JPG", "sportbet.PNG"];

   return (
      <ClientWrapper>
         <div className="event">
            <div className="container">
               <div className="head py-4 uppercase">
                  <span className=" text-xl lg:text-2xl text-slate-50 font-bold">
                     Event
                  </span>
               </div>
               <div className="row justify-between">
                  <div className="col-lg-8 col-12">
                     {/* Venue Con */}
                     <div className="venue-wrap box p-4 bg-ng-secondary-opacity">
                        <div className="box-head flex gap-2 items-center">
                           <img src={bank} alt="Apply" width={30} />{" "}
                           <span className="font-bold text-ng-primary text-xl uppercase">
                              Venue
                           </span>
                        </div>
                        <div className="box-body text-slate-50 text-sm my-4 mb-1">
                           <span className="leading-8">
                              Discover our exceptional venue, meticulously designed to host memorable events and create unforgettable experiences. From elegant spaces to state-of-the-art facilities, we provide the perfect setting for your special occasion.
                           </span>
                        </div>
                        <div className="img-box py-2">
                           <img
                              src="/Landmark.jpg"
                              alt="Event"
                              className="img-fluid"
                              height={200}
                           />
                        </div>
                     </div>

                     {/* Speaker Con */}
                     <div className="speaker-wrap box p-4 bg-black">
                        <div className="box-head flex gap-2 items-center">
                           <img src={mic} alt="Apply" width={30} />{" "}
                           <span className="font-bold text-ng-primary text-xl uppercase">
                              Speaker
                           </span>
                        </div>
                        <div className="box-body text-slate-50 text-sm my-4 mb-1">
                           <span className="leading-8">
                              Prepare yourself for an extraordinary lineup of
                              speakers featuring top-notch talent. Meet the
                              speakers for NFTng 2023/24 as they share their
                              insights and engage with us on various topics.
                           </span>
                        </div>
                     </div>
                     <div className="col">
                        {/* <CoreTeam /> */}
                        <EventSpeaker />
                     </div>

                     <div className="workers-section my-5">
                        <Workers
                           header={"Sponsors"}
                           icon={people}
                           imgArr={imgArr}
                           text={
                              "Introducing the remarkable sponsors for the NFTng event in 2024."
                           }
                        />
                     </div>

                     <div className="bookings mb-4">
                        <FullCard
                           bg={1}
                           icon={house}
                           header={"Accomodation"}
                           text={
                              "NFTNG, through its partnerships, will provide attendees of DEFI Summer event with comfortable accommodation close to the venue of the event at discounted and affordable rates. To request for accommodation booking, kindly contact "
                           }
                           btnText={"Get Accomodation"}
                        />
                     </div>
                     <div className="bookings">
                        <FullCard
                           icon={house}
                           header={"Flight Booking "}
                           text={
                              "NFTNG will assist attendees of DEFI Summer event to procure flight tickets on comfortable and affordable airlines. Also, airport to hotel conveyance will be arranged for attendees on request. To request for flight booking and airport conveyance, kindly contact "
                           }
                           btnText={"Book Flight"}
                        />
                     </div>
                  </div>

                  <div className="col-lg-3 hidden  lg:block col-12">
                     <div className="quick-links bg-black p-2 pb-3 sticky-top">
                        <div className="head text-slate-400 p-3">
                           <span className="text-xl">Quick Links</span>
                        </div>
                        {menu.map((i, index) => (
                           <div
                              className="body items-center  text-md font-bold"
                              key={index}
                           >
                              <span className="px-3 text-ng-primary leading-10">
                                 {i}
                              </span>
                           </div>
                        ))}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </ClientWrapper>
   );
}
