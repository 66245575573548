import React, { useState } from "react";
import { GoogleLogin } from "@react-oauth/google";
import jwtDecode from "jwt-decode";
import Auth from "../../../functions/Auth";
import { storeToken } from "../../../functions/tokenLogger";
import Loader from "../../components/loader";
import ClientWrapper from "../../components/ClientWrapper";

import { ConnectButton } from '@rainbow-me/rainbowkit';
import { Connection, useConnect } from 'wagmi'
import ConnectWallet from "./ConnectWallet";

import { useAccount, useEnsName, } from 'wagmi'
import WalletLogin from "./WalletLogin";
import { HiMiniArrowTopRightOnSquare } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { AuthPost } from "../../../functions/authPost";
import { setCookie } from "../../../functions/cookies";

export default function Login() {
   const [loading, setLoading] = useState(false);
   const [email, setEmail] = useState("");
   const [password, setPassword] = useState("");


   const [err, setErr] = useState("");
   const navi = useNavigate()

   const handleLogin = async (credentialResponse) => {
      try {
         setLoading(true);
         const decoded = jwtDecode(credentialResponse.credential);
         const token = await Auth(decoded.email, decoded.name);
         localStorage.setItem("email", decoded.email);
         if (token) storeToken(token, "user");
         setLoading(false);
      } catch (error) {
         console.log(error);
         setLoading(false);
      }
   };


   const handleSubmit = async (e) => {
      e.preventDefault();
      try {
         setLoading(true)
         const data = {
            email,
            password,
            loginType: "auth",
         }
         const response = await AuthPost('/auth/login', data)
         const token = response.headers['nftng-user-token']
         setCookie("nftng-user-token", token)
         navi('/user/dashboard')
      } catch (error) {
         if (error.response.data.info === "User hasn't verified") {
            const userId = error.response.data.message.id
            return navi(`/auth/otp?userId=${userId}`)
         }
         console.log(error)
         setErr(error.response.data.message)
      } finally {
         setLoading(false)
      }
   }


   return (
      <ClientWrapper>
         <div className="login">
            <div className="container">
               <Loader loading={loading} />
               <div className="row justify-center items-center min-h-[80vh]">
                  <div className="col-lg-6 rounded-lg flex flex-wrap text-center">
                     <form onSubmit={handleSubmit} className="w-full">
                        <div className="bg-black col-12 mx-auto p-5 rounded-2xl">
                           <div className="form-text  text-white text-md leading-8  pb-3">
                              <span className="text-2xl text-white font-bold col-12">
                                 Login to
                                 <span className="font-bold text-ng-primary">
                                    {" "}
                                    NFTNG
                                 </span>
                              </span>{" "}
                              <br />
                           </div>
                           <div className="input-wrap  justify-between">
                              <input
                                 type="email"
                                 placeholder="Email"
                                 className={`w-full  p-3 rounded-md mb-4 text-slate-200 text-xs bg-transparent border-1 ${err === ""
                                    ? "border-ng-primary"
                                    : "border-red-500"
                                    } border-opacity-30 focus:outline-none focus:ring-2 `}
                                 required
                                 value={email}
                                 onChange={(e) => setEmail(e.target.value)}
                              />
                              <input
                                 type="password"
                                 placeholder="Password"
                                 className={`w-full  p-3 rounded-md mb-4 text-slate-200 text-xs bg-transparent border-1 ${err === ""
                                    ? "border-ng-primary"
                                    : "border-red-500"
                                    } border-opacity-30 focus:outline-none focus:ring-2 `}
                                 required
                                 value={password}
                                 onChange={(e) => setPassword(e.target.value)}
                              />{" "}
                              <span className="text-danger capitalize">
                                 {err}
                              </span>
                           </div>
                           <div className="btn-wrap my-3 block">
                              <button
                                 type="submit"
                                 className="w-full px-3 bg-ng-primary rounded-md text-black text-sm font-bold py-3"
                              >
                                 LOGIN
                              </button>
                           </div>

                           <div className="wrap text-left">
                              <span className="text-sm text-gray-300 cursor-pointer" onClick={() => navi('/auth/signup')}>Don't have an account yet? <span className="text-ng-primary">Sign up today</span></span>
                           </div>


                           {/* <div className="wrap flex justify-center gap-2">
                              {isConnected && (
                                 <button className="bg-ng-primary bg-opacity-40 px-[10px] rounded-full" onClick={() => navi('/user/dashboard')}><HiMiniArrowTopRightOnSquare size={20} className="text-ng-primary" /></button>
                              )}
                              <WalletLogin />
                           </div> */}
                           {/* <div className="wrap text-center text-white font-bold my-3 ">
                              <span>or</span>
                           </div>{" "}
                           <div className="google-wrap col-12 flex justify-center">
                              <GoogleLogin
                                 onSuccess={(credentialResponse) => {
                                    handleLogin(credentialResponse);
                                 }}
                                 onError={() => {
                                    console.log("Login Failed");
                                 }}
                                 useOneTap
                                 className="w-full"
                              />
                           </div> */}
                        </div>
                     </form>


                     {/* <span className="text-2xl text-white font-bold col-12">
                     Login to
                     <span className="font-bold text-ng-primary"> NFTNG</span>
                  </span>{" "} */}
                     {/* <br /> <br />
                  <div className="google-wrap col-12 flex justify-center">
                     <GoogleLogin
                        onSuccess={(credentialResponse) => {
                           handleLogin(credentialResponse);
                        }}
                        onError={() => {
                           console.log("Login Failed");
                        }}
                        useOneTap
                     />
                  </div> */}
                  </div>
               </div>
            </div>
         </div>
      </ClientWrapper>
   );
}
