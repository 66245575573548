import React from "react";
import ClientWrapper from "../../../components/ClientWrapper";

export default function FaqsPage() {
   const faqFunc = (title, text) => {
      return { title, text };
   };
   const faqArr = [
      faqFunc(
         "What is the NFT_NG Pass?",
         "The NFTNG Pass is a NFT Pass that gives holders exclusive lifetime access to all NFTNG events and products."
      ),
      faqFunc(
         "  What is NFT_NG Vision?  ",
         "The NFTNG Pass is a NFT Pass that gives holders exclusive lifetime access to all NFTNG events and products. "
      ),
      faqFunc(
         " What utilities does the NFTNG pass have? ",
         "The NFTNG pass gives holders exclusive access to DEFI Summer event as well as a discount on DEFI Summer Merchandise. Also, the pass grants holders a lifetime access to other NFTNG products and events. "
      ),
      faqFunc(
         " What is the date and venue of DEFI Summer Event? ",
         "DEFI Summer event will hold on the 26th of June 2024 in Lagos, Nigeria. "
      ),
      faqFunc(
         " How many NFTNG Passes would be available? ",
         "999 NFT pass will be available for grabs "
      ),
      faqFunc(
         " Which blockchain would the NFTNG passes be launched on? ",
         "The NFTNG Pass will be launched on the Ethereum blockchain. "
      ),
      faqFunc(
         " What is Mint Price? ",
         "The mint price will absolutely be free "
      ),
      faqFunc(" When is the Mint date? ", "1st of October, 2022. "),
      faqFunc(
         " How can I partner/collab/work with the NFTNG project? ",
         "Collab and volunteering forms are available here. Kindly fill them and the team will reach out to you. "
      ),
   ];
   return (
      <ClientWrapper>
         <div className="faqs-p">
            <div className="container">
               <div className="row justify-between">
                  <div className="col-lg-8 col-12">
                     <div className="head py-5 pb-3">
                        <span className="text-slate-50 text-xl lg:text-2xl font-bold">
                           FAQs
                        </span>
                     </div>
                     {faqArr.map((faq, index) => (
                        <div className="faq-text">
                           <div className="ft-head uppercase text-ng-primary font-bold text-xl">
                              <span>
                                 {index + 1} {faq.title}
                              </span>
                           </div>
                           <div className="ft-body py-3 mb-3 text-sm leading-8">
                              <span className="text-slate-400">{faq.text}</span>
                           </div>
                        </div>
                     ))}
                  </div>
                  <div className="col-lg-3 col-12 hidden lg:block">
                     <div className="quick-links bg-black p-2 pb-3 mt-10 sticky-top">
                        <div className="head text-slate-400 p-3">
                           <span className="text-xl">Quick Links</span>
                        </div>
                        {faqArr.map((i, index) => (
                           <div className="body flex items-center text-slate-50 text-xs">
                              <span className="px-3 text-ng-primary leading-9 ">
                                 {index + 1}
                              </span>
                              <span className="leading-4">{i.title}</span>
                           </div>
                        ))}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </ClientWrapper>
   );
}
