import React, { useContext, useRef, useState } from "react";
import Breadcrumb from "../../components/breadcrumb";
import Navigator from "../../components/navigator";
import gallery from "../../../svgs/gallery-add.svg";
import { Link, useNavigate } from "react-router-dom";
import { EditContext } from "../../../context/edit-context";
import axios from "axios";
import Loader from "../../components/loader";
import notify from "../../../functions/notify";
import { ToastContainer } from "react-toastify";
import { getToken } from "../../../functions/tokenLogger";

export default function EditProduct() {
   const { state, dispatch } = useContext(EditContext);
   const [selectedSize, setSelectedSize] = useState(state.product_size);
   const [loading, setLoading] = useState(false);

   const [selectedFile, setSelectedFile] = useState(".");
   const [displayImage, setdisplayImage] = useState(state.product_img);
   const navi = useNavigate()

   const clickFile = useRef(null);

   const sizes = ["m", "l", "xl", "xxl"];

   const selectFile = (val) => {
      setSelectedFile(val);
      const validImage = val[0];

      const reader = new FileReader();
      reader.onload = (e) => {
         setdisplayImage(e.target.result);
      };
      reader.readAsDataURL(validImage);
   };
   // click the file input
   const clickFileFunc = () => clickFile.current.click();

   // Submit file and data to api
   const handleSubmit = async (e) => {
      setLoading(true);
      e.preventDefault();
      try {
         const data = {
            product_image: selectedFile,
            product_name: state.product_name,
            product_price: state.product_price,
            product_quantity: state.product_quantity,
            product_size: state.product_size,
         };

         // Get Admin Token
         const token = getToken("admin")

         const response = await axios.put(
            `${window.api}/admin/edit-product/${state.id}`,
            data,
            {
               headers: {
                  "nftng-admin-token": token,
                     "Content-Type": "multipart/form-data",
               },
            }
         );
         setLoading(false);
         navi("/admin/edit-product/select")
         notify("success", "Product edited successfully");
      } catch (error) {
         console.log(error);
         setLoading(false);
         notify("error", "Something went wrong");
         console.log(error)
      }
   };

   return (
      <div className="add-prd">
         <div className="container">
            <Navigator link={"/admin/edit-product/select"} />
            <Loader loading={loading} />
            <ToastContainer theme="dark" position="bottom-right" />

            <div className="col-lg-8 mx-auto lg:min-h-[73vh]">
               <Breadcrumb
                  base={"Edit Product"}
                  current={"merchandise"}
                  mbCrumb={"edit product"}
               />

               <div className="row">
                  <div className="col-lg-6 col-12 flex">
                     <div className="img-view mb-3 w-full flex items-center justify-end">
                        <div className="wrap text-center w-full">
                           <img
                              src={displayImage}
                              alt="gallery-add"
                              className="mx-auto mb-2"
                           />
                           <div
                              className="flex gap-2 justify-center items-center border-1 border-ng-primary py-2 text-ng-primary text-sm"
                              onClick={clickFileFunc}
                           >
                              <img src={gallery} alt="gallery-add" width={25} />{" "}
                              <span>Change Image</span>
                           </div>
                        </div>
                     </div>
                  </div>

                  {/* File uploader */}
                  <input
                     type="file"
                     className="d-none"
                     ref={clickFile}
                     onChange={(e) => selectFile(e.target.files)}
                  />

                  <div className="col-lg-6 col-12">
                     <form onSubmit={handleSubmit}>
                        <div className="input-wrap">
                           <input
                              type="text"
                              placeholder="Edit product name"
                              className="w-full  p-3 rounded-md mb-4 text-slate-200 text-xs bg-transparent border-1 border-ng-primary border-opacity-30 focus:outline-none focus:ring-2 "
                              value={state.product_name}
                              onChange={(e) =>
                                 dispatch({
                                    type: "p-name",
                                    payload: e.target.value,
                                 })
                              }
                              required
                           />
                        </div>
                        <div className="input-wrap">
                           <div className="border-1 px-4 mb-4  border-ng-primary border-opacity-30 flex items-center rounded-md">
                              <span className="text-light font-bold">
                                 Price
                              </span>
                              <input
                                 type="Product Price"
                                 placeholder="Edit product price"
                                 className="w-full  p-3 rounded-md  text-slate-200 text-xs bg-transparent  focus:outline-none focus:ring-2"
                                 value={state.product_price}
                                 onChange={(e) =>
                                    dispatch({
                                       type: "p-price",
                                       payload: e.target.value,
                                    })
                                 }
                                 required
                              />
                           </div>
                        </div>
                        <span className="text-white font-">Size</span> <br />
                        <div className="flex gap-2 pt-2 mb-4">
                           {sizes.map((size, sizeIndex) => (
                              <span
                                 className={`text-xs text-ng-primary rounded-4xl flex-initial border-1 border-ng-primary-opacity px-3 py-2 uppercase ${
                                    selectedSize === size ? "selected-size" : ""
                                 }`}
                                 key={sizeIndex}
                                 onClick={() => {
                                    dispatch({
                                       type: "p-size",
                                       payload: size,
                                    });
                                    setSelectedSize(size);
                                 }}
                              >
                                 {size}
                              </span>
                           ))}
                        </div>
                        <div className="input-wrap">
                           <div className="border-1 px-4 mb-4  border-ng-primary border-opacity-30 flex items-center rounded-md">
                              <span className="text-light font-bold">Qty</span>
                              <input
                                 type="number"
                                 placeholder="Edit product quantity"
                                 className="w-full  p-3 rounded-md  text-slate-200 text-xs bg-transparent  focus:outline-none focus:ring-2"
                                 required
                                 value={state.product_quantity}
                                 onChange={(e) =>
                                    dispatch({
                                       type: "p-quantity",
                                       payload: e.target.value,
                                    })
                                 }
                              />
                           </div>
                        </div>
                        <div className="btn-wrap flex justify-end">
                           <button className="px-3 py-2 text-black rounded-md w-full lg:w-auto bg-ng-primary font-bold">
                              Save Edit
                           </button>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}
