import React, { useRef, useState } from "react";
import Breadcrumb from "../../components/breadcrumb";
import Navigator from "../../components/navigator";
import gallery from "../../../svgs/gallery-add.svg";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../../components/loader";
import { toast, ToastContainer } from "react-toastify";
import { getToken } from "../../../functions/tokenLogger";

export default function AddProduct() {
   const [selectedSize, setSelectedSize] = useState("xl");
   const [viewImage, setViewImage] = useState(null);
   const [product_image, setProduct_image] = useState("");
   const [product_name, setProduct_name] = useState("");
   const [product_price, setProduct_price] = useState("");
   const [product_size, setProduct_size] = useState(selectedSize);
   const [product_quantity, setProduct_quantity] = useState("");
   const [loading, setLoading] = useState(false);

   const fileInputRef = useRef(null);

   const sizes = ["m", "l", "xl", "xxl"];
   const navi = useNavigate();

   const clickFileInput = () => {
      fileInputRef.current.click();
   };
   const selectedImage = (val) => {
      setProduct_image(val);

      if (val) {
         const validImage = val[0];
         const reader = new FileReader();

         reader.onload = (e) => {
            setViewImage(e.target.result);
         };
         reader.readAsDataURL(validImage);
      }
   };
   const token = getToken("admin");

   const notifySuccess = () => toast.success("Producted listed");
   const notifyError = () => toast.error("Unknown error");

   const handleSubmit = async (e) => {
      try {
         e.preventDefault();
         setLoading(true);
         const data = {
            product_image,
            product_name,
            product_price,
            product_quantity,
            product_size: selectedSize,
         };
         const response = await axios.post(
            `${window.api}/admin/add-product`,
            data,
            {
               headers: {
                  "nftng-admin-token": token,
                  "Content-Type": "multipart/form-data",
               },
            }
         );
         setLoading(false);
         notifySuccess();
         navi("/admin/edit-product/select");
      } catch (error) {
         console.log(error)
         notifyError();
         setLoading(false);
      }
   };
   return (
      <div className="add-prd">
         <div className="container">
            <Loader loading={loading} />
            <Navigator link={"/admin/dashboard"} />
            <ToastContainer theme="dark" position="bottom-right" />

            <div className="col-lg-8 mx-auto lg:min-h-[73vh]">
               <Breadcrumb
                  base={"Add Product"}
                  current={"merchandise"}
                  mbCrumb={"add product"}
               />

               <div className="row">
                  <div className="col-lg-6 col-12 flex">
                     <div className="img-view py-5 px-2 mb-3 w-full flex items-center justify-center bg-ng-secondary-opacity text-slate-400 font-thin">
                        <div className="wrap text-center">
                           <Link>
                              {viewImage === null && (
                                 <>
                                    <img
                                       src={gallery}
                                       alt="gallery-add"
                                       className="mx-auto mb-2"
                                       onClick={clickFileInput}
                                    />
                                    <span>Add Product Image</span>
                                 </>
                              )}

                              {viewImage !== null && (
                                 <>
                                    <img
                                       src={viewImage}
                                       alt="gallery-add"
                                       className="mx-auto mb-2 img-fluid"
                                    />
                                    <div
                                       className="flex gap-2 justify-center items-center border-1 border-ng-primary py-2 text-ng-primary text-sm"
                                       onClick={clickFileInput}
                                    >
                                       <img
                                          src={gallery}
                                          alt="gallery-add"
                                          width={25}
                                       />
                                       <span> Change Image</span>
                                    </div>
                                 </>
                              )}
                           </Link>
                        </div>
                        <div className="form-group">
                           <input
                              type="file"
                              hidden
                              ref={fileInputRef}
                              onChange={(e) => selectedImage(e.target.files)}
                              required
                           />
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-6 col-12">
                     <form onSubmit={handleSubmit}>
                        <div className="input-wrap">
                           <input
                              type="text"
                              placeholder="Product Name"
                              className="w-full  p-3 rounded-md mb-4 text-slate-200 text-xs bg-transparent border-1 border-ng-primary border-opacity-30 focus:outline-none focus:ring-2 "
                              required
                              value={product_name}
                              onChange={(e) => setProduct_name(e.target.value)}
                           />
                        </div>
                        <div className="input-wrap">
                           <div className="border-1 px-4 mb-4  border-ng-primary border-opacity-30 flex items-center rounded-md">
                              <span className="text-light font-bold">
                                 Price
                              </span>
                              <input
                                 type="number"
                                 placeholder="Product Price"
                                 className="w-full  p-3 rounded-md  text-slate-200 text-xs bg-transparent  focus:outline-none focus:ring-2"
                                 required
                                 value={product_price}
                                 onChange={(e) =>
                                    setProduct_price(e.target.value)
                                 }
                              />
                           </div>
                        </div>
                        <span className="text-white font-">Size</span> <br />
                        <div className="flex gap-2 pt-2 mb-4">
                           {sizes.map((size, sizeIndex) => (
                              <span
                                 className={`text-xs text-ng-primary rounded-4xl flex-initial border-1 border-ng-primary-opacity px-3 py-2 uppercase ${
                                    selectedSize === size ? "selected-size" : ""
                                 }`}
                                 key={sizeIndex}
                                 onClick={() => setSelectedSize(size)}
                              >
                                 {size}
                              </span>
                           ))}
                        </div>
                        <div className="input-wrap">
                           <div className="border-1 px-4 mb-4  border-ng-primary border-opacity-30 flex items-center rounded-md">
                              <span className="text-light font-bold">Qty</span>
                              <input
                                 type="number"
                                 placeholder="Product Quantity"
                                 className="w-full  p-3 rounded-md  text-slate-200 text-xs bg-transparent  focus:outline-none focus:ring-2"
                                 required
                                 value={product_quantity}
                                 onChange={(e) =>
                                    setProduct_quantity(e.target.value)
                                 }
                              />
                           </div>
                        </div>
                        <div className="btn-wrap flex justify-end">
                           <button
                              type="submit"
                              className="px-3 py-2 text-black rounded-md w-full lg:w-auto bg-ng-primary font-bold"
                           >
                              List Product
                           </button>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}
