import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../../../../context/cartContext";
import notify from "../../../../functions/notify";
import { getToken } from "../../../../functions/tokenLogger";
import Breadcrumb from "../../../components/breadcrumb";
import Loader from "../../../components/loader";
import Navigator from "../../../components/navigator";
import FormatNumber from "../../../components/FormatNumber";
// import NoProduct from "../components/no-product";

export default function View() {
   const { state } = useContext(CartContext);
   const [order, setOrder] = useState({});
   const [loading, setLoading] = useState(false);
   const [subtotal, setSubtotal] = useState(0);

   useEffect(() => {
      getOrder();
   }, []);

   const payAmount = (ordered_product) => {
      let totalAmount = 0;

      for (let i = 0; i < ordered_product.length; i++) {
         totalAmount +=
            ordered_product[i].product_price *
            ordered_product[i].product_quantity;
      }
      return setSubtotal(totalAmount);
   };
   const token = getToken("admin") // getting admin jwt token
   const navi = useNavigate()

   const urlString = window.location.href;
   const url = new URL(urlString);
   const orderId = url.searchParams.get("order_id");
   const getOrder = async () => {
      try {
         setLoading(true);
         const response = await axios.get(
            `${window.api}/admin/get-orders/${orderId}`,
            {
               headers: {
                  "nftng-admin-token": token
               },
            }
         );
         setOrder(response.data);
         payAmount(response.data.ordered_product);
         setLoading(false);
      } catch (error) {
         console.log(error);
         setLoading(false);
         notify("error", "An Error Occurred");
      }
   };

   const updateOrders = async (status) => {
      try {
         setLoading(true);
         const response = await axios.post(
            `${window.api}/admin/update-order/${orderId}`,
            { status },
            {
               headers: {
                  "nftng-admin-token": token,
               },
            }
         );
         setLoading(false);
         navi("/admin/orders")
      } catch (error) {
         console.log(error);
         notify("error", "An Error Occured");
         setLoading(false);
      }
   };
   return (
      <div className="checkout">
         <div className="container">
            <Navigator link={"/admin/orders"} />
            <Loader loading={loading} />

            <div className="header">
               <Breadcrumb
                  base={"product"}
                  current={"orders / view"}
                  mbCrumb={"Order Details"}
               />
            </div>
            <div className="body">
               <div className="row justify-between">
                  <>
                     <div className="col-lg-3 mb-4 bg-ng-secondary-dark lg:px-5 col-12">
                        <div className="items text-white">
                           <div className="head py-2">
                              <span className="text-xl text-ng-primary font-bold">
                                 Ordered items (1)
                              </span>
                              <hr className="mt-3" />
                           </div>
                           {/* Map here */}
                           {order.ordered_product && (
                              <div className="wrap">
                                 {order.ordered_product.map(
                                    (product, index) => (
                                       <div
                                          className="items-con mb-4"
                                          key={index}
                                       >
                                          <div className="item-card flex w-full gap-3 leading-8">
                                             <img
                                                src={product.product_img}
                                                alt="Items"
                                                className="w-[100px]"
                                             />
                                             <div className="item-data">
                                                <span className="text-lg">
                                                   {"Hoodie Cap"}
                                                </span>{" "}
                                                <br />
                                                <span className="text-ng-primary font-bold ">
                                                   <FormatNumber number={product.product_price} />
                                                </span>{" "}
                                                <br />
                                                <span className="text-xs">
                                                   Qty:{" "}
                                                   {product.product_quantity}
                                                </span> <br />
                                                <span className="text-xs">
                                                   Size:{" "}
                                                   {product.product_size}
                                                </span>
                                             </div>
                                          </div>
                                       </div>
                                    )
                                 )}
                              </div>
                           )}
                        </div>
                     </div>
                     <div className="col-lg-8 col-12 lg:p-10 lg:bg-black text-light">
                        {order.details && (
                           <div className="bill  border-ng-primary lg:border bg-ng-secondary-dark lg:bg-transparent py-4 p-3 my-3">
                              <div className="address leading-6">
                                 <div className="head flex justify-between pb-3">
                                    <span className="font-bold">
                                       Address Details
                                    </span>{" "}
                                 </div>
                                 <div className="name pb-3">
                                    <span className="text-ng-primary">
                                       Name:{" "}
                                    </span>{" "}
                                    <br />
                                    <span>
                                       {order.details.firstname}{" "}
                                       {order.details.lastname}
                                    </span>
                                 </div>

                                 <div className="address pb-3">
                                    <span className="text-ng-primary">
                                       Address:{" "}
                                    </span>{" "}
                                    <br />
                                    <span>
                                       {order.details.delivery_address},{" "}
                                       {order.details.delivery_city},{" "}
                                       {order.details.delivery_state}
                                    </span>
                                 </div>
                                 <div className="email pb-3">
                                    <span className="text-ng-primary">
                                       Email:{" "}
                                    </span>{" "}
                                    <br />
                                    <span>{order.details.email}</span>
                                 </div>
                                 <div className="email pb-3">
                                    <span className="text-ng-primary">
                                       Phone no:{" "}
                                    </span>{" "}
                                    <br />
                                    <span>+234{order.details.phone}</span>
                                 </div>
                              </div>
                           </div>
                        )}
                        {order.ordered_product && (
                           <div className="price p-3 leading-8 pt-3 bg-ng-secondary-dark">
                              <div className=" flex justify-between">
                                 <span>Subtotal</span>{" "}
                                 <span className="font-bold"><FormatNumber number={subtotal} /></span>
                              </div>
                              <div className=" flex justify-between">
                                 <span>Discount</span>{" "}
                                 <span className="font-bold">0</span>
                              </div>
                              <hr className="my-3" />
                              <div className=" flex justify-between">
                                 <span>Total</span>{" "}
                                 <span className="font-bold text-ng-primary">
                                    <FormatNumber number={subtotal} />
                                 </span>
                              </div>
                              <div className="btn-wrap my-3 flex gap-3">
                                 <button
                                    className="w-full  bg-ng-primary rounded-md text-black text-sm font-bold py-3 px-3"
                                    onClick={() => updateOrders("delivered")}
                                 >
                                    Order Delivered
                                 </button>
                                 <button
                                    className="w-full  rounded-md border-1 border-ng-primary text-ng-primary text-sm font-bold py-3 px-3"
                                    onClick={() => updateOrders("closed")}
                                 >
                                    Close Order
                                 </button>
                              </div>
                           </div>
                        )}
                     </div>
                  </>
               </div>
            </div>
         </div>
      </div>
   );
}
