import React from "react";

const cardFunc = (text, img, bg) => {
   return { text, img, bg };
};
const cardArr = [
   cardFunc(
      "To empower the next generation of builders, creatives, and thought leaders from the African community.",
      "/m1.png",
      "bg-ng-secondary-dark"
   ),
   cardFunc(
      "Bringing NFTs and Blockchain Technology to the attention of the mainstream audience and community.",
      "/m2.png",
      "bg-ng-primary-opacity"
   ),
   cardFunc(
      "Creating a tight-knit web3 community that will thrive off each other and bring value to the ecosystem.",
      "/m3.png",
      "bg-ng-secondary-dark"
   ),
   cardFunc(
      "Enlightening the mainstream community about the endless opportunities that can be created with blockchain technology and the importance of mass adoption.",
      "/m4.png",
      "bg-ng-primary-opacity"
   ),
];

export default function Buzz() {
   return (
      <div className="buzz py-16" id="buzz">
         {/* Target */}
         <div className="container">
            <div className="buzz-head text-center mb-4">
               <span className="font-bold text-4xl text-slate-50">OUR <span className="text-ng-primary">MISSION</span></span>
            </div>
            <div className="">
               <div className="grid">
                  {cardArr.map((card, index) => (
                     <div className={`grid  lg:grid-cols-3 py-4 ${index === 1 | index === 3  && " grid-row-reverse "} ${card.bg} items-center mb-5 gap-14`} key={index}>

                        <div className={`img-wrap ${index === 1 | index === 3  && " order-3 "} flex justify-center w-full col-12`}>
                           <img
                              src={card.img}
                              alt="Buzz Target"
                              className="w-[300px] mx-auto"
                           />
                        </div>
                        <div className="text-wrap lg:col-span-2 text-center">
                           <div className="px-5">
                              <span className="text-ng-primary font-bold text-3xl ">
                                 0{index + 1}
                              </span>
                           </div>

                           <div className="text-xl text-gray-200 mt-10 leading-8 px-5">
                              <span>{card.text}</span>
                           </div>
                        </div>
                     </div>
                  ))}
               </div>
            </div>
         </div>

      </div>
   );
}
