import React from "react";
import { FaDiscord, FaTwitter } from "react-icons/fa";
import Slider from "react-slick";

export default function CoreTeam({ header }) {
   const dum = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
   const teamFunc = (img, name, role, twitter, discord) => ({ img, name, role, twitter, discord })

   const teamArr = [
      teamFunc('/team/teddi.JPG', "Teddi / King.sol", "Founder", "#", "#"),
      teamFunc('/team/someh.JPG', "Someh Crypto", "Co-Founder", "#", "#"),
      teamFunc('/team/juju_girl.JPG', "Juju Girl", "Creative Lead", "#", "#"),
      teamFunc('/team/jj.jpeg', "Joe", "Co Founder", "#", "#"),
      teamFunc('/team/ama.JPG', "Ama Crypt", " Marketing Lead", "#", "#"),
      teamFunc('/team/sheezey.jpeg', "Sheezey", "Website Engineer & Blockchain dev", "https://twitter.com/sheezey_", "#"),
      teamFunc('/team/jemima.JPG', "Jemima Damina", "Marketing and PR Strategist", "#", "#"),
      teamFunc('/team/swagnito.JPG', "Swagnito", "Event Coordinator", "#", "#"),
      teamFunc('/team/fiona.JPG', "Fiona", "SMM", "#", "#"),
      teamFunc('/team/princess.jpg', "Princess Eno", "Team Member", "#", "#"),
      teamFunc('/team/frimtee.jpeg', "Frimtee", "Team Member", "#", "#"),
      teamFunc('/team/demitchy.JPG', "Demitchy", "Team Member", "#", "#"),
      teamFunc('/team/drogo.JPG', "Drogo", "Team Member", "#", "#"),
      teamFunc('/team/tokyo.JPG', "Tokyo", "Team Member", "#", "#"),
      teamFunc('/team/off.jpg', "Off Shoda", "Team Member", "#", "#"),
      teamFunc('/team/tubby.jpg', "Rex Vickz", "Blockkchain Dev", "#", "#"),
      teamFunc('/team/harry_media.JPG', "Harry Media", "Team Member", "#", "#"),
      teamFunc('/team/deb.png', "Deborah Ifegwu", "Project Management", "#", "#"),
   ]

   const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
         {
            breakpoint: 1024,
            settings: {
               slidesToShow: 4,
               slidesToScroll: 1,
               infinite: true,
            },
         },
         {
            breakpoint: 768,
            settings: {
               slidesToShow: 3,
               slidesToScroll: 1,
               infinite: true,
            },
         },
         {
            breakpoint: 640,
            settings: {
               slidesToShow: 1.95,
               slidesToScroll: 1.2,
               infinite: true,
            },
         },
      ],
   };
   return (
      <div className="ct bg-black py-10 p-4 mb-3">
         <div className="head container py-2">
            <span className="font-bold text-gray-200 text-2xl">{header}</span>
         </div>
         <Slider {...settings} className="gap-3">
            {teamArr.map((team, index) => (
               <div className="mx-2" key={index}>
                  <div className="team bg-black border rounded-lg relative h-[250px] overflow-hidden">
                     <img
                        src={team.img}
                        alt="TEAM"
                        className="absolute h-auto  w-[120%]"
                     />
                     <div className="absolute right-0 left-0 top-0 bottom-0  overlay"></div>
                     <div className="flex p-2 justify-between absolute right-0 left-0 bottom-0 img-con">
                        <div className="leading-3">
                           <span className="text-white font-bold text-sm">
                              {team.name}
                           </span>{" "}
                           <br />
                           <span className="text-white text-xs">{team.role}</span>
                        </div>
                        <span className="flex text-white gap-2 bottom-0">
                           <i>
                              <a href={team.twitter}>
                                 <FaTwitter />
                              </a>
                           </i>
                           <i>
                              <a href={team.discord}>
                                 <FaDiscord />
                              </a>
                           </i>
                        </span>
                     </div>
                  </div>
               </div>
            ))}
         </Slider>
      </div>
   );
}
