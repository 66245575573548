import React, { useEffect } from "react";
import crown from "../../../svgs/crown.svg";
import cart from "../../../svgs/cart.svg";
import edit from "../../../svgs/edit.svg";
import add from "../../../svgs/add.svg";
import house from "../../../svgs/house.svg";
import { Link } from "react-router-dom";
import { getToken } from "../../../functions/tokenLogger";
import AdminHeader from "../Components/AdminHeader";

export default function Dashboard() {
   useEffect(() => { getToken("admin") }, [])
   const boardFunc = (icon, title, link) => {
      return { icon, title, link };
   };
   const boardArr = [
      boardFunc(add, "Add product", "/admin/add-product/merchandise"),
      boardFunc(edit, "edit product", "/admin/edit-product/select"),
      boardFunc(cart, "orders", "/admin/orders"),
      boardFunc(edit, "Add Task", "/admin/task/create"),
      boardFunc(crown, "Tasks", "/admin/task"),
      boardFunc(house, "Guests", "/admin/guests"),
      boardFunc(add, "Sportbet Participant", "/admin/sportbet"),
      boardFunc(add, "raffle", "/user/raffle/laptop"),
      boardFunc(crown, "Ice", "/admin/ice-task"),

   ];
   return (
      <div className="dashboard text-white">
         <div className="container">
            <AdminHeader />
            <div className="col-12">
               <span>
                  Hello,{" "}
                  <span className="text-ng-primary font-bold">Admin</span>
               </span>
            </div>
            <div className="body  bg-ng-secondary-dark mt-4">
               <div className="col-lg-8 col-md-10 col-12 mx-auto lg:min-h-[65vh] flex items-center">
                  <div className="grid  lg:grid-cols-3 gap-5 lg:justify-between py-20 px-3  col-12 m-0 ">
                     {boardArr.map((board, index) => (
                        <div className=" border-1  border-ng-primary bg-ng-secondary py-5 rounded-2xl flex justify-center items-center my-3" key={index}>
                           <Link to={board.link}>
                              <button className="bg-ng-secondary-dark px-3 py-2 text-ng-primary font-bold justify-between text-sm rounded-lg flex gap-2 items-center capitalize">
                                 <img src={board.icon} alt="add" width={30} />{" "}
                                 {board.title}
                              </button>
                           </Link>
                        </div>
                     ))}
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}
