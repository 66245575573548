export const USER_INTIAL_STATE = {
   email: "...",
   nickname: "...",
   buzz_balance: "...",
   pfp: "...",
};

export const UserDetailReducer = (state, action) => {
   const payload = action.payload;
   switch (action.type) {
      case "set-info": {
         return {
            ...state,
            email: payload.email,
            nickname: payload.nickname,
            buzz_balance: payload.buzz_balance,
            pfp: payload.pfp,
         };
      }
      case "email":
         return { ...state, email: payload };

      case "username":
         return { ...state, username: payload };

      case "buzz_balance":
         return { ...state, buzz_balance: payload };

      case "pfp":
         return { ...state, pfp: payload };
      default:
         break;
   }
};
