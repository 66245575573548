export const INITIAL_ADDRESS_STATE = {
	firstname: "",
	lastname: "",
	phone: "",
	email: "",
	delivery_address: "",
	delivery_state: "Lagos State",
	delivery_city: "",
	delivery_method: "no methods",
};

export const AddressReducer = (state, action) => {
	switch (action.type) {
		case "pull_from_storage": {
			return action.payload;
		}
		case "firstname":
			return { ...state, firstname: action.payload };
		case "lastname":
			return { ...state, lastname: action.payload };
		case "phone":
			return { ...state, phone: action.payload };
		case "email":
			return { ...state, email: action.payload };
		case "delivery_address":
			return { ...state, delivery_address: action.payload };
		case "delivery_state":
			return { ...state, delivery_state: action.payload };
		case "delivery_city":
			return { ...state, delivery_city: action.payload };
		case "delivery_method":
			return { ...state, delivery_method: action.payload };
		default:
			break;
	}
};
