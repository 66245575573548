import React from "react";
import { Link } from "react-router-dom";

export default function FullCard({ icon, header, text, link, btnText, bg }) {
   return (
      <div
         className={
            `speaker-wrap box p-4
            ${bg === 1 ? "bg-black" : "bg-ng-secondary-opacity"}`
         }
      >
         <div className="box-head flex gap-2 items-center">
            <img src={icon} alt="Apply" width={30} />{" "}
            <span className="font-bold text-ng-primary text-xl uppercase">
               {header}
            </span>
         </div>
         <div className="box-body text-slate-50 text-sm my-4 mb-1">
            <span className="leading-8">{text}</span>
         </div>
         <div className="btn-wrap mt-4">
            <Link to={link}>
               <button className="w-full rounded-md lg:w-auto bg-ng-primary text-xs text-black py-3 px-5 font-bold ">
                  {btnText}
               </button>
            </Link>
         </div>
      </div>
   );
}
