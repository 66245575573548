import React, { useContext, useEffect, useState } from "react";
import { FaBoltLightning } from "react-icons/fa6";
import UserLayout from "../../components/UserLayout";
import Task from "../Task/Task";

import Countdown from 'react-countdown';
import { post } from "../../../../../functions/post";
import notify from "../../../../../functions/notify";
import { RingLoader } from "react-spinners";
import getUserInfo from "../../../../../functions/getUserInfo";
import { UserDetailsContext } from "../../../../../context/UserDetailsContext";
import SportBetTask from "../Task/SportBetTask";
import TurnUp from "../Task/TurnUp";
import IceTask from "../Task/iceTask";
import IceTask_ig from "../Task/iceTask_ig";
import IceTask_spotify from "../Task/iceTask_spotify";
import IceTask_thread from "../Task/iceTask_thread";
import IceTask_tweet from "../Task/iceTask_tweet";



export default function Reward() {

   const [futureDate, setFutureDate] = useState(null)
   const [loading, setLoading] = useState(false)

   const { dispatch } = useContext(UserDetailsContext)

   useEffect(() => {
      getFutureDate()
   }, [])

   const getFutureDate = async () => {
      try {
         setFutureDate(null)
         const response = await post('/user/reward/claim?date=future-date')
         setFutureDate(response.data.message)

      } catch (error) {
         console.log(error)
      }
   }

   const claim = async () => {

      try {
         setLoading(true)
         await post('/user/reward/claim')
         await updateUserData()
         notify("success", "Claiming Successfull, Comeback in 10 min")
         getFutureDate();
      } catch (error) {
         console.log(error)
      } finally {
         setLoading(false)
      }
   }

   const updateUserData = async () => {
      try {
         const info = await getUserInfo()
         dispatch({ type: "buzz_balance", payload: info.buzz_balance })
      } catch (error) {
         console.log(error)
      }
   }

   const renderer = ({ hours, minutes, seconds, completed }) => {
      if (completed) {
         // Render a completed state
         return (
            <btn className="btn rounded-pill p-2 bg-ng-primary hover:bg-ng-primary text-ng-secondary-dark" onClick={() => claim()} >
               {loading ? (
                  <>
                     <RingLoader size={'35px'} />
                  </>
               ) : 'Claim Point'}
            </btn>
         )
      } else {
         // Render a countdown
         return (
            <div className="wrap flex gap-2 items-center text-ng-primary ">
               <span>Next claiming in </span> <span className="text-sm bg-ng-primary-opacity text-ng-primary rounded-3xl p-2">
                  {hours.toString().length < 2 && 0}{hours}:{minutes.toString().length < 2 && 0}{minutes}:{seconds.toString().length < 2 && 0}{seconds}</span>
            </div>
         );
      }
   };

   return (
      <UserLayout isDashboard={true}>
         <div className="wrap ">
            <div className="text-white flex items-center justify-between  gap-4 col-lg-8 mx-auto bg-ng-secondary-dark  rounded-xl px-4 py-3 my-5">
               <div className="icon flex bg-ng-secondary-dark rounded-full p-4 border-r-2 border-yellow-300">
                  <span className=" text-center">
                     <FaBoltLightning className="mx-auto text-lg text-yellow-300" />{" "}
                     <span>1,000</span>
                  </span>
               </div>

               <div className="text-wrap text-sm md:text-md text-right">
                  <span>
                     Claim daily <span className="font-bold">points</span> every 24hrs
                  </span>
                  <div className="btn-wrap flex justify-end mt-2">
                     {futureDate && (
                        <Countdown date={futureDate + 86400000} renderer={renderer} />
                     )}
                  </div>
               </div>
            </div>

            {/* TASK SECTION */}
            {/* <SportBetTask /> */}
            {/* <div className="grid lg:grid-cols-2 col-lg-8 gap-2 mx-auto">
               <IceTask />
               <IceTask_ig />
               <IceTask_spotify />
               <IceTask_thread />
               <IceTask_tweet />
            </div> */}
            {/* <TurnUp /> */}
            <Task />
         </div>
      </UserLayout>
   );
}

