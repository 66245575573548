import React, { createContext, useEffect, useReducer } from "react";
import { AddressReducer, INITIAL_ADDRESS_STATE } from "./addressReducer";

export const AddressContext = createContext();

export default function AddressContextWrap({ children }) {
   const [state, dispatch] = useReducer(AddressReducer, INITIAL_ADDRESS_STATE);
   useEffect(() => {
      const addressFromStorage = JSON.parse(localStorage.getItem("user-address"))
      !addressFromStorage 
      ? dispatch({ type: "pull_from_storage", payload: INITIAL_ADDRESS_STATE }) 
      : dispatch({ type: "pull_from_storage", payload: addressFromStorage })
   }, [])
   
   useEffect(() => {
      localStorage.setItem("user-address", JSON.stringify(state))
   }, [state])
   return (
      <AddressContext.Provider value={{ state, dispatch }}>
         {children}
      </AddressContext.Provider>
   );
}
