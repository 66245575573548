import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import notify from "../../../../functions/notify";
import { post } from "../../../../functions/post";
import Breadcrumb from "../../../components/breadcrumb";
import ClientWrapper from "../../../components/ClientWrapper";
import Loader from "../../../components/loader";
import Navigator from "../../../components/navigator";
import Success from "../../../components/success";
import BgGrad from "../../../components/BgGrad";

export default function Award() {
   const [DAO_name, setDAO_name] = useState("");
   const [category, setCategory] = useState("category");
   const [community_members, setCommunityMembers] = useState(0);
   const [twitter_link, setTwitterLink] = useState("");
   const [discord_link, setDiscordLink] = useState("");

   const [loading, setLoading] = useState(false);
   const [success, setSuccess] = useState(false);

   const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      try {
         const data = {
            DAO_name,
            category,
            community_members,
            twitter_link,
            discord_link,
         };
         const response = await post("/apply/award", data);
         setLoading(false);
         setSuccess(true);
      } catch (error) {
         console.log(error);
         setLoading(false);
         notify("error", error.response.data.error);
      }
   };

   return (
      <ClientWrapper>
         <BgGrad>
            <div className="award bg-ng-secondary-dar lg:bg-ng-secondar pb-10">
               <div className="container  min-h-[80vh]">
                  <Navigator link={"/apply"} />
                  {/* loader if request is ongoing  */}
                  <Loader loading={loading} />
                  {/* success popup if form submitted successfully */}
                  {success && (
                     <Success
                        message={
                           "Data submitted successfully. You'll contacted be shortly"
                        }
                        link={"/apply"}
                        btnText={"Done"}
                     />
                  )}
                  {/* Notifyer */}
                  <ToastContainer theme="dark" />
                  <div className="col-lg-6 mx-auto">
                     <form onSubmit={handleSubmit} className=" bg-ng-secondary-dark bg-opacity-80 backdrop-blur-xl p-4 rounded-xl">
                        <Breadcrumb
                           base={"apply"}
                           current={"award"}
                           mbCrumb="APPLY dao Award"
                        />
                        <div className="input-wrap">
                           <input
                              type="text"
                              placeholder="Name of the DAO*"
                              className="w-full p-3 rounded-md mb-4 text-slate-200 text-xs bg-transparent border-1 border-ng-primary border-opacity-30 focus:outline-none focus:ring-2 "
                              required
                              value={DAO_name}
                              onChange={(e) => setDAO_name(e.target.value)}
                           />
                        </div>
                        <div className="input-wrap">
                           <select
                              className="w-full p-3 rounded-md mb-4 text-white slate-200 text-xs bg-transparent border-1 border-ng-primary border-opacity-30 focus:outline-none focus:ring-2 "
                              value={category}
                              onChange={(e) => setCategory(e.target.value)}
                              required
                           >
                              <option selected>Categories*</option>
                           </select>
                        </div>
                        <div className="input-wrap">
                           <input
                              type="number"
                              placeholder="How many Community Members do you have?*"
                              className="w-full p-3 rounded-md mb-4 text-slate-200 text-xs bg-transparent border-1 border-ng-primary border-opacity-30 focus:outline-none focus:ring-2 "
                              value={community_members}
                              onChange={(e) => setCommunityMembers(e.target.value)}
                              required
                           />
                        </div>
                        <div className="input-wrap">
                           <input
                              type="text"
                              placeholder="Twitter Link*"
                              className="w-full p-3 rounded-md mb-4 text-slate-200 text-xs bg-transparent border-1 border-ng-primary border-opacity-30 focus:outline-none focus:ring-2 "
                              required
                              value={twitter_link}
                              onChange={(e) => setTwitterLink(e.target.value)}
                           />
                        </div>
                        <div className="input-wrap">
                           <input
                              type="text"
                              placeholder="Discord Link* "
                              className="w-full p-3 rounded-md mb-4 text-slate-200 text-xs bg-transparent border-1 border-ng-primary border-opacity-30 focus:outline-none focus:ring-2 "
                              required
                              value={discord_link}
                              onChange={(e) => setDiscordLink(e.target.value)}
                           />
                        </div>
                        <div className="Website*">
                           <button className="w-full bg-ng-primary text-black font-bold textt-sm rounded-md py-3 hover:bg-ng-primary-opacity hover:text-white">
                              Apply
                           </button>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
         </BgGrad>
      </ClientWrapper>
   );
}
