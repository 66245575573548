import { NumericFormat } from "react-number-format";
import trash from "../../../../svgs/trash.svg";
import React, { useState } from "react";

export default function CartBox({ cart, dispatch }) {
   const [selectedSize, setSelectedSize] = useState('m');
   const sizes = ["m", "l", "xl", "xxl"];
   const sizeHandler = (id, size) => {
      setSelectedSize(size);
      dispatch({
         type: "ADD_SIZE",
         payload: { id, size },
      });
   };

   return (
      <div
         className="cart-box lg:border-1 p-3 border-ng-primary items-center gap-4 text-white"
         key={cart.cart_id}
      >
         {/* Delete button */}
         <div className=" block lg:hidden py-3">
            <div
               className="flex gap-2 justify-end"
               onClick={() =>
                  dispatch({ type: "REM_PRODUCT", payload: cart.cart_id })
               }
            >
               <img src={trash} alt="delete" /> <span>Remove</span>
            </div>
         </div>
         <div className="flex border-1 p-3 border-ng-primary gap-3">
            <div className="prd-img">
               <img
                  src={cart.product_img}
                  alt="product name"
                  className="w-[200px]"
               />
            </div>
            <div className="prd-details w-full">
               {/* merch-name and delete button */}
               <div className="flex justify-between">
                  {/* product details */}
                  <div className="col">
                     <div className="merch-name mb-2">
                        <span className="lg:text-sm text-xs">Merch Name</span>{" "}
                        <br />
                        <span className="lg:text-2xl text-xl text-ng-primary font-bold">
                           {cart.product_name}
                        </span>
                     </div>
                     <div className="merch-amount">
                        <span className="lg:text-sm text-xs">Price</span> <br />
                        <span className="lg:text-2xl text-xl text-ng-primary font-bold">
                           &#8358;
                           <NumericFormat
                              value={cart.product_price}
                              displayType={'text'}
                              thousandSeparator={','}
                           />
                        </span>
                     </div>
                  </div>
                  {/* Delete button */}
                  <div className="hidden lg:block">
                     <div
                        className="flex gap-2 justify-end"
                        onClick={() =>
                           dispatch({
                              type: "REM_PRODUCT",
                              payload: cart.cart_id,
                           })
                        }
                     >
                        <img src={trash} alt="delete" /> <span>Remove</span>
                     </div>
                  </div>
               </div>

               {/* size and quantity */}
               <div className="hidden lg:block">
                  <div className="flex ">
                     {/* size */}
                     <div className="col">
                        <span className="text-sm ">Size Guide</span>
                        <div className="flex gap-2 pt-2">
                           {sizes.map((size, sizeIndex) => (
                              <span
                                 className={`text-xs rounded-4xl flex-initial border-1 border-ng-primary-opacity px-3 py-2 uppercase ${selectedSize === size ? "selected-size" : ""
                                    }`}
                                 key={sizeIndex}
                                 onClick={() => sizeHandler(cart.cart_id, size)}
                              >
                                 {size}
                              </span>
                           ))}
                        </div>
                     </div>

                     {/* quantity */}
                     <div className="col">
                        <span className="text-sm">Quantity</span>
                        <div className="flex gap-4 pt-2">
                           <span
                              className="rounded-4xl text-xl flex-initial border-1 border-ng-primary bg-ng-secondary-dark px-3 py-1"
                              onClick={() =>
                                 dispatch({
                                    type: "REM_QTY",
                                    payload: cart.cart_id,
                                 })
                              }
                           >
                              -
                           </span>
                           <input
                              type="number"
                              className="border-1 border-ng-primary w-[70px] bg-transparent text-center text-white flex-initial"
                              value={cart.product_quantity}
                           />
                           <span
                              className="rounded-4xl text-xl flex-initial bg-ng-primary px-3 py-1 text-black"
                              onClick={() =>
                                 dispatch({
                                    type: "ADD_QTY",
                                    payload: cart.cart_id,
                                 })
                              }
                           >
                              +
                           </span>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div className="lg:hidden qty-con mt-3">
            <div className="flex gap-4">
               {/* size */}
               <div className="col">
                  <span className="text-sm ">Size Guide</span>
                  <div className="row pt-2">
                     {sizes.map((size, sizeIndex) => (
                        <div
                           className=" mb-3 bg-whit col-6 justify-start flex"
                           key={sizeIndex}
                        >
                           <span
                              className={`text-xs rounded-4xl flex-initial border-1 border-ng-primary text-center p-2 w-[50px] uppercase ${selectedSize === size ? "selected-size" : ""
                                 }`}
                              onClick={() => sizeHandler(cart.cart_id, size)}
                           >
                              {size}
                           </span>
                        </div>
                     ))}
                  </div>
               </div>

               {/* quantity */}
               <div className="col">
                  <span className="text-sm">Quantity</span>
                  <div className="flex gap-4 pt-2">
                     <span
                        className="rounded-4xl text-xl flex-initial border-1 border-ng-primary bg-ng-secondary-dark text-center w-[30px] h-[30px]"
                        onClick={() =>
                           dispatch({
                              type: "REM_QTY",
                              payload: cart.cart_id,
                           })
                        }
                     >
                        -
                     </span>
                     <input
                        type="number"
                        className="border-1 border-ng-primary w-[50px] bg-transparent text-center text-white flex-initial"
                        value={cart.product_quantity}
                        onChange={() => { }}
                     />
                     <span
                        className="rounded-4xl text-xl flex-initial bg-ng-primary text-center w-[30px] h-[30px] text-black"
                        onClick={() =>
                           dispatch({
                              type: "ADD_QTY",
                              payload: cart.cart_id,
                           })
                        }
                     >
                        +
                     </span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}
