import { removeCookie } from "./cookies";
import { get } from "./get";

export default async function getUserInfo() {
   try {
      const response = await get("/user/profile/get-user-info");
      return response.data.message;
   } catch (error) {
      console.log(error);
   }
}
