import React, { useEffect, useState } from 'react';
import Container from '../Components/Container';
import { NavLink } from 'react-router-dom';
import selectRandom from '../../../functions/select-random';
import WinnersDisplay from './WinnersDisplay';
import notify from '../../../functions/notify';
import axios from 'axios';
import { getCookie } from '../../../functions/cookies';

export default function MifiRaffle() {
    const navLinks = [
        { path: "/user/raffle/laptop", label: "LAPTOP" },
        { path: "/user/raffle/iphone", label: "Infinix" },
        { path: "/user/raffle/andriod", label: "Redmi" },
        { path: "/user/raffle/mifi", label: "MIFI" }
    ];

    const [data, setData] = useState([])

    const [open, setOpen] = useState(false);
    const [randUsers, setRandUsers] = useState([]);
    const [randUser, setRandUser] = useState(null);
    const [shuffle, setShuffle] = useState(false);
    const [winner, setWinner] = useState([]);

    // Countdown timer
    const [countdown, setCountdown] = useState(0);
    const [isCountingDown, setIsCountingDown] = useState(false);

    const startCountdown = () => {
        setCountdown(10); // Set the initial countdown value (10 seconds)
        setIsCountingDown(true);
    };

    useEffect(() => {
        getRaffler()
    }, [])
    useEffect(() => {
        let timer;
        if (isCountingDown && countdown > 0) {
            timer = setTimeout(() => {
                setCountdown(countdown - 1);
            }, 1000);
        } else if (countdown === 0 && isCountingDown) {
            setIsCountingDown(false);
            setShuffle(false);
            const result = selectRandom(data, 7);
            setWinner(result);
            setOpen(true);
        }
        return () => clearTimeout(timer);
    }, [countdown, isCountingDown]);

    useEffect(() => {
        if (shuffle) {
            const interval = setInterval(() => {
                const randomIndices = Array.from({ length: 100 }, () => Math.floor(Math.random() * data.length));
                setRandUsers(randomIndices);
            }, 400); // Adjust the interval as needed

            startCountdown();

            return () => clearInterval(interval); // Cleanup on unmount
        }
    }, [shuffle]);

    const handleShuffle = () => {
        setShuffle(true);
        setOpen(false);
        setWinner([]);
    };

    const getRaffler = async () => {
        const token = getCookie('nftng-admin-token')
        try {
            const response = await axios.get(`${window.api}/get-rafflers`, {
                headers: {
                    "content-type": "application/json",
                    "nftng-admin-token": token,
                },
            });
            setData(response.data.message)
        } catch (error) {
            console.log(error);
            notify("error", "An error occured");
            // error.response.data === "Access Denied! No auth token" && (window.location.href = "/admin/login")
        }
    };

    return (
        <div className="wrap min-h-screen raffle">
            {winner.length > 0 && (<WinnersDisplay data={winner} open={open} setOpen={setOpen} prize={"Mifi"} />)}
            <Container>
                <div className="header py-16 rounded-xl text-center">
                    <h1 className='text-3xl font-extrabold text-white'>
                        <span className='text-ng-primary font-extrabold'>NFTNG </span>
                        RAFFLE COMPETITION <span className='text-red-500 font-extrabold'>SELECTION</span>
                    </h1>
                </div>

                <div className="nav flex justify-between bg-ng-primary rounded-xl my-10 font-bold capitalize overflow-clip">
                    {navLinks.map((link, index) => (
                        <NavLink key={index} to={link.path} className="p-3 trans active:bg-black hover:bg-black hover:text-white">
                            {link.label}
                        </NavLink>
                    ))}
                </div>

                <div className="wrap bg-ng-primary-opacity p-10 rounded-xl">
                    <div className="flex gap-3 justify-between items-center mb-5 border-y border-gray-400 py-2">
                        <h1 className='text-2xl font-bold text-white'>Qualifiers</h1>
                        <div className="flex">
                            <button
                                className='bg-green-500 w-[75px] h-[75px] flex items-center justify-center text-black rounded-full text-xl font-bold trans hover:bg-green-400'
                                onClick={handleShuffle}
                            >
                                {shuffle ? countdown : 'Start'}
                            </button>
                        </div>
                        <div className="wrap font-bold text-white">
                            <span>{data.length} Competitors</span> <br />
                            <span>7 Winners</span>
                        </div>
                    </div>
                    <div className="flex flex-wrap gap-5 justify-between">
                        {data.map((i, index) => (
                            <div key={i._id} className={`pill trans ${randUsers.includes(index) ? "bg-ng-secondary-dark text-white" : "bg-ng-primary text-black"} p-2 rounded-full flex items-center gap-4 px-4`}>
                                {/* <img src={i.pfp ? i.pfp : "/n2.png"} alt="User" className='rounded-full h-[20px] w-[20px]' /> */}
                                <span className='font-bold text-sm'>{i.nickname}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </Container>
        </div>
    );
}
