const { default: notify } = require("./notify");

function copyToClipboard(text) {
   const input = document.createElement("input");
   input.value = text;
   document.body.appendChild(input);
   input.select();
   document.execCommand("copy");
   document.body.removeChild(input);
   notify('success', "Copied")
}

module.exports = copyToClipboard;
