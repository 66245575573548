import React from "react";
import Marquee from "react-fast-marquee";

export default function Bookings() {
   const imgArr = ["guardian.JPG", "thecable.JPG", "pulse.JPG", "sportbet.PNG"];
   return (
      <div className="bookings pt-6 mt-10">
         <div className="container">
            <div className="bk-head text-light text-center pb-10">
               <span className="text-4xl font-bold">Meet Our Partners</span>
            </div>
            <div className="row">
               <Marquee pauseOnHover={true}>
                  <div className="flex gap-10 items-center">
                     {imgArr.map((i, index) => (
                        <div className="wrap">
                           <img src={`/partners/${i}`} alt={i} className=" w-[150px] lg:w-[300px] " />
                        </div>
                     ))}
                  </div>
               </Marquee>
            </div>
            <div className="bk-caption col-lg-8 mx-auto text-light p-5 text-center">
               <div className="btn-wrap text-center my-5">
                  <button className="bg-ng-primary text-black py-2 px-4 rounded-lg font-bold">
                     Become a Partner
                  </button>
               </div>
            </div>
         </div>
      </div>
   );
}
