import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import notify from "../../../../functions/notify";
import { post } from "../../../../functions/post";
import Breadcrumb from "../../../components/breadcrumb";
import ClientWrapper from "../../../components/ClientWrapper";
import Loader from "../../../components/loader";
import Navigator from "../../../components/navigator";
import Success from "../../../components/success";
import BgGrad from "../../../components/BgGrad";

export default function Sponsor() {
   const [toggle, setToggle] = useState(false);

   const [title, setTitle] = useState("");
   const [firstname, setFirstname] = useState("");
   const [lastname, setLastname] = useState("");
   const [company_name, setCompany_name] = useState("");
   const [phone, setPhone] = useState("");
   const [email, setEmail] = useState(localStorage.getItem("email"));
   const [pkg, setPackage] = useState(0);
   const [pkgIndex, setPkgIndex] = useState(null);

   const [loading, setLoading] = useState(false);
   const [success, setSuccess] = useState(false);

   const pkgHandler = (value, index) => {
      setPackage(value);
      setPkgIndex(index);
   };

   const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      if (pkg === 0) {
         return alert("choose package");
      }
      try {
         const data = {
            title,
            firstname,
            lastname,
            company_name,
            phone,
            package: pkg,
            // email,
         };
         const response = await post("/apply/partner", data);
         setLoading(false);
         setSuccess(true);
      } catch (error) {
         console.log(error);
         setLoading(false);
         notify("error", error.response.data.error);
      }
   };
   const pkgFunc = (name, price) => {
      return { name, price };
   };
   const pkgArr = [
      pkgFunc("Gold Package", 1000),
      pkgFunc("Diamond Package", 2000),
      pkgFunc("Platinum Package", 4000),
   ];
   return (
      <ClientWrapper>
         <BgGrad>
            <div className="sponsor  bg-ng-secondary-dark bg-opacity-80 rounded-2xl pb-10">
               <div className="container min-h-[80vh]">
                  <Navigator link={"/apply"} />
                  {/* loader if request is ongoing  */}
                  <Loader loading={loading} />
                  {/* success popup if form submitted successfully */}
                  {success && (
                     <Success
                        message={
                           "Data submitted successfully. You'll contacted be shortly"
                        }
                        link={"/apply"}
                        btnText={"Done"}
                     />
                  )}
                  {/* Notifyer */}
                  <ToastContainer theme="dark" />

                  <div className="col-lg-12 mx-auto">
                     <form action="">
                        <Breadcrumb
                           base={"apply"}
                           current={"Sponsor"}
                           mbCrumb="Sponsor form"
                        />

                        <div className="row ">
                           <div
                              className={`col-lg-6 lg:block ${toggle && "hidden"}`}
                           >
                              <div className="form-text  text-light text-md leading-8  pb-3">
                                 <span>
                                    We’ve had something brewing… It’s very exciting
                                    and worth anticipating. Now that the cat is out
                                    of the bag, please join us on our incredible
                                    journey you snooze, you lose.
                                 </span>
                              </div>

                              <div className="input-wrap flex flex-wrap gap-x-3 justify-between">
                                 <select
                                    className=" lg:w-auto  p-3 rounded-md mb-4 text-slate-400 text-xs bg-transparent border-1 border-ng-primary border-opacity-30 focus:outline-none focus:ring-2 "
                                    value={title}
                                    onChange={(e) => {
                                       setTitle(e.target.value);
                                    }}
                                 >
                                    <option value="tetst">Test @</option>
                                    <option value={""} defaultChecked>
                                       Title*
                                    </option>
                                 </select>
                                 <input
                                    type="text"
                                    placeholder="First Name"
                                    className="w-full lg:w-auto  p-3 rounded-md mb-4 text-slate-200 text-xs bg-transparent border-1 border-ng-primary border-opacity-30 focus:outline-none focus:ring-2 "
                                    required
                                    value={firstname}
                                    onChange={(e) => {
                                       setFirstname(e.target.value);
                                    }}
                                 />
                                 <input
                                    type="text"
                                    placeholder="Last Name"
                                    className="w-full lg:w-auto p-3 rounded-md mb-4 text-slate-200 text-xs bg-transparent border-1 border-ng-primary border-opacity-30 focus:outline-none focus:ring-2 "
                                    required
                                    value={lastname}
                                    onChange={(e) => {
                                       setLastname(e.target.value);
                                    }}
                                 />
                              </div>
                              <div className="input-wrap">
                                 <input
                                    type="text"
                                    placeholder="Company Name"
                                    className="w-full  p-3 rounded-md mb-4 text-slate-200 text-xs bg-transparent border-1 border-ng-primary border-opacity-30 focus:outline-none focus:ring-2 "
                                    required
                                    value={company_name}
                                    onChange={(e) => {
                                       setCompany_name(e.target.value);
                                    }}
                                 />
                              </div>
                              <div className="input-wrap">
                                 <div className="border-1 px-4 mb-4  border-ng-primary border-opacity-30 flex items-center rounded-md">
                                    <span className="text-light font-bold">
                                       +234
                                    </span>
                                    <input
                                       type="number"
                                       placeholder="Enter phone number"
                                       className="w-full  p-3 rounded-md  text-slate-200 text-xs bg-transparent  focus:outline-none focus:ring-2"
                                       required
                                       value={phone}
                                       onChange={(e) => {
                                          setPhone(e.target.value);
                                       }}
                                    />
                                 </div>
                              </div>
                              <div className="input-wrap">
                                 <input
                                    type="email"
                                    placeholder="Email address"
                                    className="w-full  p-3 rounded-md mb-4 text-slate-200 text-xs bg-transparent border-1 border-ng-primary border-opacity-30 focus:outline-none focus:ring-2 "
                                    required
                                    value={email}
                                 />
                              </div>
                              <div className="btn-wrap my-3 b lg:hidden block">
                                 <button
                                    className="w-full px-3 bg-ng-primary rounded-md text-black text-sm font-bold py-3"
                                    onClick={() => setToggle(!toggle)}
                                 >
                                    Next
                                 </button>
                              </div>
                           </div>

                           {/* Second Column */}
                           <div
                              className={`col-lg-6 lg:block ${!toggle && "hidden"
                                 }`}
                           >
                              <div className="form-text text-light text-md leading-8  pb-3">
                                 <span>
                                    We’ve had something brewing… It’s very exciting
                                    and worth anticipating. Now that the cat is out
                                    of the bag, please join us on our incredible
                                    journey you snooze, you lose.
                                 </span>
                              </div>
                              <div className="packages mt-4">
                                 <div className="head pb-3">
                                    <span className="text-lg font-bold text-white">
                                       Sponsorship Packages
                                    </span>
                                 </div>
                                 <div className="body">
                                    {/* Packages Mapping  */}
                                    <div className="input-wrap">
                                       {pkgArr.map((i, index) => (
                                          <div
                                             className={` ${index === pkgIndex
                                                   ? " bg-ng-secondary-dark"
                                                   : ""
                                                } p-4 mb-4 border-1 border-ng-primary border-opacity-30 flex items-center justify-between rounded-md`}
                                             key={index}
                                             onClick={() =>
                                                pkgHandler(i.price, index)
                                             }
                                          >
                                             <span className="text-light font-bold">
                                                {i.name}
                                             </span>{" "}
                                             <span className=" text-ng-primary font-bold">
                                                ${i.price}
                                             </span>
                                          </div>
                                       ))}
                                    </div>
                                    <div className="btn-wrap my-3 lg:hidden b block">
                                       <button
                                          className="w-full  px-3 bg-transaparant border-1 rounded-md text-white text-sm font-bold py-3"
                                          onClick={() => setToggle(!toggle)}
                                       >
                                          previous
                                       </button>
                                    </div>
                                    <div className="btn-wrap my-3 b block">
                                       <button className="w-full px-3 bg-ng-primary rounded-md text-black text-sm font-bold py-3">
                                          Submit
                                       </button>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
         </BgGrad>
      </ClientWrapper>
   );
}
