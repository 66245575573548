import React, { useContext, useEffect, useState } from "react";
import { FaAngleRight, FaCarAlt, FaEdit, FaHistory, FaLock, FaShoppingBasket, FaSignOutAlt } from "react-icons/fa";
import { FaUserTie } from "react-icons/fa6";
import UserLayout from "../../components/UserLayout";
import WalletLogin from "../../../auth/WalletLogin";
import DialogBox from "../../components/DialogBox";
import { useNavigate } from "react-router-dom";
import { getCookie, removeCookie } from "../../../../../functions/cookies";
import getUserInfo from "../../../../../functions/getUserInfo";
import { UserDetailsContext } from "../../../../../context/UserDetailsContext";
import axios from "axios";
import notify from "../../../../../functions/notify";
import { RingLoader } from "react-spinners";
import { getToken, logout } from "../../../../../functions/tokenLogger";

export default function Profile() {
   const [open, setOpen] = useState(false)
   const [pfp, setPfp] = useState(null)
   const [userInfo, setUserInfo] = useState('')
   const [showFileBtn, setShowFileBtn] = useState(false)
   const [loading, setLoading] = useState(false)

   const { state: { nickname, email, pfp: user_pfp }, dispatch } = useContext(UserDetailsContext)


   useEffect(() => {
      gettingUserInfo()
   }, [open])

   const navi = useNavigate();
   const links = (link, name, icon) => {
      return { link, name, icon };
   };
   const linksArr = [
      // links("/user/store", "Purchase Multiplier", <FaShoppingBasket />),
      links("/user/store/orders", "Orders", <FaHistory />),
      links("https://x.com/nFT__NG/", "Customer Service", <FaUserTie />)
   ]
   const gettingUserInfo = async () => {
      const info = await getUserInfo()
      dispatch({ type: "pfp", payload: info.pfp })
   }

   const updatePfp = async (e) => {
      e.preventDefault();
      try {
         setLoading(true)
         const data = { pfp }
         if (!pfp) return notify('error', "Please select an image")
         const response = await axios.post(
            `${window.api}/user/profile/edit-pfp`,
            data,
            {
               headers: {
                  "nftng-user-token": getToken('user'),
                  "Content-Type": "multipart/form-data",
               },
            }
         );
         gettingUserInfo()
         setShowFileBtn(false)
      } catch (error) {
         console.log(error)
      } finally {
         setLoading(false)
      }
   }

   return (
      <UserLayout isDashboard={true}>
         <DialogBox open={open} setOpen={setOpen} />
         <div className="wrap col-lg-8 mx-auto text-white bg-ng-secondary-dark py-3">
            {/*  USer Name Display */}
            <div className="wrap">
               <div className="img-wrap flex justify-center relative">
                  <img
                     src={user_pfp !== "" ? user_pfp : '/t.png'}
                     alt="User"
                     className="w-[150px] h-[150px] border-1 border-ng-primary rounded-full"
                  />
                  <span className="rounded-full bg-ng-secondary-dark cursor-pointer text-ng-primary absolute p-2 bottom-0 ml-[70px]" onClick={() => setShowFileBtn(!showFileBtn)}>
                     <FaEdit />{" "}
                  </span>
               </div>

               {/* IMG upload Section */}
               {showFileBtn && (
                  <form className="p-3 gap-3 flex flex-wrap justify-center items-center" onSubmit={updatePfp}>
                     <input type="file" accept="image/*" onChange={(e) => setPfp(e.target.files)} />
                     <button type="submit" className="px-2 py-1  text-xs bg-ng-primary text-black rounded-sm">{loading ? <RingLoader size={20} /> : "Update Pfp"}</button>
                  </form>
               )}

               <div className="text-wrap text-center px-3">
                  <div className="wrap my-3">
                  </div>
                  <span className="text-lg font-bold capitalize">{nickname ? nickname : "Set nickname"}</span>{" "}
                  <br />
                  <span className="text-sm font-thin flex gap-2 justify-center">
                     {email ? email : "Set email"} <sub className="text-ng-primary cursor-pointer" onClick={() => setOpen(true)}><FaEdit /> </sub>
                  </span>
               </div>{" "}
               <br />
               <div className="profile-menu text-lg px-3">
                  <ul>
                     {linksArr.map((i, index) => (
                        <li
                           className="flex rounded-xl justify-between items-center px-3 leading-10 mb-3 cursor-pointer hover:text-ng-primary hover:bg-ng-primary-opacity"
                           key={index}
                           onClick={() => index === 1 ? window.location.href = i.link : navi(i.link)}
                        >
                           <div className="flex items-center gap-2">{i.icon}{i.name}</div> <FaAngleRight />
                        </li>
                     ))}

                     <li
                        className="flex rounded-xl justify-between items-center px-3 leading-10 mb-3 cursor-pointer hover:text-ng-primary hover:bg-ng-primary-opacity"
                        onClick={() => logout()}
                     >
                        <div className="flex items-center gap-2 text-red-500"><FaSignOutAlt /> Logout</div> <FaAngleRight />
                     </li>
                  </ul>
               </div>
            </div>
         </div>
      </UserLayout>
   );
}
