import UserDetailsContextWrap from "../../context/UserDetailsContext";
import AddressContextWrap from "../../context/addressContext";
import CartContextWrap from "../../context/cartContext";
import EditContextWrap from "../../context/edit-context";

export default function ContextWrap({ children }) {
   return (
      <CartContextWrap>
         <UserDetailsContextWrap>
            <AddressContextWrap>
               <EditContextWrap>{children}</EditContextWrap>
            </AddressContextWrap>
         </UserDetailsContextWrap>
      </CartContextWrap>
   );
}
