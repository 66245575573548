import React, { useEffect, useState } from 'react'
import Header from '../components/header'
import MbHeader from '../components/mb-header'
import axios from 'axios'
import notify from '../../functions/notify'
import Loader from '../components/loader'
import { getCookie, setCookie } from '../../functions/cookies'
import Success from '../components/success'

export default function Register() {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone_number, setPhone_number] = useState('')

    const [loading, setLoading] = useState(false)
    const [isRegistered, setIsRegistered] = useState(false)

    useEffect(() => {
        const getIsRegistered = getCookie('isRegistered')
        if (getIsRegistered === 'true') setIsRegistered(true)
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            setLoading(true)
            const data = { name, email, phone_number }

            const response = await axios.post(`${window.api}/register-guest`, data)
            notify('success', "You have successfully registered.")
            setCookie("isRegistered", "true")
        } catch (error) {
            if (error.response.data.message === "Email Exists") {
                setCookie("isRegistered", "true")
                setIsRegistered(true)
            }
            notify('error', "Email Already in Use ")
        } finally {
            setLoading(false)
        }

    }
    return (
        <>
            <Loader loading={loading} />
            {isRegistered &&
                <Success message={"Your registration has already been completed. Thank you. "} link={'/'} btnText={'Okay'} />
            }
            <div className="wrap register ">
                <Header /><MbHeader />
                <div className="bg-wrap bg-neutral-950 bg-opacity-80 py-16 min-h-[80vh]">
                    <div className="container mx-auto p-3">
                        <div className="form col-lg-6 mx-auto text-sm bg-opacity-50 backdrop-blur-xl p-4 rounded-lg mt-4 border bg-ng-primary">
                            <div className="heading text-white text-3xl font-bold text-center my-3">
                                <h1>Register for <span className='text-ng-primary'>DEFI</span> SUMMER</h1>
                            </div>
                            <form className='text-black' onSubmit={handleSubmit}>
                                <div className="lg:flex gap-2 items-center">
                                    <div className="form-group w-full">
                                        <label className='text-sm mb-1'>Name <span className='text-red-400'>*</span></label>
                                        <input type="text" className='w-full p-3 rounded-xl bg-neutral-950 bg-opacity-30 text-gray-200' value={name} onChange={(e) => setName(e.target.value)} required />
                                    </div>
                                    <div className="form-group my-3 w-full">
                                        <label className='text-sm mb-1'>Email <span className='text-red-400'>*</span></label>
                                        <input type="text" className='w-full p-3 rounded-xl bg-neutral-950 bg-opacity-30 text-gray-200' value={email} onChange={(e) => setEmail(e.target.value)} required />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className='text-sm mb-1'>Phone No </label>
                                    <input type="text" className='w-full p-3 rounded-xl bg-neutral-950 bg-opacity-30 text-gray-200' value={phone_number} onChange={(e) => setPhone_number(e.target.value)} />
                                </div>

                                <div className="btn-wrap text-center mt-4">
                                    <button type='submit' className='p-3 rounded-full bg-ng-primary text-neutral-950 text-sm w-1/2 trans hover:bg-black hover:text-white'>Register</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
