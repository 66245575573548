// export const abi = [
//    {
//       constructor: {
//          inputs: [
//             {
//                internalType: "address",
//                name: "_owner",
//                type: "address",
//             },
//          ],
//          stateMutability: "nonpayable",
//          type: "constructor",
//       },
//       BalanceWithdrawn: {
//          anonymous: false,
//          inputs: [
//             {
//                indexed: false,
//                internalType: "address",
//                name: "recipient",
//                type: "address",
//             },
//             {
//                indexed: false,
//                internalType: "uint256",
//                name: "amount",
//                type: "uint256",
//             },
//          ],
//          name: "BalanceWithdrawn",
//          type: "event",
//       },
//       buyMerch: {
//          inputs: [
//             {
//                internalType: "uint256",
//                name: "_merchPrice",
//                type: "uint256",
//             },
//          ],
//          name: "buyMerch",
//          outputs: [],
//          stateMutability: "payable",
//          type: "function",
//       },
//       confirmPurchase: {
//          inputs: [
//             {
//                internalType: "uint256",
//                name: "_merchPrice",
//                type: "uint256",
//             },
//          ],
//          name: "confirmPurchase",
//          outputs: [
//             {
//                internalType: "bool",
//                name: "",
//                type: "bool",
//             },
//          ],
//          stateMutability: "nonpayable",
//          type: "function",
//       },
//       withdraw: {
//          inputs: [],
//          name: "withdraw",
//          outputs: [],
//          stateMutability: "nonpayable",
//          type: "function",
//       },
//       balance: {
//          inputs: [],
//          name: "balance",
//          outputs: [
//             {
//                internalType: "uint256",
//                name: "",
//                type: "uint256",
//             },
//          ],
//          stateMutability: "view",
//          type: "function",
//       },
//       purchase_balance: {
//          inputs: [
//             {
//                internalType: "address",
//                name: "",
//                type: "address",
//             },
//          ],
//          name: "purchase_balance",
//          outputs: [
//             {
//                internalType: "uint256",
//                name: "",
//                type: "uint256",
//             },
//          ],
//          stateMutability: "view",
//          type: "function",
//       },
//    },
// ];


export const abi = [
    {
      type: 'function',
      name: 'approve',
      stateMutability: 'nonpayable',
      inputs: [
        { name: 'spender', type: 'address' },
        { name: 'amount', type: 'uint256' },
      ],
      outputs: [{ type: 'bool' }],
    },
    {
      type: 'function',
      name: 'transferFrom',
      stateMutability: 'nonpayable',
      inputs: [
        { name: 'sender', type: 'address' },
        { name: 'recipient', type: 'address' },
        { name: 'amount', type: 'uint256' },
      ],
      outputs: [{ type: 'bool' }],
    },
  ];
  


