import React from "react";
import { FaDiscord, FaTwitter } from "react-icons/fa";
import Slider from "react-slick";

export default function EventSpeaker({ header }) {
   const dum = [1, 1, 1];

   const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
         {
            breakpoint: 1024,
            settings: {
               slidesToShow: 4,
               slidesToScroll: 1,
               infinite: true,
            },
         },
         {
            breakpoint: 768,
            settings: {
               slidesToShow: 3,
               slidesToScroll: 1,
               infinite: true,
            },
         },
         {
            breakpoint: 640,
            settings: {
               slidesToShow: 1.95,
               slidesToScroll: 1.2,
               infinite: true,
            },
         },
      ],
   };
   return (
      <div className="ct bg-black py-10 p-4">
         <div className="head container py-2">
            <span className="font-bold text-white text-2xl">{header}</span>
            <div className="text-wrap text-center">
               <span className="text-slate-900 badge bg-ng-primary">
                  Revealing Soon...
               </span>
            </div>
         </div>
         <Slider {...settings} className="gap-3">
            {dum.map((team, index) => (
               <div className="mx-2" key={index}>
                  <div className="team bg-ng-secondary border rounded-lg relative h-[250px] overflow-hidden">
                     <img
                        src="/unkown.png"
                        alt="TEAM"
                        className="absolute h-auto  w-[120%]"
                     />
                     <div className="absolute right-0 left-0 top-0 bottom-0  overlay"></div>
                     {/* <div className="flex p-2 justify-between absolute right-0 left-0 bottom-0 img-con">
                        <div className="leading-3">
                           <span className="text-white font-bold text-sm">
                              Sheriff Ibrahim{" "}
                           </span>{" "}
                           <br />
                           <span className="text-white text-xs">Founder</span>
                        </div>
                        <span className="flex text-white gap-2 bottom-0">
                           <i>
                              <FaTwitter />
                           </i>
                           <i>
                              <FaDiscord />
                           </i>
                        </span>
                     </div> */}
                  </div>
               </div>
            ))}
         </Slider>
      </div>
   );
}
