import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AddressContext } from "../../../../context/addressContext";
import Breadcrumb from "../../../components/breadcrumb";
import Navigator from "../../../components/navigator";
import ProductBreadcrumb from "../../../components/product-breadcrumb";
import states from "../states.json";
import Header from "../../../components/header";
import MbHeader from "../../../components/mb-header";

export default function Address() {
   const navi = useNavigate();
   const handleSubmit = (e) => {
      e.preventDefault();
      navi("/product/cart/checkout");
   };
   const {
      state: {
         firstname,
         lastname,
         phone,
         email,
         delivery_address,
         delivery_state,
         delivery_city,
         delivery_method,
      },
      dispatch,
   } = useContext(AddressContext);

   const LGA_Array = states.filter((i) => {
      return i.state.name === delivery_state;
   });
   const LGA = LGA_Array[0].state.locals;

   return (
      <>
         <Header />
         <MbHeader />

         <div className="speaker bg-ng-secondary-dark lg:bg-neutral-950 pb-10">
            <div className="container min-h-[80vh]">
               <Navigator link={"/product/cart/checkout"} />
               <div className="col-lg-12 mx-auto">
                  <form onSubmit={handleSubmit}>
                     <div className="row ">
                        <div className="col-lg-6 mx-auto">
                           <ProductBreadcrumb
                              base={"product"}
                              current={"delivery-address"}
                              mbCrumb="delivery Address"
                           />
                           <div className="wrap bg-ng-secondary-dark  lg:p-5 ">
                              <div className="input-wrap  flex flex-wrap gap-x-3 ">
                                 <input
                                    type="text"
                                    placeholder="First Name"
                                    className="w-full lg:w-auto  p-3 rounded-md mb-4 text-slate-200 text-xs bg-transparent border-1 border-ng-primary border-opacity-30 focus:outline-none focus:ring-2 "
                                    value={firstname}
                                    onChange={(e) =>
                                       dispatch({
                                          type: "firstname",
                                          payload: e.target.value,
                                       })
                                    }
                                    required
                                 />
                                 <input
                                    type="text"
                                    placeholder="Last Name"
                                    className="w-full lg:w-auto p-3 rounded-md mb-4 text-slate-200 text-xs bg-transparent border-1 border-ng-primary border-opacity-30 focus:outline-none focus:ring-2 "
                                    required
                                    value={lastname}
                                    onChange={(e) =>
                                       dispatch({
                                          type: "lastname",
                                          payload: e.target.value,
                                       })
                                    }
                                 />
                              </div>

                              <div className="input-wrap">
                                 <div className="border-1 px-4 mb-4  border-ng-primary border-opacity-30 flex items-center rounded-md">
                                    <span className="text-light font-bold">
                                       +234
                                    </span>
                                    <input
                                       type="number"
                                       placeholder="Enter phone number"
                                       className="w-full  p-3 rounded-md  text-slate-200 text-xs bg-transparent  focus:outline-none focus:ring-2"
                                       required
                                       value={phone}
                                       onChange={(e) =>
                                          dispatch({
                                             type: "phone",
                                             payload: e.target.value,
                                          })
                                       }
                                    />
                                 </div>
                              </div>
                              <div className="input-wrap">
                                 <input
                                    type="email"
                                    placeholder="Email address"
                                    className="w-full  p-3 rounded-md mb-4 text-slate-200 text-xs bg-transparent border-1 border-ng-primary border-opacity-30 focus:outline-none focus:ring-2 "
                                    required
                                    value={email}
                                    onChange={(e) =>
                                       dispatch({
                                          type: "email",
                                          payload: e.target.value,
                                       })
                                    }
                                 />
                              </div>
                              <div className="input-wrap">
                                 <textarea
                                    type="text"
                                    rows={5}
                                    placeholder="Delivery Address"
                                    className="w-full  p-3 rounded-md mb-4 text-slate-200 text-xs bg-transparent border-1 border-ng-primary border-opacity-30 focus:outline-none focus:ring-2 "
                                    required
                                    value={delivery_address}
                                    onChange={(e) =>
                                       dispatch({
                                          type: "delivery_address",
                                          payload: e.target.value,
                                       })
                                    }
                                 />
                              </div>
                              <div className="input-wrap  flex flex-wrap gap-x-3 ">
                                 <div className="col">
                                    <select
                                       onChange={(e) => dispatch({ type: "delivery_state", payload: e.target.value })}
                                       className=" w-full  p-3 rounded-md mb-4 text-slate-400 text-xs bg-transparent border-1 border-ng-primary border-opacity-30 focus:outline-none focus:ring-2 "
                                       required
                                    >
                                       <option selected value={''}>State / Region*</option>
                                       {states.map((i, index) => (
                                          <option value={i.state.name} key={index}>
                                             {i.state.name}
                                          </option>
                                       ))}
                                    </select>
                                 </div>
                                 <div className="col">
                                    <select value={delivery_city} onChange={e => dispatch({ type: "delivery_city", payload: e.target.value })} className=" w-full  p-3 rounded-md mb-4 text-slate-400 text-xs bg-transparent border-1 border-ng-primary border-opacity-30 focus:outline-none focus:ring-2 " required>
                                       <option selected value={''}>City*</option>
                                       {LGA.map((i, index) => (
                                          <option key={index} value={i.name}>{i.name}</option>
                                       ))}
                                    </select>
                                 </div>
                              </div>
                              <div className="input-wrap  flex flex-wrap gap-x-3 ">
                                 <div className="col">
                                    <select className=" w-full  p-3 rounded-md mb-4 text-slate-400 text-xs bg-transparent border-1 border-ng-primary border-opacity-30 focus:outline-none focus:ring-2"
                                       onChange={e => dispatch({ type: "delivery_method", payload: e.target.value })}
                                       value={delivery_method}
                                       required>
                                       <option selected value={''}>Delivery Method*</option>
                                       <option value={"dispatch"}>Dispatch</option>
                                    </select>
                                 </div>
                              </div>
                              <div className="flex justify-between gap-4 items-center">
                                 <div className="btn-wrap my-3 col">
                                    <button className="w-full  bg-ng-primary rounded-md text-black text-sm font-bold py-3 px-3">
                                       Continue
                                    </button>
                                 </div>
                                 <div className="btn-wrap col">
                                    <button className="w-full bg-transparent text-white rounded-md text-sm font-bold py-3 px-3 border-1 border-ng-primary">
                                       Cancel
                                    </button>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </>
   );
}
