import React from "react";

export default function Breadcrumb({ base, current, mbCrumb }) {
   return (
      <div>
         <div className="py-4 hidden lg:block text-white">
            <span className="text-xl font-bold uppercase">{base}/ </span>
            <span className="capitalize text-lg text-ng-primary">{current}</span>
         </div>
         <div className="mb-rumb uppercase lg:hidden block text-xl py-4 text-ng-primary font-bold">
            <span>{mbCrumb}</span>
         </div>
      </div>
   );
}
