import React from 'react'

export default function BgGrad({ children }) {
    return (
        <div className="bg-con relative">
            <img src="/v1.png" alt="vector-1" className="absolute w-[200px] lg:w-[300px] top-40 left-0" />
            <img src="/v2.png" alt="vector-2" className="absolute w-[200px] lg:w-[400px] top-0 lg:left-80 right-0" />
            <img src="/v3.png" alt="vector-3" className="absolute w-[200px] lg:w-[400px] top-50 lg:start-50 bottom-0" />

            <div className="backdrop-blur-2xl bg-ng-secondary bg-opacity-60 py-5 lg:px-5">
                <div className="mx-auto p-2 z-10">
                    {children}
                </div>
            </div>
        </div>
    )
}
