import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Wrap from "../../../components/Wrap";
import { FaCheckCircle, FaHeart } from "react-icons/fa";
import DspPop from "./DspPop";
const settings = {
   dots: true,
   infinite: true,
   speed: 500,
   slidesToShow: 7,
   slidesToScroll: 2,
   initialSlide: 0,
   autoplay: true,
   speed: 2000,
   autoplaySpeed: 2000,
   cssEase: "linear",
   responsive: [
      {
         breakpoint: 1024,
         settings: {
            slidesToShow: 6.4,
            slidesToScroll: 1,
            infinite: true,
            autoplay: true,
            speed: 2000,
            autoplaySpeed: 2000,
            cssEase: "linear",
         },
      },
      {
         breakpoint: 768,
         settings: {
            slidesToShow: 3.8,
            slidesToScroll: 1,
            infinite: true,
            autoplay: true,
            speed: 2000,
            autoplaySpeed: 2000,
            cssEase: "linear",
         },
      },
      {
         breakpoint: 640,
         settings: {
            slidesToShow: 2.95,
            slidesToScroll: 1.2,
            infinite: true,
            autoplay: true,
            speed: 2000,
            autoplaySpeed: 2000,
            cssEase: "linear",
         },
      },
   ],
};

export default function Intro() {
   const [show, setShow] = useState(false)
   const navi = useNavigate()

   useEffect(() => {
      setTimeout(() => {
         setShow(true)
      }, 3000);
   }, [])
   return (
      <div className="intro  min-h-[50vh] flex justify-center items-center text-slate-100 relative" >
         {/* <DspPop show={show} setShow={setShow} /> */}
         <div className="bg-con ">
            <img src="/v1.png" alt="vector-1" className="absolute w-[200px] lg:w-[300px] top-40 left-0" />
            <img src="/v2.png" alt="vector-2" className="absolute w-[200px] lg:w-[400px] top-0 lg:left-80 right-0" />
            <img src="/v3.png" alt="vector-3" className="absolute w-[200px] lg:w-[400px] top-50 lg:start-50 bottom-0" />

            <div className="backdrop-blur-2xl bg-neutral-950 bg-opacity-60 py-5 px-2 lg:px-5">
               <div className="mx-auto p-2 z-10 flex">
                  <div className="grid lg:grid-cols-2 py-16 lg:px-20 lg:py-32 z-10">
                     <div className="wrap text-cent lg:text-left">
                        <div className="text-wrap mb-12">
                           <span className="font-led text-xl text-ng-primary">The 2nd Annual NFT Industry Event </span> <br />
                           <span className="font-extrabold text-white  "> JUNE 26, 2024</span>
                        </div>

                        <div className=" lg:hidden img-wrap mb-5 relative overflow- flex justify-center py-3">
                           <img src="/v6.png" alt="NFT vextor" className=" absolute w-[400px] top-0 " />
                           <div className="nft-card-con flex">
                              <div className="card border-0 bg-transparent">
                                 <div className="img-wrap">
                                    <img src="/defi_summer.JPG" alt="NFT" className="rounded-xl w-full" />
                                 </div>
                                 <div className="px-3">
                                    <div className="text-wrap bg-white rounded-b-2xl p-3 tex-sm">
                                       <div className="top-text text-[13px] flex justify-between items-center mb-2">
                                          <span className="font-bold">NFTNG | Defi Summer</span> <span><FaHeart /></span>
                                       </div>
                                       <div className="bottom-text flex justify-between">
                                          <div className="wrap flex gap-2 items-center text-[13px]">
                                             <img src="/defi_summer.JPG" alt="NFT" className="w-[15px] rounded-full" /> <span className="font-thin">DEFI Summer </span> <span><FaCheckCircle /></span>
                                          </div>
                                          <div className="price text-[13px] ml-3">
                                             <span>&#8734; ETH</span>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div className="text-wrap leading-8">
                           <span className="font-extrabold text-white text-4xl lg:text-6xl uppercase"> DEFI <span className="text-ng-primary">SUMMER</span> </span> <br />
                           <span className="text-2xl font-led text-ng-primary"> Let’s Touch Grass Together </span>
                        </div>

                        <div className="text-wrap mt-3 font-thin text-sm">
                           <span>We’ve had something brewing… It’s very exciting and worth anticipating.
                              Now that the cat is out of the bag, please join us on our incredible
                              journey. You snooze, you lose. </span>
                        </div>

                        <div className="btn-wrap mt-5 flex gap-3">
                           <button className="p-3 rounded-lg bg-ng-primary text-black" onClick={() => navi('/user/dashboard')} >Claim DeFi Summer Point</button>
                           <button className="p-3 rounded-lg bg-white text-black" onClick={() => navi('/register')} >Register</button>
                        </div>
                     </div>
                     <div className="hidden img-wrap lg:flex justify-end">
                        <img src="/v5.png" alt="NFT vextor" className="absolute w-[500px] lg:top-20 right-0 bottom-0" />
                        <div className="nft-card-con flex">
                           <div className="card border-0 bg-transparent">
                              <div className="img-wrap">
                                 <img src="/defi_summer.JPG" alt="NFT" className="rounded-xl w-[350px]" />
                              </div>
                              <div className="px-3">
                                 <div className="text-wrap bg-white rounded-b-2xl p-3 tex-sm">
                                    <div className="top-text flex justify-between items-center mb-2">
                                       <span className="font-bold">NFTNG | Defi Summer</span> <span><FaHeart /></span>
                                    </div>
                                    <div className="bottom-text flex justify-between">
                                       <div className="wrap flex gap-2 items-center">
                                          <img src="/defi_summer.JPG" alt="NFT" className="w-[30px] rounded-ful text-" /> <span className="font-thin">DEFI SUMMER</span> <span><FaCheckCircle /></span>
                                       </div>
                                       <div className="price">
                                          <span>&#8734; ETH</span>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

         </div>

      </div>
   );
}
