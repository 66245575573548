import { FaShare } from "react-icons/fa";
import React, { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import copyToClipboard from "../../../../../../functions/copyToClipboard";
import getUserInfo from "../../../../../../functions/getUserInfo";

export default function LinkBox() {
   const { address } = useAccount()
   const [id, setId] = useState('')

   useEffect(() => {
      updateId()
   }, [])

   const updateId = async () => {
      const user = await getUserInfo()
      setId(user._id)
   }
   return (
      <div className="link-wrap px-3">
         <div className="flex gap-3 items-center mb-3">
            <input className="bg-ng-primary-opacity rounded-full  text-gray-200 p-3 w-full text-xs" value={`https://nftng.io/auth/signup?upline=${id}`} />
            <button className=" bg-ng-primary p-2 text-xs rounded-full text-black px-5" onClick={() => copyToClipboard(`https://nftng.io/auth/signup?upline=${id}`)}>Copy</button>
         </div>
         <button className="bg-pink-700 text-white text-md pink flex gap-2 justify-center items-center px-3 py-2 rounded-3xl">
            {" "}
            Share <FaShare />{" "}
         </button>
      </div>
   );
}
