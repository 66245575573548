import React, { useEffect } from "react";
import merch from "../../../svgs/merch.svg";
import nftpass from "../../../svgs/nftpass.svg";
import ClientWrapper from "../../components/ClientWrapper";
import FullCard from "../event/components/full-card";
import { useAccount } from "wagmi";
import { logout } from "../../../functions/tokenLogger";

export default function Product() {
   const { isConnected } = useAccount()

   // useEffect(() => {
   //    if (!isConnected) logout()
   // }, [])
   return (
      <ClientWrapper>
         <div className="product">
            <div className="container min-h-screen">
               <div className="col-lg-8 col-12 mx-auto pb-4">
                  <div className="head py-4 uppercase">
                     <span className=" text-xl lg:text-2xl text-slate-50 font-bold">
                        Product
                     </span>
                  </div>
                  <div className="wrap mb-4">
                     <FullCard
                        header={"Merchandise "}
                        icon={merch}
                        text={
                           "The NFTNG merchandise is a collection of merch (T-shirts, Hoodies, Stickers, Button pins and Caps) representing the African culture, the rich way of life of the people and the creativity present in the African Web3 Space. With these merch, users can proudly symbolize their membership of both the NFTNG and African Web3 Communities. Holders of the NFTNG pass get early priority access to the merchandise at discounted prices. "
                        }
                        btnText={"Get Merch"}
                        bg={1}
                        link={"/product/merchandise"}
                     />
                  </div>
                  {/* <div className="wrap">
                     <FullCard
                        header={"NFT PASS "}
                        icon={nftpass}
                        text={
                           "The NFTNG pass is the main utility of the NFTNG Project. This pass grants holders exclusive access to the Webthree Buzz event (first of its kind exclusive web3 event holding in Lagos, Nigeria in November, 2022) and other events by NFTNG. The pass also gives holders priority access to NFTNG Merchandise at discounted prices. Furthermore, it affords holders early access to other products/utilities by the NFTNG team."
                        }
                        btnText={"Get pass"}
                        bg={0}
                     />
                  </div> */}
               </div>
            </div>
         </div>
      </ClientWrapper>
   );
}
