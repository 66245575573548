import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { AddressContext } from "../../../../context/addressContext";
import { CartContext } from "../../../../context/cartContext";
import notify from "../../../../functions/notify";
import Loader from "../../../components/loader";
import Navigator from "../../../components/navigator";
import ProductBreadcrumb from "../../../components/product-breadcrumb";
import Success from "../../../components/success";
import NoProduct from "../components/no-product";
import Header from "../../../components/header";
import MbHeader from "../../../components/mb-header";
import { useAccount } from "wagmi";
import { logout } from "../../../../functions/tokenLogger";
import { get } from "../../../../functions/get";
import CryptoPayBox from "../components/CryptoPayBox";
import { NumericFormat } from "react-number-format";

export default function Checkout() {
   const [loading, setLoading] = useState(false);
   const [open, setOpen] = useState(false)
   const [success, setSuccess] = useState(false);
   const [ethAmount, setEthAmount] = useState(null)
   const [payment_type, setPayment_type] = useState('card')

   const [tx, setTx] = useState('')

   const { state, dispatch } = useContext(CartContext);
   const { address, isConnected } = useAccount()
   const {
      state: {
         firstname,
         lastname,
         phone,
         email,
         delivery_address,
         delivery_state,
         delivery_city,
         // delivery_method,
      },
   } = useContext(AddressContext);

   useEffect(() => {
      payment_type === 'crypto' && handleSubmit()
   }, [payment_type])

   // useffect for check if user us c
   useEffect(() => {
      getEthRate()
   }, [state])

   const payAmount = () => {
      let totalAmount = 0;

      for (let i = 0; i < state.length; i++) {
         totalAmount += state[i].product_price * state[i].product_quantity;
      }
      return parseFloat(totalAmount.toFixed(4));
   };

   const getEthRate = async () => {
      try {
         const convertToUSD = payAmount() / 1400
         const response = await axios.get(`${window.api}/get-eth-rate/${convertToUSD}`)
         setEthAmount(response.data.message)
      } catch (error) {
         console.log(error)
      }
   }

   const navi = useNavigate();

   const handleSubmit = async () => {
      try {
         setLoading(true);
         const data = {
            amount: payAmount(),
            ordered_product: state,
            wallet_address: address ? address : email,
            details: {
               firstname,
               lastname,
               phone,
               email,
               delivery_address,
               delivery_state,
               delivery_city,
               // delivery_method,
            },
         };

         const response = await axios.post(`${window.api}/create/order?payment_type=${payment_type}&tx=${tx}`, data);

         setTimeout(() => {
            setLoading(false);
            // setSuccess(true);
            payment_type === 'crypto' ? navi('/product/cart/checkout/payment-success') : window.location.href = response.data.payment_link
            // dispatch({ type: "RESET" });.
         }, 1000);
      } catch (error) {
         console.log(error);
         setLoading(false);
         notify("error", "Please select product sizes");
         // setTimeout(() => navi("/product/cart"), 2000);
      }
   };

   return (
      <>
         <Header />
         <MbHeader />
         <CryptoPayBox open={open} setOpen={setOpen} ethAmount={ethAmount && ethAmount.toString()} setPayment_type={setPayment_type} submit={handleSubmit} setTx={setTx} />
         <div className="checkout">
            <div className="container">
               <Navigator link={"/product/cart/billing-address"} />
               <Loader loading={loading} />
               <ToastContainer theme="dark" />
               {success && (
                  <Success
                     message={"Order placed successfully"}
                     link={"/product/merchandise"}
                     btnText={"Done"}
                  />
               )}

               <div className="header">
                  <ProductBreadcrumb
                     base={"product"}
                     current={"merchandise / checkout "}
                     mbCrumb={"Checkout"}
                  />
               </div>
               <div className="body">
                  <div className="row justify-between">
                     {state.length === 0 && (
                        <div className="col-12">
                           <NoProduct />
                        </div>
                     )}
                     {state.length !== 0 && (
                        <>
                           <div className="col-lg-3 mb-4 bg-neutral-950 lg:px-5 col-12">
                              <div className="items text-white">
                                 <div className="head py-2">
                                    <span className="text-xl text-ng-primary font-bold">
                                       Your Order items ({state.length})
                                    </span>
                                    <hr className="mt-3" />
                                 </div>
                                 {state.map((i) => (
                                    <div className="items-con mb-4" key={i}>
                                       <div className="item-card flex w-full gap-3 leading-8">
                                          <img
                                             src={i.product_img}
                                             alt="Items"
                                             className="w-[100px]"
                                          />
                                          <div className="item-data">
                                             <span className="text-lg">
                                                {i.product_name}
                                             </span>{" "}
                                             <br />
                                             <span className="text-ng-primary font-bold ">
                                                &#8358;
                                                <NumericFormat
                                                   value={i.product_price}
                                                   displayType={'text'}
                                                   thousandSeparator={','}
                                                />
                                             </span>{" "}
                                             <br />
                                             <span className="text-xs">
                                                Qty: {i.product_quantity}
                                             </span>
                                          </div>
                                       </div>
                                    </div>
                                 ))}
                              </div>
                           </div>
                           <div className="col-lg-8 col-12 lg:p-10 lg:bg-black text-light">
                              <div className="bill  border-ng-primary lg:border bg-ng-secondary-dark lg:bg-transparent py-4 p-3 my-3">
                                 <div className="address leading-6">
                                    <div className="head flex justify-between pb-3">
                                       <span className="font-bold">
                                          Address Details
                                       </span>{" "}
                                       <span className="text-ng-primary cursor-pointer" onClick={() => navi("/product/cart/billing-address")}>
                                          Edit
                                       </span>
                                    </div>
                                    <div className="name pb-3">
                                       <span className="text-ng-primary">
                                          Name:{" "}
                                       </span>{" "}
                                       <br />
                                       <span>
                                          {firstname} {lastname}
                                       </span>
                                    </div>

                                    <div className="address pb-3">
                                       <span className="text-ng-primary">
                                          Address:{" "}
                                       </span>{" "}
                                       <br />
                                       <span>
                                          {delivery_address}, {delivery_city},{" "}
                                          {delivery_state}
                                       </span>
                                    </div>
                                    <div className="email pb-3">
                                       <span className="text-ng-primary">
                                          Email:{" "}
                                       </span>{" "}
                                       <br />
                                       <span>{email}</span>
                                    </div>
                                    <div className="phone pb-3">
                                       <span className="text-ng-primary">
                                          Phone Number:{" "}
                                       </span>{" "}
                                       <br />
                                       <span>{phone}</span>
                                    </div>
                                 </div>
                              </div>
                              <div className="price p-3 leading-8 pt-3 bg-ng-secondary-dark">
                                 <div className=" flex justify-between">
                                    <span>Subtotal</span>{" "}
                                    <span className="font-bold">
                                       &#8358;
                                       <NumericFormat
                                          value={payAmount()}
                                          displayType={'text'}
                                          thousandSeparator={','}
                                       />
                                    </span>
                                 </div>
                                 {/* <div className=" flex justify-between">
                                    <span>Delivery fee</span>{" "}
                                    <span className="font-bold">700</span>
                                 </div> */}
                                 <div className=" flex justify-between">
                                    <span>Discount</span>{" "}
                                    <span className="font-bold">0%</span>
                                 </div>
                                 <hr className="my-3" />
                                 <div className=" flex justify-between">
                                    <span>Total</span>{" "}
                                    <span className="font-bold text-ng-primary">
                                       &#8358;
                                       <NumericFormat
                                          value={payAmount()}
                                          displayType={'text'}
                                          thousandSeparator={','}
                                       />
                                    </span>
                                 </div>
                                 <div className="btn-wrap my-3 text-center">
                                    <button
                                       className="w-full  bg-ng-primary rounded-md text-black text-sm font-bold py-3 px-3 flex gap-2 items-center justify-center"
                                       onClick={handleSubmit}
                                    >
                                       Pay with
                                       <img src="https://dashboard.paystack.com/assets/img/logo.svg" alt="Paystack" className="w-[10px}" />
                                    </button>
                                    <span>or</span>
                                    <button
                                       className="w-full  bg-white mt-2 placeholder-glow rounded-md text-black text-sm font-bold py-3 px-3 flex gap-1 justify-center items-center"
                                       onClick={() => setOpen(!open)}
                                    >
                                       Pay <span className={`${!ethAmount && 'placeholder rounded-md w-[20px]'}`}>{ethAmount}</span> <img src="https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png" className="w-[20px]" alt="ETH" />
                                    </button>
                                 </div>
                              </div>
                           </div>
                        </>
                     )}
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}
