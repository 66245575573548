import axios from "axios";
import React, { useEffect, useState } from "react";
import notify from "../../../../functions/notify";
import Navigator from "../../../components/navigator";
import ProductBreadcrumb from "../../../components/product-breadcrumb";
import MerchCon from "../components/merch-con";
import MerchSkeleton from "../../../admin/edit-product/select/components/MerchSkeleton";
import { useAccount } from "wagmi";
import { logout } from "../../../../functions/tokenLogger";
import Header from "../../../components/header";
import MbHeader from "../../../components/mb-header";

export default function Merch() {
   const [products, setProducts] = useState([]);
   const { isConnected } = useAccount()

   useEffect(() => {
      // if (!isConnected) logout()
      getProduct();
   }, []);
   const getProduct = async () => {
      try {
         const response = await axios.get(`${window.api}/get-products`);
         setProducts(response.data);
      } catch (error) {
         console.log(error);
         notify("error", "Unknow error");
      }
   };
   return (
      <>
         <Header />
         <MbHeader />

         <div className="merch">
            <div className="container min-h-screen">
               <div className="heading">
                  <Navigator link={"/product"} />
                  <ProductBreadcrumb
                     base={"Product"}
                     current={"merchandise"}
                     mbCrumb={"Merchandise"}
                  />
               </div>
               <div className="body  bg-ng-secondary-dark p-4 ">
                  <div className="row">
                     {products.length === 0 && (<span className="text-white text-3xl"><MerchSkeleton /></span>)}

                     {products.map((product, index) => (
                        <div
                           className="col-lg-3 col-md-4 col-sm-6 col-12 my-2"
                           key={index}
                        >
                           <MerchCon
                              id={product._id}
                              name={product.product_name}
                              price={product.product_price}
                              img={product.product_img}
                           />
                        </div>
                     ))}
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}
