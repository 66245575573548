import axios from "axios";
import { logout } from "./tokenLogger";
import { removeCookie } from "./cookies";

// This function recive token token for either admin or user
// and verifies the token in the server...
export const verifyToken = async (token, who) => {
   try {
      const response = await axios.get(`${window.api}/${who}/check-token`, {
         headers: {
            [`nftng-${who}-token`]: token,
         },
      });
      return true;
   } catch (error) {
      console.log(error);
      removeCookie(`nft-${who}-token`);
      logout(who)
      // window.location.href = who === "admin" ? "/admin/login" : "/auth/login";
      return false;
   }
};
