import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserLayout from "../../dashboard/components/UserLayout";
import { get } from "../../../../functions/get";
import { FaExclamationCircle } from "react-icons/fa";

export default function Orders() {
   const dum = [1, 2, 3];
   const navi = useNavigate()
   const [orders, setOrders] = useState([])

   useEffect(() => { getOrders() }, [])

   const getOrders = async () => {
      try {
         const response = await get("/user/store/user-orders")
         const userOrders = response.data.message
         setOrders(userOrders)
      } catch (error) {
         console.log(error)
      }
   }

   const productQty = (product) => {
      let totalQty = 0;
      for (let i = 0; i < product.length; i++) {

         const pdqty = product[i].product_quantity

         totalQty += pdqty
      }
      return totalQty
   }

   return (
      <UserLayout heading={"Order History"}>
         <div className="wrap col-lg-8 mx-auto bg-ng-secondary-dark p-3">
            <div className="heading text-lg font-bold text-ng-primary">
               <span>Order History </span>
            </div>

            {
               orders.length === 0 && (
                  <div className="wrap p-4 text-center mt-4 text-lg flex justify-center items-center gap-2 bg-ng-primary-opacity text-ng-primary font-bold rounded-xl">
                     <FaExclamationCircle /> <span>No Record Found</span>
                  </div>
               )
            }

            {orders.map((i, index) => (
               <div className="wrap cursor-pointer bg-ng-primary-opacity p-3 py-1 my-3 rounded-xl" key={index} onClick={() => navi(`/user/store/order/detail?order_id=${i._id}`)}>
                  <div className="flex flex-wrap justify-between items-center gap-3">
                     <div className="right-box grid gap-3 grid-cols-3">
                        <div className="text-wrap my-2">
                           <span className="text-sm  text-ng-primary ">
                              Order
                           </span>{" "}
                           <br />
                           <span className="text-gray-400">{i._id}</span>
                        </div>
                        <div className="text-wrap my-2">
                           <span className="text-sm  text-ng-primary ">
                              Total Quantity
                           </span>{" "}
                           <br />
                           <span className="text-gray-400"> {productQty(i.ordered_product)} Qty</span>
                        </div>
                        <div className="text-wrap my-2">
                           <span className="text-sm  text-ng-primary ">
                              Total Product
                           </span>{" "}
                           <br />
                           <span className="text-gray-400">{i.ordered_product.length} Products</span>
                        </div>
                     </div>
                     <div className="left-box text-gray-400 pb-2">
                        Status: <span className={`badge bg-ng-primary-opacity text-xs text-ng-primary`}>{i.status}</span>
                     </div>
                  </div>
               </div>
            ))}
         </div>
      </UserLayout>
   );
}
