import React from 'react'
import { FaCalendar, FaMapMarker } from 'react-icons/fa';

export default function Football() {
    const teamsData = [
        { team: "CLORD TEAM", P: 0, W: 0, D: 0, L: 0, GD: 0, PTS: 0 },
        { team: "CSEERS DAO", P: 0, W: 0, D: 0, L: 0, GD: 0, PTS: 0 },
        { team: "SMC DAO", P: 0, W: 0, D: 0, L: 0, GD: 0, PTS: 0 },
        { team: "NW3 FC", P: 0, W: 0, D: 0, L: 0, GD: 0, PTS: 0 },
        { team: "AVAX DAO", P: 0, W: 0, D: 0, L: 0, GD: 0, PTS: 0 },
        { team: "STREETS DAO", P: 0, W: 0, D: 0, L: 0, GD: 0, PTS: 0 },
        { team: "GM COMMUNITY FC", P: 0, W: 0, D: 0, L: 0, GD: 0, PTS: 0 },
        { team: "FC VALHALLA", P: 0, W: 0, D: 0, L: 0, GD: 0, PTS: 0 },
    ];

    return (
        <div className="wrap">
            <div className="heading text-center mb-3 px-3">
                <h1 className='font-extrabold text-white lg:text-4xl text-3xl'>NFTng <span className="text-ng-primary font-extrabold">DEFI SUMMER</span> <br /> SOCCER TOURNAMENT</h1>
                <p className='font-bold text-gray-300'>5 A-SIDE FOOTBALL</p>
            </div>

            <div className="details container mx-auto text-gray-300 mb-4">
                <div className="grid lg:grid-cols-2 gap-6 items-center">
                    <div className="date">
                        <div className="flex gap-3">
                            <FaCalendar className='text-ng-primary' /> <span>23rd June 2024</span>
                        </div>
                        <div className="flex gap-3">
                            <FaMapMarker  className='text-ng-primary' /> <span>Lagos</span>
                        </div>
                    </div>
                    <div className="wrap lg:text-right">
                        <span className=' capitalize font-extrabold text-xl text-ng-primary'>Tournament Rules</span> <br />
                        <span>- 10 players each team</span> <br />
                        <span>- 8 teams</span> <br />
                        <span>- 1 winner</span> <br />
                    </div>
                </div>
            </div>
            <div className="table">
                <div className="table-responsive container mx-auto text-center">
                    <table width={"100%"} className='rounded-xl overflow-clip'>
                        <thead>
                            <tr>
                                <th className='font-bold p-1 py-2 bg-slate-200 px-2 text-left'>Teams</th>
                                <th className='font-bold p-1 py-2 bg-slate-200'>P</th>
                                <th className='font-bold p-1 py-2 bg-slate-200'>W</th>
                                <th className='font-bold p-1 py-2 bg-slate-200'>D</th>
                                <th className='font-bold p-1 py-2 bg-slate-200'>L</th>
                                <th className='font-bold p-1 py-2 bg-slate-200'>GD</th>
                                <th className='font-bold p-1 py-2 bg-slate-200'>PTS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {teamsData.map((i, index) => (
                                <tr>
                                    <td className='p-1 px-2 text-left'>{i.team}</td>
                                    <td className='p-1'>{i.P}</td>
                                    <td className='p-1'>{i.W}</td>
                                    <td className='p-1'>{i.D}</td>
                                    <td className='p-1'>{i.L}</td>
                                    <td className='p-1'>{i.GD}</td>
                                    <td className='p-1'>{i.PTS}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
