import React from "react";

export default function Workers({ imgArr, icon, header, text }) {
   const dum = [1, 2, 3, 4, 5, 5];
   return (
      <div className="venue-wrap box p-4 bg-neutral-950">
         <div className="box-head flex gap-2 items-center">
            <img src={icon} alt="Apply" width={30} />{" "}
            <span className="font-bold text-ng-primary text-xl uppercase">
               {header}
            </span>
         </div>
         <div className="box-body text-slate-50 text-sm my-4 mb-1">
            <span className="leading-8">{text}</span>
         </div>
         <div className="flex flex-wrap ">
            {imgArr.map((i, index) => (
               <div className="col-lg-2 col-md-2 col-sm-2 p-2 col-3 flex justify-center" key={index}>
                  <div className="wrap flex items-center">
                     <img
                        src={`/partners/${i}`}
                        alt={i}
                        className=""
                     />
                  </div>
               </div>
            ))}
         </div>
      </div>
   );
}
