import React, { useEffect, useState } from "react";
import { FaCheckCircle, FaEye } from "react-icons/fa";
import { FaBoltLightning } from "react-icons/fa6";
import TaskDetails from "./TaskDetails";
import { get } from "../../../../../functions/get";
import TaskSkeleton from "./TaskSkeleton";

export default function Task() {
   const [show, setShow] = useState(false);
   const [task, setTask] = useState(null)
   const [taskData, setTaskData] = useState({
      img: "http://res.cloudinary.com/dlnrppbjh/image/upload/v1711450755/IMG_3196.PNG.png",
      status: "ended",
      title: "Run again 3rd tme",
      type: "like",
      link: "",
      views: 2,
      _id: "6602aa85e32794e59571045a"
   })

   useEffect(() => {
      getTasks()
   }, [])

   const getTasks = async () => {
      try {
         const response = await get('/user/get-task')
         setTask(response.data.message)
         console.log(response.data.message)
      } catch (error) {
         console.log(error)
      }
   }
   const handleClick = async (singleTask) => {
      setShow(true)
      setTaskData(singleTask)
      try {
         await get(`/user/update-views/${singleTask._id}`)
      } catch (error) {
         console.log(error)
      }
   }

   return (
      <div className="task-wrap col-lg-8 mx-auto mb-16 rounded-3xl overflow-clip">
         <div className="bg-con lg:p-5 backdrop-blur-xl bg-black bg-opacity-75">
            {show && <TaskDetails task={taskData} show={show} setShow={() => setShow(false)} getTasks={getTasks} />}
            <div className="heading p-2 text-2xl md:text-2xl font-bold text-white my-4">
               <span>TASKS</span>
            </div>
            <div className="grid [2000px]:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 grid-cols-2">
               {task && task.length === 0 && (
                  <div className="wrap text-center flex justify-center w-[100%] mx-auto col-span-4 py-3">
                     <span className="flex justify-center gap-2 text-lg text-ng-primary items-center p-3 rounded-xl bg-gray-50 bg-opacity-20">All Task Completed <FaCheckCircle /> </span>
                  </div>
               )}
               {task && (
                  task.map((task, index) => (
                     <div
                        className="cards rounded-xl  m-2 mb-5 md:m-16 bg-ng-secondary-opacity"
                        key={index}
                        onClick={() => handleClick(task)}
                     >
                        <div className="img-wrap">
                           <img
                              src={task.img}
                              alt="Task"
                              className=" w-full  rounded-t-xl"
                           />
                        </div>
                        <div className="views text-sm font-thin text-ng-primary">
                           {/* <span className="flex gap-1 p-2 items-center">
                              <FaEye /> {task.views ? task.views : 0}
                           </span> */}
                        </div>
                        <div className="text-wrap text-sm text-white p-2">
                           <span className=" font-extrabold">
                              {task.title}
                           </span>{" "}
                           <br />
                        </div>
                        <div className="wrap flex justify-between items-center p-2 text-sm">
                           <span className="flex items-center gap-1 text-ng-primary">
                              <FaBoltLightning className="mx-auto text-lg text-yellow-300" />
                              {task.buzz_reward}
                           </span>
                           <span className={`badge ${task.status === "ended" ? "bg-red-600" : "bg-ng-primary"}`}>{task.status}</span>
                        </div>
                     </div>
                  ))
               )}

            </div>
            {!task && (
               <TaskSkeleton />
            )}
         </div>
      </div>
   );
}
