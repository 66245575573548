import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import Container from '../Components/Container'
import { getCookie } from '../../../functions/cookies'
import axios from 'axios'
import notify from '../../../functions/notify'
import { RingLoader } from 'react-spinners'

export default function WinnersDisplay({ data, open, setOpen, prize }) {
    const cancelButtonRef = useRef(null)
    const [loading, setLoading] = useState(false)

    const token = getCookie('nftng-admin-token')

    const sendCode = async () => {
        try {
            setLoading(true);
            const response = await axios.post(
                `${window.api}/admin/send-winners-code`,
                { winners: data, prize, },
                {
                    headers: {
                        "content-type": "application/json",
                        "nftng-admin-token": token,
                    },
                }
            );
            setOpen(false)
        } catch (error) {
            error.response.data === "Access Denied! No auth token" && (window.location.href = "/admin/login")
            console.log(error);
            notify("error", "An Error Occured");
        }
        finally {
            setLoading(false);
        }
    };
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-900 backdrop-blur-xl bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-ng-secondary-dark bg-opacity-70 text-left shadow-xl transition-all sm:my-8 ">
                                <Container>
                                    <div className="p-5">
                                        <div className="header font-bold text-2xl text-white text-center mb-3">
                                            <h1>Raffle <span className='font-bold text-ng-primary'>Winners</span></h1>
                                        </div>
                                        <div className="flex flex-wrap gap-5 justify-between ">
                                            {data.map((i, index) => (
                                                <div className={`pill trans bg-ng-primary text-black p-2 rounded-full flex items-center gap-4 px-4`}>
                                                    {/* <img src={i.pfp ? i.pfp : "/n2.png"} alt="User" className='rounded-full h-[20px] w-[20px]' /> */}
                                                    <span className='font-bold text-sm'>{i.nickname}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Container>
                                <div className="text-sm bg-ng-secondary-dark px-4 py-3 sm:flex justify-center sm:px-6">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-ng-primary px-3 py-2 font-semibold text-black shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                                        onClick={() => sendCode()}
                                    >
                                        {loading ? <RingLoader size={'25px'} color='#000' /> : "Send Winner Codes"}
                                    </button>
                                    {/* <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                        onClick={() => setOpen(false)}
                                        ref={cancelButtonRef}
                                    >
                                        Cancel
                                    </button> */}
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
