import React from "react";
import twitter from "../../svgs/twitter.svg";
import instagram from "../../svgs/instagram.svg";
import { Link } from "react-router-dom";

export default function Footer() {
   return (
      <div className="footer text-gray-400 bg-black ">
         <div className="container py-4">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 lg:gap-5 items-center">
               <div className="flex items-center flex-wrap gap-3">
                  <span className="text-light font-bold text-lg">
                     GET IN TOUCH
                  </span>
                  <div className="footer-icons flex gap-2">
                     {" "}
                     <a href={"https://twitter.com/nft__ng"} target="_blank">
                        <i>
                           <img src={twitter} alt="twitter" />
                        </i>{" "}
                     </a>{" "}
                     <a href={"https://www.instagram.com/nft__ng/"} target="_blank">
                        <i>
                           <img src={instagram} alt="instagram" />
                        </i>
                     </a>
                  </div>
               </div>

               <div className="wrap lg:text-center">
                  <span>All Right Reserved. Powered by <span className="text-ng-primary font-bold">NFTng</span></span>
               </div>
               <div className="wrap lg:text-right">
                  <span>Copyright © 2024 </span>
               </div>
            </div>
         </div>
      </div>
   );
}
