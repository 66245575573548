import axios from "axios";
import React, { useEffect } from "react";
import Success from "../../../../components/success";

export default function PaymentSuccess() {
   useEffect(() => {
      paymentCallback();
   }, []);

   // extract queries from url
   const extractQueries = () => {
      const url = window.location.href;
      const urlSearchParams = new URLSearchParams(url.split("?")[1]);
      // Extract all queries as an object
      const queries = {};
      for (const [key, value] of urlSearchParams) {
         queries[key] = value;
      }
      return queries;
   };

   // Payment callback
   const paymentCallback = async () => {
      try {
         const queries = extractQueries();
         if (queries.reference) {
            const response = await axios.post(
               `${window.api}/payment/callback?reference=${queries.reference}`
            );
         } 
      } catch (error) {
         console.log(error); 
      }
   };
   return (
      <div className="pay-success bg-ng-secondary-dark p-3">
         <div className="wrap h-[100vh] p-5">
            <Success
               message={
                  "Payment Successful. You'll be contacted via your email and phone number you provided during checkout. Thank you purchasing our merch. WAGMI   "
               }
               link="/"
               btnText={"Done"}
            />
         </div>
      </div>
   );
}
