import React, { createContext, useReducer } from "react";
import { editReducer, INITIAL_STATE } from "./editReducer";

export const EditContext = createContext();

export default function EditContextWrap({ children }) {
   const [state, dispatch] = useReducer(editReducer, INITIAL_STATE);
   return (
      <EditContext.Provider value={{ state, dispatch }}>
         {children}
      </EditContext.Provider>
   );
}
