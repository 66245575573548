import React from "react";
import { Link } from "react-router-dom";

export default function NoProduct() {
   return (
      <div className="np bg-ng-secondary-dark py-5 p-4">
         <div className="col-lg-4 col-md-8 col-12 mx-auto text-center">
            <span className="text-ng-primary text-sm font-bold">
               You have no item in your cart kindly add some item from the merch
               section
            </span>
            <div className="btn-wrap mt-4">
               <Link to={"/product/merchandise"}>
                  <button className="bg-ng-primary w-full font-bold text-xs text-black rounded-lg p-3">
                     Add items
                  </button>
               </Link>
            </div>
         </div>
      </div>
   );
}
