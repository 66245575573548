import React, { useEffect, useState } from "react";
import SelectCon from "./components/select-con";
import Navigator from "../../../components/navigator";
import Breadcrumb from "../../../components/breadcrumb";
import notify from "../../../../functions/notify";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import MerchSkeleton from "./components/MerchSkeleton";

export default function Select() {
   const [products, setProducts] = useState([])
   useEffect(() => { getProduct() }, [])

   const getProduct = async () => {
      try {
         const response = await axios.get(`${window.api}/get-products`);
         setProducts(response.data)
      } catch (error) {
         console.log(error);
         notify("error", "Unknow error");
      }
   };
   return (
      <div className="merch">
         <div className="container">
            <div className="heading">
               <Navigator link={"/admin/dashboard"} />
               <Breadcrumb
                  base={"Edit product"}
                  current={"select"}
                  mbCrumb={"edit product"}
               />
               <ToastContainer theme="dark" position="bottom-right" />
            </div>
            <div className="body  bg-ng-secondary-dark p-4 ">
               <div className="row">
                  {products.length === 0 && (<span className="text-white text-3xl"><MerchSkeleton /></span>)}

                  {products.reverse().map((product, index) => (
                     <div
                        className="col-lg-3 col-md-4 col-sm-6 col-12 my-2"
                        key={index}
                     >
                        <SelectCon
                           id={product._id}
                           name={product.product_name}
                           price={product.product_price}
                           img={product.product_img}
                           size={product.product_size}
                        />
                     </div>
                  ))}
               </div>
            </div>
         </div>
      </div>
   );
}
