import React, { useContext } from "react";
import Navigator from "../../../components/navigator";
import ProductBreadcrumb from "../../../components/product-breadcrumb";
import Payout from "../components/payout";
import NoProduct from "../components/no-product";
import { CartContext } from "../../../../context/cartContext";
import CartBox from "../components/cart-box";
import ClientWrapper from "../../../components/ClientWrapper";

export default function Cart() {
   const { state, dispatch } = useContext(CartContext);

   return (
      <ClientWrapper>
         <div className="cart min-h-[80vh]">
            <div className="container">
               <div className="header">
                  <Navigator link={"/product/merchandise"} />
                  <ProductBreadcrumb
                     base={"product"}
                     current={"merchandise / cart"}
                     mbCrumb={"Your Cart"}
                  />
               </div>

               {/* If There's no Product */}
               {state.length === 0 && <NoProduct />}

               {/* If there are products */}
               {state.length !== 0 && (
                  <div className="row p-2">
                     <div className="col-lg-8 lg:p-5 p-3 mb-4  bg-ng-secondary-dark lg:bg-black">
                        {state.map((cart, index) => (
                           <CartBox
                              cart={cart}
                              dispatch={dispatch}
                              key={index}
                           />
                        ))}
                     </div>
                     <div className="col-lg-4">
                        <Payout state={state} />
                     </div>
                  </div>
               )}
            </div>
         </div>
      </ClientWrapper>
   );
}
