import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { EditContext } from "../../../../../context/edit-context";
import { NumericFormat } from "react-number-format";

export default function SelectCon({ id, name, price, img, size }) {
   const { state, dispatch } = useContext(EditContext);
   const setEditReducer = () => {
      dispatch({ type: "p-img", payload: img })
      dispatch({ type: "p-price", payload: price })
      dispatch({ type: "p-size", payload: size })
      dispatch({ type: "p-name", payload: name })
      dispatch({ type: "p-id", payload: id })
   };
   return (
      <div className="mx-2">
         <div className="team bg-black border rounded-lg relative h-[350px]  overflow-hidden">
            <img src={img} alt="TEAM" className="min-w-[100%] h-[auto]" />
            <div className="absolute right-0 left-0 top-0 bottom-0  overlay"></div>
            <div className="flex p-2 justify-between absolute right-0 left-0 bottom-0 img-con">
               <div className="leading-3">
                  <span className="text-white font-bold text-sm">{name}</span>{" "}
                  <br />
                  <span className="text-ng-primary text-xs">&#8358;
                     <NumericFormat
                        value={price}
                        displayType={'text'}
                        thousandSeparator={','}
                     />
                  </span>
               </div>
               <span className="flex text-white gap-2 bottom-0">
                  <Link to={"/admin/edit-product/merchandise"}>
                     <button className="bg-ng-primary text-black p-1 px-3 rounded-md text-xs" onClick={() => setEditReducer()}>
                        Edit
                     </button>
                  </Link>
               </span>
            </div>
         </div>
      </div>
   );
}
