import React, { createContext, useEffect, useReducer } from "react";
import CartReducer, { CART_INITIAL_STATE } from "./cartReducer";
import { getCookie, removeCookie, setCookie } from "../functions/cookies";

export const CartContext = createContext();
export default function CartContextWrap({ children }) {
   const [state, dispatch] = useReducer(CartReducer, []);

   useEffect(() => {
      const cookieState = JSON.parse(localStorage.getItem('user-cart'));

      // if there data in the cooki state and there no data in the state
      // pull data from the cookie state and add to the cart
      // state.length === 0 && localStorage.removeItem('user-cart')
      cookieState && (
         state.length === 0 & cookieState.length > 1  && (
            dispatch({ type: "ADD_STORED_CART", payload: cookieState ? cookieState : [] })
         )
      )

      // Add data when the state isn't emty
      state.length > 0 && localStorage.setItem('user-cart', JSON.stringify(state));
      // removeCookie("user-cart")
   }, [state])
   return (
      <CartContext.Provider value={{ state, dispatch }}>
         {children}
      </CartContext.Provider>
   );
}
