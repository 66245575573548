import React from "react";
import UserLayout from "../../components/UserLayout";
import Downlines from "./components/Downlines";
import LinkBox from "./components/LinkBox";

export default function Referral() {
   return (
      <UserLayout isDashboard={true}>
         <div className="wrap bg-ng-secondary-dark col-lg-8 mx-auto py-10 rounded-t-xl  ">
            <LinkBox />
            <Downlines />
         </div>
      </UserLayout>
   );
}
