import React, { useEffect, useState } from "react";
import UserLayout from "../dashboard/components/UserLayout";
import { FaBoltLightning } from "react-icons/fa6";
import { FaUsers } from "react-icons/fa";
import { get } from "../../../functions/get";
import { RingLoader } from "react-spinners";
import { NumericFormat } from "react-number-format";


export default function Leaderboard() {
   const [limit, setLimit] = useState(10)
   const [loading, setLoading] = useState(false)
   const [leaderboard, setLeaderboard] = useState([])
   const [totalParticipant, setTotalParticipant] = useState(0)

   useEffect(() => {
      getLeaderboardStat()
   }, [limit])

   const getLeaderboardStat = async () => {
      try {
         setLoading(true)
         const response = await get(`/leaderboard?limit=${limit}`)
         setLeaderboard(response.data.message.leaderboardData)
         setTotalParticipant(response.data.message.totalParticipant)

         console.log(response.data.message.totalParticipant)
      } catch (error) {
         console.log(error)
      } finally {
         setLoading(false)
      }
   }

   return (
      <UserLayout heading={"Leaderboard"} showLayout={true}>
         <div className="wrap px-3 col-lg-8 mx-auto">
            <div className="heading flex justify-between items-center mt-3">
               <span className="font-bold text-white text-xl">
                  Total Participants (<NumericFormat
                     value={totalParticipant}
                     displayType={'text'}
                     thousandSeparator={','}
                  />)
               </span>
               {/* <span className="flex gap-3 text-gray-400">
                  Buzz Earned:{" "}
                  <span className="flex items-center gap-1">
                     <FaBoltLightning className="text-yellow-300" /> 400
                  </span>
               </span> */}
            </div>
            <div className="users">
               <ul className="my-10 ">
                  <span className="text-ng-primary font-bold text-lg mx-2">Top 200</span>
                  {leaderboard.map((i, index) => (
                     <li className="flex justify-between items-center text-gray-400 rounded-full bg-ng-primary-opacity px-3 py-3 my-2" key={index}>
                        <div className="wrap flex gap-3 items-center">
                           <span className=" text-ng-primary text-sm font-bold">{index + 1}.</span>
                           <img
                              src={i.pfp ? i.pfp : "/n2.png"}
                              alt="user"
                              className="w-[30px] h-[30px] rounded-full "
                           />
                           {/* <span>{obscureEmail(i.nickname)}</span> */}
                           <span>{i.nickname}</span>
                        </div>
                        <div className="flex gap-3 ">
                           <span className="flex gap-1 items-center">
                              {" "}
                              {i.totalReferral}
                              <FaUsers className="text-ng-primary" />
                           </span>
                           <span className="flex gap-1 items-center">
                              {" "}
                              {i.buzz_point}{" "}
                              <FaBoltLightning className="text-yellow-300" />
                           </span>
                        </div>
                     </li>
                  ))}
               </ul>
               {loading && (
                  <div className="wrap flex justify-center items-center gap-3 text-ng-primary tex-lg font-bold">Fetching Leaderboard<RingLoader color="#A1D283" size={20} /></div>
               )}

               {/* <div className="btn-wrap text-gray-400 mb-10">
                  <button className="border-2 border-ng-primary text-ng-primary rounded-3xl p-2 px-3 text-sm" onClick={() => setLimit(limit + 100)}>
                     {loading ? <RingLoader color="#A1D283" size={20} /> : 'View more'}
                  </button>
               </div> */}
            </div>
         </div>
      </UserLayout>
   );
}
