import React, { useContext } from "react";
import { ToastContainer } from "react-toastify";
import { CartContext } from "../../../../context/cartContext";
import notify from "../../../../functions/notify";
import { NumericFormat } from "react-number-format";

export default function MerchCon({ id, name, price, img }) {
   const { state, dispatch } = useContext(CartContext);

   const addToCart = (product_id, product_name, product_price, product_img) => {
      const cart = {
         cart_id: state.length + 1,
         product_id,
         product_name,
         product_price,
         product_img,
         product_quantity: 1,
         product_size: "m",
      };
      notify("success", "1 item added to cart");
      dispatch({ type: "ADD_CART", payload: cart });
   };
   return (
      <div className="mx-2">
         <div className="team bg-black border rounded-lg relative  h-[350px]   overflow-hidden">
            <img src={img} alt="TEAM" className="min-w-[100%] h-[auto]" />
            <div className="absolute right-0 left-0 top-0 bottom-0  overlay"></div>
            <div className="flex flex-wrap p-2 justify-between absolute right-0 left-0 bottom-0 img-con">
               <div className="leading-3">
                  <span className="text-white font-bold text-sm">{name}</span>{" "}
                  <br />
                  <span className="text-ng-primary text-xs">&#8358;
                     <NumericFormat
                        value={price}
                        displayType={'text'}
                        thousandSeparator={','}
                     />
                  </span>
               </div>
               <span className="flex text-white gap-2 bottom-0">
                  <button
                     className="bg-ng-primary text-black p-1 lg:p-3 py-1 rounded-md text-xs"
                     onClick={() => addToCart(id, name, price, img)}
                  >
                     Add to cart
                  </button>
               </span>
            </div>
         </div>
      </div>
   );
}
