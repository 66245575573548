import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { post } from '../../../../functions/post'
import { RingLoader } from 'react-spinners'
import notify from '../../../../functions/notify'
import getUserInfo from '../../../../functions/getUserInfo'
import { UserDetailsContext } from '../../../../context/UserDetailsContext'


export default function DialogBox({ open, setOpen }) {
  // const [new_email, setEmail] = useState('')
  // const [new_username, setUsername] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const { state: { email, username }, dispatch } = useContext(UserDetailsContext)

  useEffect(() => {
    gettingUserInfo()
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    try {
      const data = { email, username }
      if (email === "" | username === "") return notify('error', "Fill all field")
      await post('/user/profile/edit-info', data)
      await gettingUserInfo()
      setOpen(false)
      notify('success', "Updated Successfully")
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const gettingUserInfo = async () => {
    try {
      const info = await getUserInfo()
      dispatch({ type: 'set-info', payload: info })
    } catch (error) {
      console.log(error)
    }
  }

  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-75 backdrop-blur-lg transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <form onSubmit={handleSubmit}>
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="wrap grid gap-3">
                      <div className="input-wrap">
                        <span className='text-md'>Set new username <sup className='text-red-500'>*</sup></span>
                        <input type="text" className='mt-2 w-full bg-gray-200 rounded-md p-2' placeholder='Johnny'
                          onChange={e => dispatch({ type: "username", payload: e.target.value })} value={username} />
                      </div>
                      <div className="input-wrap">
                        <span className='text-md'>Set new Email <sup className='text-red-500'>*</sup></span>
                        <input type="email" className='mt-2 w-full bg-gray-200 rounded-md p-2' placeholder='example@abc.com' onChange={e => dispatch({ type: "email", payload: e.target.value })} value={email} required disabled/>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="submit"
                      className="w-full justify-center rounded-md bg-ng-primary px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-opacity-50 sm:ml-3 sm:w-auto"
                    >
                      {isLoading ? <RingLoader size={'25px'} /> : 'Confirm'}
                    </button>
                    <button
                      type="button"
                      className="w-full justify-center text-red-500 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-opacity-50 sm:ml-3 sm:w-auto"
                      ref={cancelButtonRef}
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
