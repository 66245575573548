import React from "react";
import { Link } from "react-router-dom";

export default function Success({ message, link, btnText }) {
   return (
      <div className="success">
         <div className="flex h-[100vh] w-[100vw] bg-black bg-opacity-50 filter backdrop-blur-lg  fixed top-0 bottom-0 right-0 left-0 items-center z-50 justify-center">
            <div className="w-1/2">
               <div className="flex col-sm-4 w-full mx-auto gap-2 px-5 py-3 justify-center">
                  <div className="img-wrap">
                     <img src="/check.gif" alt="success" className="rounded-full w-[200px]" />
                  </div>
               </div>
               <div className="px-4 font-bold text-center col-sm-4 w-full mx-auto text-white">
                  <span className="text-sm">{message}</span>
               </div>
               <div className="btn-wrap mt-3 col-sm-4 mx-auto px-4">
                  <Link to={link}>
                     <button className="w-full bg-ng-primary text-black font-bold text-lg rounded-md py-2 hover:bg-ng-primary-opacity hover:text-white">
                        {btnText}
                     </button>
                  </Link>
               </div>
            </div>
         </div>
      </div>
   );
}
