import React, { useEffect, useState } from 'react'
import Container from '../Components/Container'
import AdminHeader from '../Components/AdminHeader'
import Breadcrumb from '../../components/breadcrumb'
import Navigator from '../../components/navigator'
import { FaBoltLightning } from 'react-icons/fa6'
import { FaEye, FaTrash, FaUsers } from 'react-icons/fa'
import { get } from '../../../functions/get'
import axios from 'axios'
import { getCookie } from '../../../functions/cookies'
import Loader from '../../components/loader'

export default function AdminIceTask() {

    const [task, setTask] = useState([])
    const [open, setOpen] = useState(false)
    const [taskId, setTaskId] = useState('')

    const [loading, setLoading] = useState(false)


    useEffect(() => {
        getTask()
    }, [])
    const token = getCookie('nftng-admin-token')

    const getTask = async () => {
        try {
            setLoading(true)
            const response = await axios.get(`${window.api}/admin/get-ice-task`, {
                headers: {
                    "nftng-admin-token": token,
                    "Content-Type": "multipart/form-data",
                },
            })
            setTask(response.data.message)
        } catch (error) {
            console.log(error)
        }
        finally {
            setLoading(false)
        }
    }

    const deleteTask = async (id) => {
        try {
            setLoading(true)
            const response = await axios.delete(`${window.api}/admin/get-ice-task/${id}`, {
                headers: {
                    "nftng-admin-token": token,
                    "Content-Type": "multipart/form-data",
                },
            })
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
            setOpen(false)
            getTask()
        }
    }
    const rewardTask = async (id) => {
        try {
            setLoading(true)
            alert(token)
            const response = await axios.post(`${window.api}/admin/get-ice-task/${id}`, {}, {
                headers: {
                    "nftng-admin-token": token,
                    "Content-Type": "multipart/form-data",
                },
            })
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
            setOpen(false)
            getTask()
        }
    }

    const toggeleOpen = (passId) => {
        setTaskId(passId)
        setOpen(true)
    }
    return (
        <div className="wrap min-h-[100vh]">
            <Container>
                <AdminHeader />
                <Loader loading={loading} />
                <Navigator link={"/admin/dashboard"} />
                <Breadcrumb
                    base={"Task "}
                    current={"Ice Task"}
                    mbCrumb={"Ice Task Participants"}
                />

                <div className="heading">
                    <div className="users">
                        <div className="table-responsive my-3">
                            <table className='w-full rounded-3xl overflow-clip'>
                                <thead className=' bg-ng-primary p-3'>
                                    <tr>
                                        <th className='p-3'>Username</th>
                                        <th className='p-3'>link</th>
                                        <th className='p-3'>Task Type</th>
                                        <th className='p-3'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {task.map((i, index) => (
                                        <tr className=' text-gray-400 rounded-3xl bg-ng-primary-opacity p-3'>
                                            <td className='p-3'>
                                                <div className="wrap flex gap-2 items-center p-3">
                                                    <span>{i.username}</span>
                                                </div>
                                            </td>
                                            <td className="p-3">
                                                <a href={i.link} target='_blank'>{i.link}</a>
                                            </td>
                                            <td className='p-3'>
                                                {i.task_type ? i.task_type : "tiktok"}
                                            </td>
                                            <td className='p-3'>
                                                <div className="btn-wrap flex gap-3 items-center">
                                                    <button className='p-2 rounded-lg text-black bg-ng-primary' onClick={() => rewardTask(i._id)}>Reward</button>
                                                    <button className='p-2 rounded-lg text-white bg-red-500' onDoubleClick={() => deleteTask(i._id)}><FaTrash /></button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="btn-wrap text-gray-400">
                            <button className="border-2 border-ng-primary-opacity rounded-3xl p-2 px-3 text-sm ">
                                view more
                            </button>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}
