import React, { useRef, useState } from "react";
import Breadcrumb from "../../components/breadcrumb";
import Navigator from "../../components/navigator";
import gallery from "../../../svgs/gallery-add.svg";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../../components/loader";
import { getToken } from "../../../functions/tokenLogger";
import { FaArrowRight } from "react-icons/fa";
import AdminHeader from "../Components/AdminHeader";
import { getCookie } from "../../../functions/cookies";
import notify from "../../../functions/notify";

export default function AddProduct() {
    const navi = useNavigate()
    const [viewImage, setViewImage] = useState(null);
    const [loading, setLoading] = useState(false);

    const [title, setTitle] = useState('')
    const [link, setLink] = useState('')
    const [buzz_reward, setBuzz_reward] = useState(0)
    const [description, setDescription] = useState('')
    const [type, setType] = useState('')
    const [img, setImg] = useState(null)
    const [start_date, setStart_date] = useState('')
    const [end_date, setEnd_date] = useState('')


    const fileInputRef = useRef(null);

    const clickFileInput = () => {
        fileInputRef.current.click();
    };
    const selectedImage = (val) => {
        setImg(val);

        if (val) {
            const validImage = val[0];
            const reader = new FileReader();

            reader.onload = (e) => {
                setViewImage(e.target.result);
            };
            reader.readAsDataURL(validImage);
        }
    };


    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setLoading(true)
            if (!img) return notify('error', "Select an image")
            const token = getCookie('nftng-admin-token')
            const data = {
                title, link, buzz_reward, description, type, img, start_date, end_date
            }
            const response = await axios.post(
                `${window.api}/admin/create-task`,
                data,
                {
                    headers: {
                        "nftng-admin-token": token,
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            notify('success', "New task uploaded successfully")
            navi('/admin/task')
        } catch (error) {
            console.log(error)
            setLoading(false);
            notify('error', error.message)

        } finally {
            setLoading(false)
        }
    };
    return (
        <div className="add-prd py-10">
            <div className="container">
                <AdminHeader />
                <Loader loading={loading} />
                <Navigator link={"/admin/dashboard"} />

                <div className="col-lg-8 mx-auto lg:min-h-[73vh]">
                    <Breadcrumb
                        base={"Task"}
                        current={"Create"}
                        mbCrumb={"Create Task"}
                    />

                    <div className="row">
                        <div className="col-lg-6 col-12 flex">
                            <div className="img-view py-5 px-2 mb-3 w-full flex items-center justify-center bg-ng-secondary-opacity text-slate-400 font-thin">
                                <div className="wrap text-center">
                                    <Link>
                                        {viewImage === null && (
                                            <>
                                                <img
                                                    src={gallery}
                                                    alt="gallery-add"
                                                    className="mx-auto mb-2"
                                                    onClick={clickFileInput}
                                                />
                                                <span>Task Image Cover</span>
                                            </>
                                        )}

                                        {viewImage !== null && (
                                            <>
                                                <img
                                                    src={viewImage}
                                                    alt="gallery-add"
                                                    className="mx-auto mb-2 img-fluid"
                                                />
                                                <div
                                                    className="flex gap-2 justify-center items-center border-1 border-ng-primary py-2 text-ng-primary text-sm"
                                                    onClick={clickFileInput}
                                                >
                                                    <img
                                                        src={gallery}
                                                        alt="gallery-add"
                                                        width={25}
                                                    />
                                                    <span> Change Image</span>
                                                </div>
                                            </>
                                        )}
                                    </Link>
                                </div>
                                <div className="form-group">
                                    <input
                                        type="file"
                                        hidden
                                        ref={fileInputRef}
                                        onChange={(e) => selectedImage(e.target.files)}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <form onSubmit={handleSubmit}>
                                <div className="input-wrap">
                                    <input
                                        type="text"
                                        placeholder="Task Title"
                                        className="w-full  p-3 rounded-md mb-4 text-slate-200 text-xs bg-transparent border-1 border-ng-primary border-opacity-30 focus:outline-none focus:ring-2 "
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="input-wrap">
                                    <textarea
                                        type="text"
                                        rows={4}
                                        placeholder="Task Description"
                                        className="w-full  p-3 rounded-md mb-4 text-slate-200 text-xs bg-transparent border-1 border-ng-primary border-opacity-30 focus:outline-none focus:ring-2 "
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        required
                                    />
                                </div>

                                <div className="input-wrap">
                                    <input
                                        type="number"
                                        placeholder="Buzz Reward"
                                        className="w-full  p-3 rounded-md mb-4 text-slate-200 text-xs bg-transparent border-1 border-ng-primary border-opacity-30 focus:outline-none focus:ring-2 "
                                        value={buzz_reward}
                                        onChange={(e) => setBuzz_reward(e.target.value)}
                                        required
                                    />
                                </div>

                                <div className="input-wrap">
                                    <input
                                        type="text"
                                        placeholder="Task Link"
                                        className="w-full  p-3 rounded-md mb-4 text-slate-200 text-xs bg-transparent border-1 border-ng-primary border-opacity-30 focus:outline-none focus:ring-2 "
                                        value={link}
                                        onChange={(e) => setLink(e.target.value)}
                                        required
                                    />
                                </div>

                                <div className="input-wrap">
                                    <select
                                        type="text"
                                        placeholder="Task Title"
                                        className="w-full  p-3 rounded-md mb-4 text-slate-200 text-xs bg-transparent border-1 border-ng-primary border-opacity-30 focus:outline-none focus:ring-2 "
                                        onChange={(e) => setType(e.target.value)}
                                        required
                                    >
                                        <option selected value=''>Task Type</option>
                                        <option value="follow">Follow Task</option>
                                        <option value="like">Like Task</option>
                                        <option value="retweet">Retweet Task</option>
                                        <option value="comment">Comment Task</option>
                                        <option value="quote_retweet">Quote Retweet Task</option>
                                    </select>
                                </div>

                                <div className="input-wrap flex gap-4 text-slate-200">
                                    <div className="wrap">
                                        <label className="text-sm mb-2">Start Date</label>
                                        <input
                                            type="Date"
                                            className="w-full bg-ng-primary-opacity p-3 rounded-md mb-4 text-slate-200 text-xs  border-1 border-ng-primary border-opacity-30 focus:outline-none focus:ring-2 "
                                            onChange={(e) => setStart_date(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="wrap">
                                        <label className="text-sm mb-2">End Date</label>
                                        <input
                                            type="Date"
                                            className="w-full bg-ng-primary-opacity p-3 rounded-md mb-4 text-slate-200 text-xs border-1 border-ng-primary border-opacity-30 focus:outline-none focus:ring-2 "
                                            onChange={(e) => setEnd_date(e.target.value)}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="btn-wrap">
                                    <button
                                        type="submit"
                                        className="px-3 py-2 flex justify-center gap-2 items-center text-black rounded-md w-full lg:w-auto bg-ng-primary font-bold"
                                    >
                                        Create Task <FaArrowRight />
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
