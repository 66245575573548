import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { CartContext } from "../../context/cartContext";
import cart from "../../svgs/cart.svg";

export default function ProductBreadcrumb({ base, current, mbCrumb }) {
   const { state, dispatch } = useContext(CartContext);
   return (
      <div>
         <div className="py-4 hidden lg:block text-white ">
            <div className="flex justify-between">
               <div className="crumb-wrap">
                  <span className="text-xl font-bold uppercase">{base}/ </span>
                  <span className="capitalize text-lg text-ng-primary">{current}</span>
               </div>
               <div className="cart-wrapper relative">
                  <Link to={"/product/cart"}>
                     <img src={cart} alt="cart-icon" />
                     {state.length !== 0 && (
                        <span className="position-absolute text-black top-0 start-0 translate-middle badge text-[10px] rounded-pill bg-ng-primary">
                           {state.length}
                        </span>
                     )}
                  </Link>
               </div>
            </div>
         </div>
         <div className="mb-rumb uppercase lg:hidden block text-xl py-4 text-ng-primary font-bold">
            <div className="flex justify-between items-center">
               <span>{mbCrumb}</span>
               <div className="cart-wrapper relative">
                  <Link to={"/product/cart"}>
                     <img src={cart} alt="cart-icon" />
                     {state.length !== 0 && (
                        <span className="position-absolute text-black top-0 start-0 translate-middle badge text-[10px] rounded-pill bg-ng-primary">
                           {state.length}
                        </span>
                     )}
                  </Link>
               </div>
            </div>
         </div>
      </div>
   );
}
