import axios from "axios";
import React, { useEffect, useState } from "react";
import notify from "../../../functions/notify";
import UserLayout from "../dashboard/components/UserLayout";
import MerchCon from "../product/components/merch-con";
import ProductBreadcrumb from "../../components/product-breadcrumb";
import { FaHistory } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export default function Store() {
   const navi = useNavigate();
   const [products, setProducts] = useState([1, 2, 3]);
   useEffect(() => {
        getProduct();
   }, []);
   const getProduct = async () => {
      try {
         const response = await axios.get(`${window.api}/get-products`);
         setProducts(response.data);
      } catch (error) {
         console.log(error);
         notify("error", "Unknow error");
      }
   };

   return (
      <UserLayout heading={"Puchase Merch / Multiplier"}>
         <div className="wrap col-lg-8 mx-auto">
            <div className="heading px-3">
               {/* <Navigator link={"/product"} /> */}
               <ProductBreadcrumb
                  base={"Product"}
                  current={"merchandise"}
                  mbCrumb={"Merchandise"}
               />
            </div>

            <div className="body  bg-ng-secondary-dark p-4 ">
               <div className="wrap flex justify-end  px-3 py-2 text-sm">
                  <span className=" text-ng-primary opacity-7 flex gap-1 items-center cursor-pointer" onClick={() => navi('/user/store/orders')}> <span>Orders History</span> <FaHistory /></span>
               </div>
               <div className="row">
                  {products.map((product, index) => (
                     <div
                        className="col-lg-4 col-md-4 col-sm-6 col-12 my-2"
                        key={index}
                     >
                        <MerchCon
                           id={product._id}
                           name={product.product_name}
                           price={product.product_price}
                           img={product.product_img}
                        />
                     </div>
                  ))}
               </div>
            </div>
         </div>
      </UserLayout>
   );
}
