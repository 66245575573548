import React, { useState } from 'react'
import ClientWrapper from '../../components/ClientWrapper'
import Loader from '../../components/loader'
import { useNavigate } from 'react-router-dom';
import notify from '../../../functions/notify';
import axios from 'axios';
import { getUrlParam } from '../../../functions/getUrlParameter';
import ReCAPTCHA from 'react-google-recaptcha';

export default function Signup() {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [nickname, setNickname] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [err, setErr] = useState("");
    const [recaptchaToken, setRecaptchaToken] = useState(null);
    const navi = useNavigate();

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setLoading(true);

            // verify user password
            if (password !== confirmPassword) return setErr('Password does not match');
            if (!recaptchaToken) return setErr('Please complete the reCAPTCHA');

            const upline = getUrlParam(window.location.href, "upline");

            const data = {
                email, password, nickname, upline: upline ? upline : "system", recaptchaToken
            }
            const response = await axios.post(`${window.api}/auth/signup`, data)
            const userId = response.data.message
            navi(`/auth/otp?userId=${userId}`)
        } catch (error) {
            console.log(error)
            setErr(error.response.data.info)
        }
        finally {
            setLoading(false)
        }
    }

    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
    }

    return (
        <ClientWrapper>
            <div className="login">
                <div className="container">
                    <Loader loading={loading} />
                    <div className="row justify-center items-center min-h-[80vh]">
                        <div className="col-lg-6 rounded-lg flex flex-wrap text-center">
                            <form onSubmit={handleSubmit} className="w-full">
                                <div className="bg-black col-12 mx-auto p-5 rounded-2xl">
                                    <div className="form-text  text-white text-md leading-8  pb-3">
                                        <span className="text-2xl text-white font-bold col-12">
                                            Signup to
                                            <span className="font-bold text-ng-primary">
                                                {" "}
                                                NFTNG
                                            </span> Point System
                                        </span>{" "}
                                        <br />
                                    </div>
                                    <div className="input-wrap  justify-between">
                                        <input
                                            type="email"
                                            placeholder="example@abc.com"
                                            className={`w-full  p-3 rounded-md mb-4 text-slate-200 text-xs bg-transparent border-1 ${err === "Duplicate Email"
                                                ? "border-red-500"
                                                : "border-ng-primary"
                                                } border-opacity-30 focus:outline-none focus:ring-2 `}
                                            required
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />

                                        <input
                                            type="text"
                                            placeholder="Nickname"
                                            className={`w-full  p-3 rounded-md mb-4 text-slate-200 text-xs bg-transparent border-1 ${err === "Duplicate Nickname"
                                                ? "border-red-500"
                                                : "border-ng-primary"
                                                } border-opacity-30 focus:outline-none focus:ring-2 `}
                                            required
                                            value={nickname}
                                            onChange={(e) => setNickname(e.target.value)}
                                        />{" "}

                                        <div className="flex gap-3">
                                            <input
                                                type="password"
                                                placeholder="Enter Password"
                                                className={`w-full  p-3 rounded-md mb-4 text-slate-200 text-xs bg-transparent border-1 ${err === "Password does not match"
                                                    ? "border-red-500"
                                                    : "border-ng-primary"
                                                    } border-opacity-30 focus:outline-none focus:ring-2 `}
                                                required
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />{" "}

                                            <input
                                                type="password"
                                                placeholder="Confirm Password"
                                                className={`w-full  p-3 rounded-md mb-4 text-slate-200 text-xs bg-transparent border-1 ${err === "Password does not match"
                                                    ? "border-red-500"
                                                    : "border-ng-primary"
                                                    } border-opacity-30 focus:outline-none focus:ring-2 `}
                                                required
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                            />{" "}
                                        </div>
                                        <ReCAPTCHA
                                            sitekey="6Lfj_vApAAAAALGFfZROZRPBe2Pgmc-lmmp1D9kr"
                                            onChange={handleRecaptchaChange}
                                        />
                                        <span className="text-danger capitalize">
                                            {err}
                                        </span>
                                    </div>
                                    <div className="btn-wrap my-3 block">
                                        <button
                                            type="submit"
                                            className="w-full px-3 bg-ng-primary rounded-md text-black text-sm font-bold py-3"
                                        >
                                            SIGNUP
                                        </button>
                                    </div>

                                    <div className="wrap text-left">
                                        <span className="text-sm text-gray-300 cursor-pointer" onClick={() => navi('/auth/login')}>Already have an account? <span className="text-ng-primary">Login</span></span>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </ClientWrapper>
    )
}
